import { Injectable, OnInit } from "@angular/core";
import axios from "axios";
import { extend, reject } from "lodash";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })

export class dbFullService implements OnInit {

    private ShowInfoTable:any =""
    private Excluido:any =["createdAt","updatedAt"];
    private permissionsData = []

    constructor() {
      
    }

  
  get PermissionsUserRoutes() {
    return {...this.permissionsData}
  }

  ngOnInit(): void {
    this.ShowInfo();
  }

    async GetAllData(table:string){
        return new Promise(async(resolve,reject)=>{
            axios.get(`${environment.UrlFull}api/v1/find-all-info/${environment.bd}/${table}`)
            .then((resp:any)=>resolve(resp.data))
            .catch((error:any)=>reject(error));
        })
    }

    async GetAllT(){
      return new Promise(async(resolve,reject)=>{        
          axios.get(`${environment.UrlFull}api/v1/show-all-info/thomas_clubfibex`)
          .then((resp:any)=>resolve(resp.data))
          .catch((error:any)=>reject(error));
      })
  }

    async GetAllDataCampo(table:string,campo:string,valor:any): Promise<any[]>{
        return new Promise(async(resolve,reject)=>{
            const iURL = `${environment.UrlFull}api/v1/find-any-info/${environment.bd}/${table}/${campo}/${valor}`
            // console.log(iURL)
            axios.get(iURL)
            .then((resp:any)=>resolve(resp.data))
            .catch((error:any)=>reject(error));
        })
    }

    getLoginDataQuery( table: string, email: string, password: string ) {
      return new Promise(async(resolve,reject)=>{        
        const headers = {
          'x-data-query': `SELECT * FROM thomas_clubfibex.${table} WHERE Login="${email}" and Clave="${password}" and Status=1`
        };

        axios.get(`${environment.UrlFull}api/v1/any-queries/thomas_clubfibex`, { headers })
        .then((resp:any)=>{
          console.log(resp)
          resolve(resp.data)
        })
        .catch((error:any)=>reject(error));
      })
    }

    getCountDataQuery( table: string ) {
      return new Promise(async(resolve,reject)=>{        
        const headers = {
          'x-data-query': `SELECT MONTH(createdAt) as mes, COUNT(*) as cantidad FROM ${table} GROUP BY mes`
        };

        axios.get(`${environment.UrlFull}api/v1/any-queries/thomas_clubfibex`, { headers })
        .then((resp:any)=>resolve(resp.data))
        .catch((error:any)=>reject(error));
      })
    }

    getPermissionsQuery( id: string ) {
      return new Promise(async(resolve,reject)=>{        
        const headers = {
          'x-data-query': `SELECT * FROM thomas_clubfibex.cb_RolPermisos crp JOIN cb_Permisos cp ON crp.idRol ="${id}"  and cp.idPermiso = crp.idPermiso`
        };

        axios.get(`${environment.UrlFull}api/v1/any-queries/thomas_clubfibex`, { headers })
        .then((resp:any)=> {
          this.permissionsData = resp.data
          resolve(resp.data)
        })
        .catch((error:any)=>reject(error));
      })
    }

    async GetAllEstrucura(table:string,campo:string,valor:any){
       //https://backend.thomas-talk.me/api/v1/find-any-info/thomas_clubfibex/cb_Estructura/TableName/cb_EmpresaConvenio
      return new Promise(async(resolve,reject)=>{
          axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.bd}/${table}/${campo}/${valor}`)
          .then((resp:any)=>resolve(resp.data))
          .catch((error:any)=>reject(error));
      })
  }

    async CreateData(table:string,idkey:string,body:any){
        return new Promise(async(resolve,reject)=>{
          // console.log("body");
          // console.log(body);
          // console.log("key");
          // console.log(idkey)
          // console.log("table");
          // console.log(table);
          
            let config = {
                method: 'post',
                url: `${environment.UrlFull}api/v1/create-info/${environment.bd}/${table}`,
                headers: { 
                'x-keys-to-add-id': `["${idkey}"]`, 
                'x-keys-of-arrays': '[]', 
                'x-relations': 'false', 
                'Content-Type': 'application/json'
                },
                data : body
            };
            axios.request(config)
            .then((response) =>resolve(response.data))
            .catch((error) =>reject(error));
        })
    }

    async UpdateData(table:string,campoSearch:string,body:any){
        return new Promise(async(resolve,reject)=>{
            let config = {
                method: 'put',
                url: `${environment.UrlFull}api/v1/update-info/${environment.bd}/${table}/${campoSearch}`,
                headers: { 
                  'x-multiple-update': 'false', 
                  'x-elements-obj': '[]', 
                  'x-attr-duplicate': '[]', 
                  'campo': '', 
                  'Content-Type': 'application/json'
                },
                data : body
            };
            axios.request(config)
            .then((response) =>resolve(response.data))
            .catch((error) =>reject(error));
        })
    }

    async ShowInfo(){
      return new Promise(async(resolve,reject)=>{
        if(this.ShowInfoTable && this.ShowInfoTable !=""){
          resolve(this.ShowInfoTable)
        }else{
          let config = {
            method: 'get',
            url: `${environment.UrlFull}api/v1/show-all-info/${environment.bd}`,
          };
          axios.request(config)
          .then((response) =>{resolve(response.data); this.ShowInfoTable=response.data})
          .catch((error) =>reject(error));
          }
      })
    }

    Filtrado(table:any){
      let info =this.ShowInfoTable.model.filter((datadb:any)=>(datadb.tableName === table))
      if(info && info.length>0){
        info =info[0].attr.filter((camposexluido:any)=>( camposexluido!="createdAt" && camposexluido!="updatedAt"));
        return(info);
      }
      return[];
    }

    async CamposTableReturn(table:string){
      return new Promise(async(resolve,reject)=>{
        if(table && table!=""){
          if(this.ShowInfoTable && this.ShowInfoTable !=""){
            // let info =this.ShowInfoTable.model.filter((datadb:any)=>(datadb.tableName === table))
            // if(info && info.length>0){
            //   info =info[0].attr.filter((camposexluido:any)=>( camposexluido!="createdAt" && camposexluido!="updatedAt"));
            //   resolve(info);
            // }else
            // resolve(info);
            let info =this.Filtrado(table);
            return info;
          }else{
            this.ShowInfo()
            .then((resp:any)=>{this.ShowInfoTable=resp;
            // let info =this.ShowInfoTable.model.filter((datadb:any)=>(datadb.tableName === table))
            // if(info && info.length>0){
            //   info =info[0].attr.filter((camposexluido:any)=>( camposexluido!="createdAt" && camposexluido!="updatedAt"))
            //   resolve(info)
            // }else
            // resolve(info);
              let info =this.Filtrado(table);
              return info;
            })
            .catch((error:any)=>{reject({error:error})})
          }
        }else{
          reject({error:"Error debes poner un nombre de tabla"})
        }
      })
    }

    Test(){
        /*Test1 por campos
        this._dbFullService.GetAllDataCampo('cb_EmpCategoria','nombre','Comida')
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })*/
    
        /*
        Test2
        let Data ={
          idEmpConv:"100",
          idFranquicia:"GG5HP",
          Nombre: "Damasco",
          Status:true,
          ImgURL: "https://usatoday.com"
        }
    
        this._dbFullService.CreateData('cb_EmpresaSucursal','idSucursal',Data)
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })*/
    
        /* 
        Test3
        let Data ={
          idEmpCat:"123458",
          nombre: "Comida",
          icon:"fa fa-burguer",
          img: "http://scribd.com2"
        }
    
        this._dbFullService.UpdateData('cb_EmpCategoria','idEmpCat',Data)
        .then((resp)=>{
          console.log("respondio");
          console.log(resp);
        })
        .catch((error:any)=>{
          console.log(error);
        })
        */
      }
    
}