import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { RolesService } from '../services/roles.service';

@Directive({
  selector: '[appUserRole]'
})
export class UserRoleDirective implements OnInit{

  private currentUser: any;
  private permissions: any = [];


  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
    private rolesService: RolesService
  ) {}

  ngOnInit(): void {

    this.currentUser = this.userService.getUserData();

    this.updateView();

  }

  @Input()
  set appUserRole(permissions: Array<string>){

    this.permissions = permissions;
    this.updateView();
  }

  updateView(){
    this.viewContainer.clear();
    if(this.checkPermission()){
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  checkPermission(){
    let hasPermission = false;

    const userPermissions = this.rolesService.getUserPermissions();

    // console.log(this.permissions);
    // console.log(userPermissions);

    if(this.currentUser && this.currentUser.role){

      if(this.permissions.find((permission: any) => permission == 'all')){
        hasPermission = true;
      } else {

        if(!hasPermission){

          this.permissions.forEach((permission: string) => {

            hasPermission = userPermissions.find((userPermission: any) => userPermission.toLowerCase().trim() == permission.toLowerCase().trim());

          });
        }

      }
   
    }

    return hasPermission;
  }

}
