import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import { DictionaryObject } from '../../interfaces/Dictionary';
import { formToJSON } from 'axios';

@Component({
  selector: 'app-field-dinamic',
  templateUrl: './field-dinamic.component.html',
  styleUrls: ['./field-dinamic.component.scss']
})
export class FieldDinamicComponent {
    @Input() FieldDynamic!: string | "id_d_tec1";   // para llamar este componente debes valiar antes de que lo que pasas contiene id_d_
    @Input() value: any;
    @Input() idUserEmp: string = '';
    @Output() ValueSalida = new EventEmitter<string | string[]>();
    
    public TipoComponente: string = "0" // Default No muestra nada deberia mostrar un cargando
    public DataSendComponent:any[] = []
    public TitleStr: string = "";
    public TableName: string = "";
    constructor() {
       
    }

    ngOnInit(): void {

      //this.FieldDynamic = "id_d_tec3".split("_")[2]  // Tomo nada mas tec1 Para Test
        
      if (this.FieldDynamic.indexOf("_") > 0) this.FieldDynamic = this.FieldDynamic.split("_")[2]  // Para produccion
      const FullDBObj = new dbFullService()
      FullDBObj.GetAllDataCampo("cb_Dictionary", "idUnico", this.FieldDynamic).then((DatosDic: any) => {
          const DataDic: DictionaryObject = DatosDic[0];

          this.TableName = DataDic.TableNameDestino;
          this.TitleStr = DataDic.TitleShow
          // Voy a ir a Buscar los datos que me indique el diccionario
          FullDBObj.GetAllDataCampo(DataDic.TableNameDestino, "Status", 1).then((DataShow: any) => { 
            this.TipoComponente = DataDic.Tipo

            let TemDataSendComponent:any[] = []
            DataShow.forEach((DataComponente:any) => {
              const Dato = { 
                datoShow:  DataComponente[DataDic.idCampoShow], 
                status: DataComponente[DataDic.idCampoStatus || ""],
                value: DataComponente[DataDic.idCampoDestino], 
                icon: DataComponente[DataDic.idCampoIcon || "icon"],
                EmpresaUser: DataComponente.cb_EmpresaUsers
              }
              TemDataSendComponent.push(Dato)
            }); 
            this.DataSendComponent = TemDataSendComponent
            console.log(this.DataSendComponent)
          })
      })
    }


    GetDataSelect(Value: string | string[]) {
      console.log(Value)
      this.ValueSalida.emit(Value);
    }



    //https://backend.thomas-talk.me/api/v1/find-any-info/thomas_clubfibex/cb_Dictionary/idUnico/tec1

}
