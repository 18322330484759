import { Component, inject, Input, ViewChild, ElementRef } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { Modal } from 'src/app/core/interfaces/Modal.interface';



@Component({
  selector: 'app-onu-types',
  templateUrl: './onu-types.component.html',
  styleUrls: ['./onu-types.component.scss']
})
export class OnuTypesComponent {

  @Input() Modal!: Modal;


  form: FormGroup;
  editForm: FormGroup;

  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);

  loading = false;
  array: any;
  close: boolean = false;
  idOnuType: any;
  ElementSelect: any;
  modalTitle: string = '';
  modalText: string = '';
  item: any;
  buttonSave: string = '';
  buttonClose: string = '';
  options: any = [];
  selectedItemId: number | undefined;
  formSections: any;
  endPoint: string = 'onuTypes';
  id: any;

  // Select para Tipo de PON
  selectedPonType: any;
  itemsPonTypes = [
    { id: 1, name: 'GPON' },
    { id: 2, name: 'EPON' },
  ];

  // Select para Tipo de Capability
  selectedCapability: any;

  // data to be defined
  dataUndefined: any = [
    { id: 1, name: 'datos por definir' },
    { id: 2, name: 'datos por definir' }
  ];

  buttonsHide: boolean = true;


  constructor() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      onuTypeImage: new FormControl('', [Validators.minLength(3)]),
      capability_id: new FormControl('', [Validators.required, Validators.minLength(1)]),
      pon_type_id: new FormControl('', [Validators.minLength(4)]),
      ethernet_ports: new FormControl('', [Validators.minLength(1)]),
      wifi_ports: new FormControl('', [Validators.minLength(1)]),
      voip_ports: new FormControl('', [Validators.minLength(1)]),
      catv: new FormControl('', [Validators.minLength(1)]),
      allow_custom_profiles: new FormControl('', [Validators.minLength(1)]),
      wifiSsidsPrefix: new FormControl('', [Validators.minLength(1)]),
      ethernetPortsPrefix: new FormControl('', [Validators.minLength(1)]),
      voipPortsPrefix: new FormControl('', [Validators.minLength(1)])
    });

    this.editForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      onuTypeImage: new FormControl('', [Validators.minLength(3)]),
      capability_id: new FormControl('', [Validators.required, Validators.minLength(1)]),
      pon_type_id: new FormControl('', [Validators.minLength(4)]),
      ethernet_ports: new FormControl('', [Validators.minLength(1)]),
      wifi_ports: new FormControl('', [Validators.minLength(1)]),
      voip_ports: new FormControl('', [Validators.minLength(1)]),
      catv: new FormControl('', [Validators.minLength(1)]),
      allow_custom_profiles: new FormControl('', [Validators.minLength(1)]),
      wifiSsidsPrefix: new FormControl('', [Validators.minLength(1)]),
      ethernetPortsPrefix: new FormControl('', [Validators.minLength(1)]),
      voipPortsPrefix: new FormControl('', [Validators.minLength(1)])
    });
  }

  ngOnInit() {

    this.getData();
   

  }

  async getData(){
    let estructura = {
      title: "OnuType",
      text: "Esto es una simple prueba",
      saveButton: "Save",
      closeButton: "Close"
    }


    this.Modal = estructura;

    this.modalTitle = this.Modal.title

    this.modalText = this.Modal.text

    this.buttonSave = this.Modal.saveButton

    this.buttonClose = this.Modal.closeButton


    this.loading = true;

    const data = await this.smartOltService.getData('onuTypes/listing');
    const capability = await this.smartOltService.getData('capabilities/listing');
    this.options = capability.data;

    if (data.data.length > 0) {
      this.loading = false;
      this.array = data.data;

      console.log(this.array);
    } else {
      this.loading = false;
      this.array = [];
    }

    this.formSections = [
      {
        title: 'PON TYPE',
        controlName: 'pon_type_id',
        placeholder: 'Select PON TYPE',
        type: 'select',
        selectOptions: this.itemsPonTypes,
      },
      {
        title: 'ONU TYPE',
        controlName: 'name',
        placeholder: 'Name del ONU type',
        type: 'text',
      },
      {
        title: 'Ethernet ports',
        controlName: 'ethernet_ports',
        placeholder: 'Ports enthernet',
        type: 'number',
      },
      {
        title: 'WiFi SSIDs',
        controlName: 'wifi_ports',
        placeholder: 'WiFi',
        type: 'number',
      },
      {
        title: 'VoIP ports',
        controlName: 'voip_ports',
        placeholder: 'voipPorts',
        type: 'number',
      },
      {
        title: 'Capability',
        controlName: 'capability_id',
        type: 'select',
        selectOptions: this.options,
      },
      {
        title: 'Allow custom profile',
        controlName: 'allow_custom_profiles',
        type: 'checkbox',
      },
      {
        title: 'catv',
        controlName: 'catv',
        type: 'checkbox',
      },
      {
        title: 'ONU TYPE image',
        controlName: 'onuTypeImage',
        type: 'file',
      },
      {
        title: 'Ethernet ports prefix',
        controlName: 'ethernetPortsPrefix',
        placeholder: 'ethernetPortsPrefix',
        type: 'select',
        selectOptions: this.dataUndefined,
      },
      {
        title: 'WIFI SSIDs prefix',
        controlName: 'wifiSsidsPrefix',
        placeholder: 'wifiSsidsPrefix',
        type: 'select',
        selectOptions: this.dataUndefined,
      },
      {
        title: 'VoIP ports prefix',
        controlName: 'voipPortsPrefix',
        placeholder: 'voipPortsPrefix',
        type: 'select',
        selectOptions: this.dataUndefined,
      },
    ];
  }

  formRefresh() {
    this.form.reset();
  }


  get ponType() {
    return this.form.get('ponType');
  }
  get name() {
    return this.form.get('name');
  }
  get ethernetPorts() {
    return this.form.get('ethernet_ports');
  }
  get wifi() {
    return this.form.get('wifi');
  }
  get voipPorts() {
    return this.form.get('voipPorts');
  }
  get catv() {
    return this.form.get('catv');
  }
  get customProfile() {
    return this.form.get('allow_custom_profiles ');
  }

  receiveID(itemId: number) {
    this.id = itemId;
  }

  receiveEdit(editForm: any) {
    this.editForm = editForm;
    console.log(this.editForm, 'Recibido')
  }


}
