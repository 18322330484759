import { Component, inject, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { Modal } from 'src/app/core/interfaces/Modal.interface';


@Component({
  selector: 'app-onus',
  templateUrl: './onus.component.html',
  styleUrls: ['./onus.component.scss']
})
export class OnusComponent {

  @Input() Modal!: Modal;

  form: FormGroup;
  editForm: FormGroup;

  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);

  modalTitle: string = '';
  modalText: string = '';
  buttonSave: string = '';
  buttonClose: string = '';
  loading = false;
  array: any;
  close: boolean = false;
  id: any;
  idOnu: any;
  ElementSelect: any;
  onus: any;
  page: any;
  render = inject(Renderer2);
  endPoint: string = 'onus';
  queryParams: string[] = [];
  buttonsHide: boolean = false;
  itemsOnuTypes: any = [];
  itemsVLAN = ['100', '6- FTTH RESIDENCIAL', '13- GALPON-23', '50-HOTSyEXONERAD'];
  itemsOLT: any = [];
  itemsZone: any = [];
  itemsSplitter: any = [];
  itemsPort = [1, 2, 3, 4];
  itemsSpeedProfiles: any = [];
  formSections: any = [];

  itemsPonTypes: any = [
    { id: 1, name: 'GPON' },
    { id: 2, name: 'EPON' },
    { id: 3, name: 'GPON | EPON' },
  ];

  constructor() {
    this.form = new FormGroup({
      board: new FormControl(null, [Validators.required]),
      olt_id: new FormControl(null, [Validators.required]),
      pon_type_id: new FormControl('', [Validators.required, Validators.minLength(3)]),
      port: new FormControl(null, [Validators.required]),
      serial: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      onu_type_id: new FormControl(null, [Validators.required]),
      speed_profile_id: new FormControl(null),
      mode: new FormControl(null, [Validators.required]),
      zone_id: new FormControl(null, [Validators.required]),
      odb_id: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      // address: new FormControl(null,  Validators.minLength(5)),
      unique_external_id: new FormControl(null, [Validators.required, Validators.minLength(5)]),
    })

    this.editForm = new FormGroup({
      board: new FormControl(null, [Validators.required]),
      olt_id: new FormControl(null, [Validators.required]),
      pon_type_id: new FormControl('', [Validators.required, Validators.minLength(3)]),
      port: new FormControl(null, [Validators.required]),
      serial: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      onu_type_id: new FormControl(null, [Validators.required]),
      speed_profile_id: new FormControl(null),
      mode: new FormControl(null, [Validators.required]),
      zone_id: new FormControl(null, [Validators.required]),
      odb_id: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      // address: new FormControl(null,  Validators.minLength(5)),
      unique_external_id: new FormControl(null, [Validators.required, Validators.minLength(5)]),
    });
  }

  ngOnInit() {
    this.getData();

  }

  async getData(){

    let estructura = {
      title: "ONU",
      text: "Esto es una simple prueba",
      saveButton: "Accept",
      closeButton: "Close"
    }

    this.Modal = estructura;

    this.modalTitle = this.Modal.title

    this.buttonSave = this.Modal.saveButton

    this.buttonClose = this.Modal.closeButton


    this.loading = true;

    const data = await this.smartOltService.getData(`onus/listing?${this.queryParams.join('&')}`);

    console.log('DATA ONUS', data)

    if (data.data.length > 0) {
      this.array = data;
    } else {
      this.array = [];
    }

    let olts = await this.smartOltService.getData('olts/listing');
    let zones = await this.smartOltService.getData('locations/listing');
    let odb = await this.smartOltService.getData('odbs/listing');
    let speedProfiles = await this.smartOltService.getData('speed_profiles/listing');
    let onuTypes = await this.smartOltService.getData('onuTypes/listing');


    if (olts.data) {
      this.itemsOnuTypes= onuTypes.data
      this.itemsOLT = olts.data;
      this.itemsZone = zones.data;
      this.itemsSplitter = odb.data;
      this.itemsSpeedProfiles = speedProfiles.data;
    }

    this.formSections = [
      {
        title: 'OLT',
        controlName: 'olt_id',
        placeholder: 'Select',
        type: 'select',
        selectOptions: this.itemsOLT,
      },
      {
        title: 'PON TYPE',
        controlName: 'pon_type_id',
        placeholder: 'Select PON TYPE',
        type: 'select',
        selectOptions: this.itemsPonTypes,
      },
      {
        title: 'Board',
        controlName: 'board',
        placeholder: 'Board (optional)',
        type: 'number',
      },
      {
        title: 'Port',
        controlName: 'port',
        placeholder: 'Port (optional)',
        type: 'number',
      },
      {
        title: 'Serial',
        controlName: 'serial',
        type: 'text',
      },
      {
        title: 'ONU type',
        controlName: 'onu_type_id',
        placeholder: 'Seleccionar',
        type: 'select',
        selectOptions: this.itemsOnuTypes,
      },
      {
        title: 'ONU mode',
        controlName: 'mode',
        type: 'radio',
        radioOptions: [
          { label: 'Routing', value: 'routing' },
          { label: 'Bridging', value: 'bridging' },
        ],
      },
      {
        title: 'Zone',
        controlName: 'zone_id',
        placeholder: 'Select',
        type: 'select',
        selectOptions: this.itemsZone,
      },
      {
        title: 'Speed Profile',
        controlName: 'speed_profile_id',
        placeholder: 'Select',
        type: 'select',
        selectOptions: this.itemsSpeedProfiles,
      },
      {
        title: 'ODB (Splitter)',
        controlName: 'odb_id',
        type: 'select',
        selectOptions: this.itemsSplitter,
      },
      {
        title: 'Name',
        controlName: 'name',
        type: 'text',
      },
      // {
      //   title: 'Address or comment',
      //   controlName: 'address',
      //   placeholder: 'Addres or comment (optional)',
      //   type: 'text',
      // },
      {
        title: 'ONU external ID',
        controlName: 'unique_external_id',
        placeholder: 'Use the unique ONU external ID with API or billing systems',
        type: 'text',
      },
    ];

    this.loading = false;

  }

  recieveQueryParams(queryParams: any){

    let pageQuery;
    let sameFilters = true;

    if(queryParams.length){

      queryParams.forEach((query: string) => {

        if(query.includes('page')){

          const index = queryParams.indexOf(query);
          if (index > -1) { 
            pageQuery = queryParams.splice(index, 1)[0];
          }
        } else {

          if(this.queryParams.length){

            let findedQuery = this.queryParams.find((q: string) => q == query);

            if(findedQuery == undefined){
              sameFilters = false;
            }

        
          }

        }
        
      });

      if(sameFilters){
        let oldPage;

        this.queryParams.forEach((query: string) => {
          
          if(query?.includes('page')){
  
            const index = this.queryParams.indexOf(query);
            if (index > -1) { 
              oldPage = this.queryParams.splice(index, 1);
            }
          } 
          
        });

        if(this.queryParams.length > queryParams.length){

          sameFilters = false;

        } 
      }

    }

    if(sameFilters && pageQuery){
      queryParams.push(pageQuery);
    }

    this.queryParams = queryParams;

    this.getData();

  }

  receiveID(itemId: number) {
    this.id = itemId;
  }

  receiveEdit(editForm: any) {
    this.editForm = editForm;
  }

}
