import { Component } from '@angular/core';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent {

  loading: boolean = false;

  data: any = [];

  constructor(){}

  ngOnInit(){

  }

  getData(){

  }

}
