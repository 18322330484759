import { Injectable, OnInit } from '@angular/core';
import { SmartOltService } from './smart-olt.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {


  constructor(private smartOltService: SmartOltService, private userService: UserService) { 
  }



  async getRoles(){

    let res = await this.smartOltService.getData('role/listing');

    return res;
  }

  async setUserPermissions(){

    const data = this.userService.getUserData();

    let res = await this.smartOltService.getData('user/permissions/' + data.id);

    sessionStorage.setItem('permissions', JSON.stringify(res));

  }

  getUserPermissions(){

    let data = JSON.parse(sessionStorage.getItem('permissions')!);

    return data;
   
  }

}
