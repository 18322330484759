import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  @Input() rightSideItem:string = 'button';
  @Input() Ruta:boolean = true;
  @Input() bar:boolean = true;
  @Input() Details:any ="";
  
  @Output() SalidaClickMenu:EventEmitter<any> = new EventEmitter();

  public StyleDefault ="overflow: auto; margin-right: 20px; margin-bottom: -10px;"
  public IconDefault ="fa fa-plus mg-r-10";
  public TitleButtonDefault ="Crear Elemento";

  constructor( 
    private ObsService: ObservableComponentsService, 
    private router: Router 
    ) { 
    this.StyleDefault =this.Details.hasOwnProperty('Style')? this.Details.Style : this.StyleDefault;
    this.IconDefault =this.Details.hasOwnProperty('Icon')? this.Details.Icon : this.IconDefault;
    this.TitleButtonDefault =this.Details.hasOwnProperty('Tittle')? this.Details.Tittle : this.TitleButtonDefault;
    this.initSuscriptions()
  }

  ngOnInit(): void {

  }

  testEvent() {
    this.ObsService.paramData$.emit(true);
  }


  SalidaClickMenuEvent(event:any,value?:any){
    let Estructura = {
      Type : event, //Horita puedes recibir search o button
      Value: value?.value //En caso de ser un search te devuelve el valor
    }
    //console.log(Estructura);
    this.SalidaClickMenu.emit(Estructura);
  }

  initHeaderExceptions(){
    console.log(this.router.url)
    if (this.router.url.includes('consulta')) {
      this.rightSideItem = 'not set';
    }
  }

  initSuscriptions(){

    this.ObsService.pageTitleOptions$.subscribe(route => {
      console.log(route)

      if(route.includes('consulta') || route.includes('home')){
        this.rightSideItem = 'not set'

      }else{
        this.rightSideItem = 'button'

      }
      
    })
  }
}
