import { Component, inject, Input, ViewChild, ElementRef } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { Modal } from 'src/app/core/interfaces/Modal.interface';



@Component({
  selector: 'app-speed-profiles',
  templateUrl: './speed-profiles.component.html',
  styleUrls: ['./speed-profiles.component.scss']
})

export class SpeedProfilesComponent {
  @Input() Modal!: Modal;


  form: FormGroup;
  editForm: FormGroup;

  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);

  loading = false;
  array: any;
  close: boolean = false;
  idSpeedProfile: any;
  ElementSelect: any;
  modalTitle: string = '';
  modalText: string = '';
  buttonSave: string = '';
  buttonClose: string = '';
  options: any = [];
  selectedItemId: number | undefined;
  endPoint: string = 'speed_profiles';
  id: any;

    // Select para Tipo de Perfil
    selectedProfile: any;
 
  
    // Select para Tipo de Conexion
    selectedConexion: any;
    itemsConexions = [
      { id: 'internet', name: 'Internet' },
    ];
    //Select para Prefix
    selectedPrefix: any;
    itemsPrefix = [
      { id: 1, name: 'Si' },
      { id: 0, name: 'No' },
    ];

  formSections = [
    {
      title: 'Speed Profile name',
      controlName: 'name',
      placeholder: 'Speed Profile name',
      type: 'text',
    },
    {
      title: 'Upload speed',
      controlName: 'upload_speed',
      placeholder: 'Upload speed (Kbps)',
      type: 'number',
    },
    {
      title: 'Download speed',
      controlName: 'download_speed',
      placeholder: 'Download speed (Kbps)',
      type: 'number',
    },
    {
      title: 'Connection type',
      controlName: 'type_conexion',
      placeholder: 'Select connection type',
      type: 'select',
      selectOptions: this.itemsConexions,
    },
    {
      title: 'Prefix',
      controlName: 'use_prefix',
      placeholder: 'Prefix',
      type: 'select',
      selectOptions: this.itemsPrefix,
    },
  ];
  buttonsHide:boolean = false;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      type_conexion: new FormControl('', [Validators.required, Validators.minLength(5)]),
      use_prefix: new FormControl('', [Validators.nullValidator, Validators.minLength(1)]),
      type: new FormControl('', [Validators.minLength(5)]),
      is_default: new FormControl('', [Validators.minLength(5)]),
      upload_speed: new FormControl('', [Validators.required, Validators.minLength(1)]),
      download_speed: new FormControl('', [Validators.required, Validators.minLength(1)]),    
    });

    this.editForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      type_conexion: new FormControl('', [Validators.required, Validators.minLength(5)]),
      use_prefix: new FormControl('', [Validators.nullValidator, Validators.minLength(1)]),
      type: new FormControl('', [Validators.minLength(5)]),
      is_default: new FormControl('', [Validators.minLength(5)]),
      upload_speed: new FormControl('', [Validators.required, Validators.minLength(1)]),
      download_speed: new FormControl('', [Validators.required, Validators.minLength(1)]),
    });
  }

  ngOnInit() {

    this.getData();
 
  }

  async getData(){
    let estructura = {
      title: "Speed Profile",
      text: "Esto es una simple prueba",
      saveButton: "Save",
      closeButton: "Close"
    }

    this.Modal = estructura;

    this.modalTitle = this.Modal.title

    this.modalText = this.Modal.text

    this.buttonSave = this.Modal.saveButton

    this.buttonClose = this.Modal.closeButton

    this.loading = true;

    const data = await this.smartOltService.getData('speed_profiles/listing');


    if (data.data.length > 0) {
      this.loading = false;
      this.array = data.data;
     console.log(this.array) 
    } else {
      this.loading = false;
      this.array = [];
    }
  }

  get name() {
    return this.form.get('name');
  }
  get speed() {
    return this.form.get('speed');
  }
  get type_conexion() {
    return this.form.get('type_conexion');
  }
  get direction() {
    return this.form.get('direction');
  }

  receiveID(itemId: number) {
    this.id = itemId;
  }

  receiveEdit(editForm:any){
    this.editForm = editForm;
    console.log(this.editForm, 'Recibido')
  }

 

}
