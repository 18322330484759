<div class="main">

    <div class="buttons">

        <button class="btn btn-primary" [routerLink]="'/dashboard/roles'"><i class="fa fa-mail-reply"></i> Back</button>

    
    </div>



    <div class="table">



        <div *ngIf="loading; else contentList" class="div-centrado">
            <app-loader></app-loader>
        </div>

        <ng-template #contentList>

            <app-lista-frontend [showFilter]="true" [placeholder]="'Name'" [statusFilter]="false" [endPoint]="'permission'" [exportOpts]="false" [actionsButtons]="true" [title]="'Permissions'"  [data]="permissonsList" [form]="form" [editForm]="editForm"  [formSections]="formSections" (formEditEmitter)="receiveEdit($event)" (itemIdEmitter)="receiveID($event)"> </app-lista-frontend>

        </ng-template>


    </div>
    
</div>
  
<app-modal *ngIf="!loading" [title]="'Permission'" [formSections]="formSections" [item]="item" [id]="id" [endPoint]="'permission'" [editForm]="editForm" [form]="form" (dataEmitter)="getData()"></app-modal> 
