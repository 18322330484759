<div>
    <div *ngIf="loading; else contentList" class="div-centrado">
        <app-loader></app-loader>
    </div>

    <ng-template #contentList>

    
        <div class="col-md-12">
            <app-lista-frontend *ngIf="!loading"  [title]="''" [data]="data" > </app-lista-frontend>
        </div>
    </ng-template>
</div>


