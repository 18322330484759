import { Component, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SmartOltService } from 'src/app/services/smart-olt.service';

interface formOnu {
  "board": number,
  "olt_id": number,
  "pon_type": string,
  "port": number,
  "sn": string,
  "onu_type": string,
  "mode": string,
  "service_port_vlan": number,
  "zone": number,
  "odb": number,
  "speed_downloaded_id": number,
  "speed_up_id": number,
  "name": string,
  "address": string,
  "onu_external": string
}


@Component({
  selector: 'app-add-onu',
  templateUrl: './add-onu.component.html',
  styleUrls: ['./add-onu.component.scss']
})
export class AddOnuComponent {

  form = new FormGroup({
    board: new FormControl(null, [Validators.required]),
    olt_id: new FormControl(null, [Validators.required]),
    pon_type: new FormControl(null, [Validators.required]),
    port: new FormControl(null, [Validators.required]),
    sn: new FormControl(null, [Validators.required, Validators.minLength(5)]),
    onu_type: new FormControl(null, [Validators.required]),
    mode: new FormControl(null, [Validators.required]),
    service_port_vlan: new FormControl(null, [Validators.required]),
    zone: new FormControl(null, [Validators.required]),
    odb: new FormControl(null, [Validators.required]),
    speed_downloaded_id: new FormControl(null, [Validators.required]),
    speed_up_id: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required, Validators.minLength(5)]),
    address: new FormControl(null,  Validators.minLength(5)),
    onu_external: new FormControl(null, [Validators.required, Validators.minLength(5)]),
  })

  loading: boolean = false;

  itemsOnuTypes: any = [1,2,3];
  itemsVLAN = ['100', '6- FTTH RESIDENCIAL', '13- GALPON-23', '50-HOTSyEXONERAD'];
  itemsOLT: any =[];
  itemsZone: any = [];
  itemsSplitter: any = [];
  itemsPort = [1, 2, 3, 4];
  itemSpeed: any = [];
  formSections:any = [];
  smartOltService = inject(SmartOltService);

  constructor(private router: Router) { }

  ngOnInit() {
    this.getData();

    this.formSections = [
      {
        title: 'OLT',
        controlName: 'olt_id',
        placeholder: 'Select',
        type: 'select',
        selectOptions: this.itemsOLT, 
      },
      {
        title: 'PON TYPE',
        controlName: 'pon_type',
        type: 'radio',
        radioOptions: [
          { label: 'GPON', value: 'gpon' },
          { label: 'EPON', value: 'epon' },
        ],
      },
      {
        title: 'Board',
        controlName: 'board',
        placeholder: 'Board (optional)',
        type: 'number',
      },
      {
        title: 'Port',
        controlName: 'port',
        placeholder: 'Port (optional)',
        type: 'number',
      },
      {
        title: 'SN',
        controlName: 'sn',
        type: 'text',
      },
      {
        title: 'ONU type',
        controlName: 'onu_type',
        placeholder: 'Seleccionar',
        type: 'select',
        selectOptions: this.itemsOnuTypes,
      },
      {
        title: 'ONU mode',
        controlName: 'mode',
        type: 'radio',
        radioOptions: [
          { label: 'Routing', value: 'routing' },
          { label: 'Bridging', value: 'bridging' },
        ],
      },
      {
        title: 'Use SVLAN-ID',
        controlName: 'svlan',
        type: 'checkbox',
      },
      {
        title: 'User VLAN-ID',
        controlName: 'service_port_vlan',
        type: 'select',
        selectOptions: this.itemsVLAN,
      },
      {
        title: 'Zone',
        controlName: 'zone',
        placeholder: 'Select',
        type: 'select',
        selectOptions: this.itemsZone,
      },
      {
        title: 'ODB (Splitter)',
        controlName: 'odb',
        type: 'select',
        selectOptions: this.itemsSplitter,
      },
      {
        title: 'ODB port',
        controlName: 'port',
        type: 'select',
        selectOptions: this.itemsPort,
      },
      {
        title: 'Download speed',
        controlName: 'speed_downloaded_id',
        type: 'select',
        selectOptions: this.itemSpeed,
      },
      {
        title: 'Upload speed',
        controlName: 'speed_up_id',
        type: 'select',
        selectOptions: this.itemSpeed,
      },
      {
        title: 'Name',
        controlName: 'name',
        type: 'text',
      },
      {
        title: 'Address or comment',
        controlName: 'address',
        placeholder: 'Addres or comment (optional)',
        type: 'text',
      },
      {
        title: 'ONU external ID',
        controlName: 'onu_external',
        placeholder: 'Use the unique ONU external ID with API or billing systems',
        type: 'text',
      },
      {
        title: 'Use GPS',
        controlName: 'gps',
        type: 'checkbox',
      },
    ];
    
  }

  



  async getData() {

    this.loading = true;

    let olts = await this.smartOltService.getData('olts/listing');
    let zones = await this.smartOltService.getData('locations/listing');
    let odb = await this.smartOltService.getData('odbs/listing');
    let speed = await this.smartOltService.getData('speed_profiles/listing');


    if (olts.data) {
      this.itemsOLT = olts.data;
      this.itemsZone = zones.data;
      this.itemsSplitter = odb.data;
      this.itemSpeed = speed.data;
      this.loading = false;
    }

  }

  onSubmit(): void {
    console.log(this.itemsZone)
    this.smartOltService.create('onus', this.form.value).then((res) => {
        if (res.data) {
          this.router.navigateByUrl('dashboard/unconfigured')
        }
      });
  }
}
