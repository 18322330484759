import { Component } from '@angular/core';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SmartOltService } from 'src/app/services/smart-olt.service';


interface jsPDFWithPlugin extends jsPDF {
  autoTable: (config: object) => jsPDF;
}

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class ExportsComponent {

  loadingOnu: boolean = false;
  loadingOlt: boolean = false;
  info: any = [];
  element: any;
  randomFileName = `${Date.now()}-fibexolt.xlsx`;
  hiddenSelectOlt: boolean = false;
  itemsOlts: any;
  selectedOlt: any;

  constructor(private smartSvc: SmartOltService) { }


  exportExcel(type: string) {

    if (this.selectedOlt == null) {

      if (type === 'onus') {
        this.loadingOnu = true;
        this.smartSvc.getData('onus/listing').then(async (data: any) => {
          this.info = await data.data;
          this.generateExcel(this.info);
          this.loadingOnu = false;
        }).catch((error) => {
          console.log(error);
        });
      }

      else if (type === 'olts') {
        this.loadingOlt = true;
        this.smartSvc.getData('olts/listing').then((data: any) => {
          this.info = data.data;
          //console.log(this.info);
          this.generateExcel(this.info);
          this.loadingOlt = false;
        }).catch((error) => {
          console.log(error);
        });
      }

    } else {

      this.loadingOnu = true;
      this.smartSvc.getById('onus/showbyOlt', this.selectedOlt).then((data: any) => {
        this.info = data.data;
        this.generateExcel(this.info);
        this.loadingOnu = false;
      }).catch((error) => {
        console.log(error);
      })
    }

  }

  generateExcel(data: any) {
    this.element = data;
    const docWs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.element);
    const docWb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(docWb, docWs, 'Doc-1');
    XLSX.writeFile(docWb, this.randomFileName);
  }

  exportToCSV(type: string) {

    if (this.selectedOlt == null) {

      if (type === 'onus') {
        this.loadingOnu = true;
        this.smartSvc.getData('onus/listing').then(async (data: any) => {
          this.info = await data.data;
          //console.log(this.info);
          this.generateCsv(this.info);
          this.loadingOnu = false;
        }).catch((error) => {
          console.log(error);
        });
      }

      if (type === 'olts') {
        this.loadingOlt = true;
        this.smartSvc.getData('olts/listing').then((data: any) => {
          this.info = data.data;
          //console.log(this.info);
          this.generateCsv(this.info);
          this.loadingOlt = false;
        }).catch((error) => {
          console.log(error);
        });
      }
    } else {

      this.loadingOnu = true;
      this.smartSvc.getById('onus/showbyOlt', this.selectedOlt).then((data: any) => {
        this.info = data.data;
        this.generateCsv(this.info);
        this.loadingOnu = false;
      }).catch((error) => {
        console.log(error);
      })

    }


  }

  generateCsv(data: any) {
    const rows = data;
    var fileName = `${new Date().toISOString()}_fibex_olt.csv`;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    this.saveCsvFile(excelBuffer, fileName);
  }

  saveCsvFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'text/csv' });
    const fileUrl = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  exportPDF(type: string) {

    if (this.selectedOlt == null) {
      if (type === 'onus') {
        this.loadingOnu = true;
        this.smartSvc.getData('onus/listing').then(async (data: any) => {
          this.info = await data.data;
          this.generatePDF(this.info, 'onus');
          this.loadingOnu = false;
        }).catch((error) => {
          console.log(error);
        });
      }

      if (type === 'olts') {
        this.loadingOlt = true;
        this.smartSvc.getData('olts/listing').then((data: any) => {
          this.info = data.data;
          //console.log(this.info);
          this.generatePDF(this.info, 'olts');
          this.loadingOlt = false;
        }).catch((error) => {
          console.log(error);
        });
      }
    } else {

      this.loadingOnu = true;
      this.smartSvc.getById('onus/showbyOlt', this.selectedOlt).then((data: any) => {
        this.info = data.data;
        this.generatePDF(this.info, 'onus');
        this.loadingOnu = false;
      }).catch((error) => {
        console.log(error);
      })

    }


  }

  generatePDF(data: any, type: string) {
    let body: any[] = []; // Cuerpo de la tabla
    let headers: any[] = []; // Encabezado de la tabla\

    const doc = new jsPDF() as jsPDFWithPlugin;
    if (type === 'onus') {
      headers = [['ONU', 'OLT', 'Status', 'SN/MAC', 'Zone', 'Mode', 'VLAN', 'Auth date']];
      body = data.map((row: any) => [row.name, row.olt_name, row.status, row.sn, row.zone_name, row.mode, row.vlan, row.authorization_date]);
    } else {
      headers = [['OLT', 'IP', 'OLT Hardware Version', 'SNMP PORT', 'TELNET PORT']];
      body = data.map((row: any) => [row.name, row.ip, row.olt_hardware_version, row.snmp_port, row.telnet_port]);
    }

    // Generar la tabla en el documento PDF
    doc.autoTable({
      head: headers,
      body: body,
    });

    // Descargar el documento PDF
    doc.save(`${new Date().toISOString()}_fibex_olt.pdf`);
  }

  showSelectOlt() {
    this.loadingOnu = true;
    this.smartSvc.getData('olts/listing').then((data: any) => {
      this.itemsOlts = data.data;
      this.hiddenSelectOlt = true;
      this.loadingOnu = false;
    }).catch((error) => {
      console.log(error);
    });
  }

  hiddenSelect() {
    this.hiddenSelectOlt = false;
    this.selectedOlt = null;
  }
}
