import { isDevMode, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ModuleCardMenuComponent } from './pages/dashboard/components/module-card-menu/module-card-menu.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HttpErrorComponent } from './pages/http-error/http-error.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { PerfilComponent } from './pages/dashboard/layout/perfil/perfil.component';
import { CouponsTagComponent } from './pages/coupons-tag/coupons-tag.component';
import { PromotionsTagComponent } from './pages/promotions-tag/promotions-tag.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { UserRolesGuard } from './guards/user-roles.guard';
import { RolesComponent } from './pages/roles/roles.component';
import { PermissionsComponent } from './pages/permissions/permissions.component';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivateChild: isDevMode() ? [AuthGuard] : [AuthGuard], // auth guard will be deactivated when dev mode is on
    component: DashboardComponent,
    children: [  // ConfigurationService is in charge of loading the routes here
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'modules', component: ModuleCardMenuComponent },
      { path: "promotions", component: PromotionsTagComponent },
      { path: "coupons", component: CouponsTagComponent },
    ],
  },
  { path: 'signup', component: SignupComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'changePassword', canActivate: [UserRolesGuard], component: ChangePasswordComponent, data: {roles: []}},
  { path: 'profiles', canActivate: [UserRolesGuard], component: ProfilesComponent, data: {roles: ['admin', 'usuario']}},
  { path: 'roles', canActivate: [UserRolesGuard], component: RolesComponent, data: {roles: ['admin', 'usuario']}},
  { path: 'permissions', canActivate: [UserRolesGuard], component: PermissionsComponent, data: {roles: ['admin', 'usuario']}},
  { path: 'perfil', canActivate: [UserRolesGuard], component: PerfilComponent, data: {roles: []}},
  { path: 'error', component: HttpErrorComponent },
  { path: '', redirectTo: 'dashboard/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
