import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SmartOltService } from 'src/app/services/smart-olt.service';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss'],
})
export class ViewsComponent implements OnInit {

  loading = true;
  dataOnu: any;
  order!: string;
  data: any = [];
  show: boolean = false;
  modalTitle: string = '';
  selectedOlt: any = [];
  onuTypes: any = [];

  itemsVLAN = [
    '100',
    '6- FTTH RESIDENCIAL',
    '13- GALPON-23',
    '50-HOTSyEXONERAD',
  ];

  itemsTAG = ['default', 'translate', 'tanslate-and-add', 'transparent'];

  itemsDownload = [
    'FTTH',
    'FTTH 200',
    '1MB',
    'FTTH_8',
    'Perfil de subida 300 MB',
  ];

  itemsUpload = [
    'UP_1M_VOIP',
    'ID-10',
    'FTTH_1M',
    'FTTH_5',
    'Perfil de subida 300 MB',
  ];

  labels: any = [];
  data2: any = [];
  data3: any = [];
  x: any;
  x2: any;
  status: any = null;
  loadingStatus: boolean = false;

  speedProfile = {
    userVlanId: '',
    TagtransformMode: '',
    downloadSpeed: '',
    uploadSpeed: '',
  }

  ethernetPorts = {
    status: '',
    mode: '',
    dhcp: 'No control',
  };

  detailsTemplate = {
    optical_status: {
      module_type: 'info1',
      rx_optical_power: 'info2',
      tx_optical_power: 'info3',
      temperature: 'info4',
      olt_rx_ont_optical_power: 'info5'
    },
    onu_details: {
      control_flag: 'info6',
      run_state: 'info7',
      match_state: 'info8',
      ont_distance: 'info9',
      memory_occupation: 'info10',
      cpu_occupation: 'info11',
      temperature: 'info12',
      sn: 'info13',
      management_mode: 'info14',
      description: 'info15',
      last_upTime: 'info16',
      ont_online_duration: 'info17',
      interoperabilityMode: 'info18',
      fec_upstream_state: 'info19',
      line_profile_name: 'info20',
      fec_upstream: 'info21',
      qos_mode: 'info22',
      service_mapping_mode: 'info23',
      service_profile_name: 'info24',
      loopback_detect: 'info25',
      loopback_auto_shutdown: 'info26',
      multicast_forward_mode: 'info27'

    }
  }

  statusHeaders: any = [];

  test1: any = {
    uno: "hola",
    dos: "hola",
    tres: "hola"
  }

  test2: any = {}

  constructor(private smartSvc: SmartOltService, private route: ActivatedRoute, private formBuilder: FormBuilder){}

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.getTime();
  }

  getStatus(){
    this.loadingStatus = true;

    console.log("hola");

    setTimeout(() => {

      this.loadingStatus = false;

      let keys = Object.keys(this.test1);

      keys.forEach(key => {

        this.test2[key] = this.test1[key];
        
      });

      this.status = this.detailsTemplate;

      console.log(this.status);

    }, 2000);

    // this.route.queryParams.subscribe(async (data: any) => {
    //   const status = await this.smartSvc.getById('onus/get_all_status', data.id);

    //   if (status.data) {
    //     this.status = status.data;
    //     this.loadingStatus = false;

    //   }

    // });
  }

  getObjKeys(obj: any){
    return Object.keys(obj)
  }

  isObject(obj: any){
    return typeof obj == 'object'
  }


  getTime() {
    // crea un nuevo objeto `Date`
    let today = new Date();
    let dataUp = 0;
    this.data.push(dataUp);
    // obtener la fecha y la hora cada 10 min
    for(let i = 0; i < 7; i++){
      let futureTime = new Date(today);
      futureTime.setHours(futureTime.getHours());
      futureTime.setMinutes(futureTime.getMinutes() + (i * 10));
      let futureTimeString = futureTime.toLocaleTimeString();
      this.labels.push(futureTimeString);
      dataUp += 0.2;
      let num = Math.random();
      let num2 = Math.random() * 3;
      let up = 20 + (num * 10);
      this.data2.push(up.toFixed(1));
      this.data3.push(num2.toFixed(1));
      this.data.push(dataUp.toFixed(1));
    }
  }

  submit(form: number) {
    if (form == 1) {

    } else {

    }
  }



  mostrar(op: boolean, title: string) {
    this.show = op;
    this.modalTitle = title;
  }

  getData(){

    this.route.queryParams.subscribe(async (data: any) => {
      const onu = await this.smartSvc.getById('onus', data.id).then(data => {
        this.dataOnu = data.data ;
        this.loading = false;
      });
      const olts = await this.smartSvc.getData('olts/listing');
      const onuTypes = await this.smartSvc.getData('onuTypes/listing');

      this.selectedOlt = olts.data;
      this.onuTypes = onuTypes.data;

      console.log(this.dataOnu);
    });



  }


  switchSignal(data: string) {
    switch (data) {
      case 'Very good':
        return 'fa fa-signal fa-md text-success';
      case 'Warning':
        return 'fa fa-signal fa-md text-warning';
      default:
        return 'fa fa-signal fa-md text-danger';
    }
  }
}
