import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import { CloudynariService } from 'src/app/services/cloudDinary.service';
import { error } from 'jquery';
import { reject } from 'lodash';

@Component({
  selector: 'app-avatar-generic',
  templateUrl: './avatar-generic.component.html',
  styleUrls: ['./avatar-generic.component.scss']
})
export class AvatarGenericComponent  implements OnInit ,OnDestroy, AfterViewInit{
    @Input() Avatar: any;   // para llamar este componente debes valiar antes de que lo que pasas contiene id_d_
    @Input() name: string | undefined;
    @Output() ValueSalida = new EventEmitter<string>();
    // @Input() CanEdit: boolean | new EventEmitter<boolean>;
    public ImgDefecto: string = "https://res.cloudinary.com/thomasv9/image/upload/v1681065837/ClubFibex/users_sjlanv.png"
    @Input() UrlIMg: string="";
    public showEditimg: boolean = false;
    public validFormats = ['jpg', 'jpeg', 'png'];
    public extn: any ="";
    public indexof: any =0;
    public ValidExtension: boolean=false;
    public limitMB: number = 10;
    public uploadingImg = false;
    public imageUrl: any="";

    constructor(private _CloudynariService:CloudynariService, private _dbFullService:dbFullService) {}


    ngOnInit(): void {
      // this.ValueSalida.subscribe((resp:boolean)=>{
      //   //Aqui debería mandarme solo booleano
      //   console.log("ValueSalidaescuchando");
      //   this.CanEdit = resp;
      // })
    }

    ngOnDestroy(): void {
      // this.ValueSalida.unsubscribe();
    }

    ngAfterViewInit() {
      $('.custom-dropdown').on('show.bs.dropdown', function () {
        var that = $(this);
        setTimeout(function () {
          that.find('.dropdown-menu').addClass('active');
        }, 100);
      });
      $('.custom-dropdown').on('hide.bs.dropdown', function () {
        $(this).find('.dropdown-menu').removeClass('active');
      });
    }

    RemoveAvatar(){
      this.UrlIMg=this.ImgDefecto
    }


    GetDataSelect(Value: string) {
      console.log(Value)
      this.ValueSalida.emit(Value);
    }

    test(event:any){
      console.log(event);
    }

    onMouseEnter() {
      console.log("mouse enter");
    }

    onMouseOut(name: string) {
      console.log("mouse out", name);
    }

    uploadImagePayment2($event: any) {
      this.showEditimg = true;
      let reader = new FileReader();
      reader.readAsDataURL($event.target.files[0]);
      reader.onload = (_event) => {
        let imageBase64: any = reader.result
        const fileList: FileList = $event.target.files;
        //Extraigo la extension del arhivo que subio
        this.extn = fileList[0].name.split(".").pop();
        //Valido si es aceptado la extenxion
        this.indexof = this.validFormats.indexOf(this.extn.toLowerCase());

        if (this.indexof != -1) {
          this.ValidExtension = true;
          if (fileList.length > 0) {
            const file = fileList[0];
            if (!this.verifyFileSize(file.size)) {
              this.uploadingImg = false;
              return;
            }

            var dt = new Date();
            let year = dt.getFullYear();
            let month = (dt.getMonth() + 1).toString().padStart(2, "0");
            let day = dt.getDate().toString().padStart(2, "0");
            let Hour = dt.getHours().toString();
            let Minute = dt.getMinutes().toString();
            let Second = dt.getSeconds().toString();
            let NameCloud = 'Avatar' + '-' + day + '-' + month + '-' + year + '-' + Hour + Minute + Second
            //Paso el file en base64 y el nombre que se le asignara
            this._CloudynariService.UploadImagenCloudynariSignature(imageBase64, NameCloud)
              .then((response: any) => {
                if (response.hasOwnProperty('error')) {
                  console.error("Error al tratar de subir la imagen")
                  return;
                }
                this.uploadingImg = false;
                imageBase64 = '';
                this.imageUrl = response.secure_url;
                this.UrlIMg=this.imageUrl;
                this.ValueSalida.emit(this.imageUrl);
              })
              .catch((error: any) => {
                console.error(error);
              })
          }
        } else {
          this.ValidExtension = false;
          this.uploadingImg = false;
        }
      }
    }

    verifyFileSize(fileSize: number): boolean {
      fileSize =  fileSize / Math.pow(1024 , 2);
      if( fileSize <= this.limitMB ) {
        return true;
      }
      return false;
    }



    //https://backend.thomas-talk.me/api/v1/find-any-info/thomas_clubfibex/cb_Dictionary/idUnico/tec1

}
