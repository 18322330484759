import { Component, Input } from '@angular/core';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (config: object) => jsPDF;
}

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {

  @Input() info: any = [];
  @Input() columns?: string[];

  fileName = 'Document-generated.xlsx';
  element: any;

  NgOnInit(){
    console.log(this.columns)
  }


  exportPDF() {

    console.log(this.info);

    let headers;
    let body;
    let doc;
    let styles;

    // verificar que hay informacion para exportar
    if(this.info[0] != undefined){

      // verificar que se estan especificando las columnas a definir, sino, se crea el documento de forma predeterminada con toda la informacion recibida
      if(this.columns != undefined){
        
        // Encabezado de la tabla
        headers = Object.keys(this.info[0]);

        headers = headers.map((head: any) => {

          if(this.columns?.includes(head)){

            return head
          }

        }).filter((column: any) => column != undefined);
        
        let entries = this.info.map((info: any) => Object.entries(info));

        // Cuerpo de la tabla
        body = this.info.map((row: any) => Object.values(row));

        body = entries.map((entry: any) => {

          let value = entry.map((row: any) => {

            if(this.columns?.includes(row[0])){

              return row[1];
            }

          }).filter((column: any) => column != undefined);
          
          if(value){

            return value;

          }

        }).filter((column: any) => column != undefined);

        console.log(headers)
        console.log(body)

        

      } else {

        // Encabezado de la tabla
        headers = Object.keys(this.info[0]);
        // Cuerpo de la tabla
        body = this.info.map((row: any) => Object.values(row));

      }

      // Verificar que hay menos de 6 columnas para definir el documento vertical u horizontal (mas espacio)
      if(Object.keys(this.info[0]).length > 6){

        doc = new jsPDF('landscape') as jsPDFWithPlugin;
        styles = {fontSize: 6}
  
      } else {
  
        doc = new jsPDF() as jsPDFWithPlugin;
        styles = {fontSize: 10}
  
      }

      // formatear encabezados de columnas
      headers = headers.map((head: any) => head.split('_').join(' ').toUpperCase());

      // Generar la tabla en el documento PDF
      doc.autoTable({

        head: [headers],
        body: body,
        styles: styles,
      });
      // Descargar el documento PDF
      doc.save(`${new Date().toISOString()}_fibex_olt.pdf`);

    }
  }

  export() {

    if(this.info[0] != undefined){

      this.element = this.info;
      const docWs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.element);
      const docWb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(docWb, docWs, 'Doc-1');
      XLSX.writeFile(docWb, this.fileName);

    }

  
  }

  exportToCSV(): void {

    
    if(this.info[0] != undefined){

      const rows = this.info;
      var fileName = `${new Date().toISOString()}_fibex_olt.csv`;
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
      const workbook: XLSX.WorkBook = {
        Sheets: { 'data': worksheet },
        SheetNames: ['data']
      };
  
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
      this.saveExcelFile(excelBuffer, fileName);
      
    }

  }

  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'text/csv' });
    const fileUrl = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


}
