import { Component, OnInit, inject, Renderer2 } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-configured',
  templateUrl: './configured.component.html',
  styleUrls: ['./configured.component.scss'],
})
export class ConfiguredComponent implements OnInit {
  filters = '';
  filters2!: any;
  info: any;
  onus: any = [];
  loading = false;
  selectedOlt: any;
  itemsOlts: any = [];
  page: number = 1;
  render = inject(Renderer2);
  path: any;
  mostrar: any = null;
  numDatosMostrados: any = 10;
  title: string = 'Configured';
  endPoint: string = 'onus/configured';
  viewEndpoint: string = 'configured/views';
  viewbtn: boolean = true;
  viewStatus: boolean = true;
  showFilterPanel: boolean = true;

  search: string = '';
  statusFilter: string = '';
  queryParams: string[] = [];
  queryFromDashboard: string = '';

  constructor(
    private smartSvc: SmartOltService,
    private filterPipe: FilterPipe,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getData();
  }

  selectStatus(status: any) {

    if (this.statusFilter == status) {
      this.statusFilter = '';
    } else {
      this.statusFilter = status;
    }

    this.getData();
  }

  recieveQueryParams(queryParams: any){

    let pageQuery;
    let sameFilters = true;

    if(queryParams.length){

      queryParams.forEach((query: string) => {

        if(query.includes('page')){

          const index = queryParams.indexOf(query);
          if (index > -1) { 
            pageQuery = queryParams.splice(index, 1)[0];
          }
        } else {

          if(this.queryParams.length){

            let findedQuery = this.queryParams.find((q: string) => q == query);

            if(findedQuery == undefined){
              sameFilters = false;
            }

        
          }

        }
        
      });

      if(sameFilters){
        let oldPage;

        this.queryParams.forEach((query: string) => {
          
          if(query?.includes('page')){
  
            const index = this.queryParams.indexOf(query);
            if (index > -1) { 
              oldPage = this.queryParams.splice(index, 1);
            }
          } 
          
        });

        if(this.queryParams.length > queryParams.length){

          sameFilters = false;

        } 
      }

    }

    if(sameFilters && pageQuery){
      queryParams.push(pageQuery);
    }

    this.queryParams = queryParams;

    this.getData();

  }

  async getData() {

    this.loading = true;

    this.router.queryParams.subscribe(async (data: any) => {

      if (data.path) {
        this.path = data.path;

      }

      if(data.cardFilter && !this.queryFromDashboard.length){
        this.queryFromDashboard = data.cardFilter;
        this.queryParams.push(this.queryFromDashboard);
      }
    });

    await this.smartSvc.getData(`onus/configured?${this.queryParams.join('&')}`).then(async (res) => {
        const olts = await this.smartSvc.getData('olts/listing');
        this.loading = false;
        this.itemsOlts = olts.data;
        this.info = res;
      })
      .catch((err) => {
        console.log(err);
      });

    if (this.numDatosMostrados != 10 || this.mostrar != null) {
      this.numDatosMostrados = 10;
      this.mostrar = null;
    }

    console.log(this.info);
  }



  refresh() {
    this.selectedOlt = null;
    this.getData();
  }
}
