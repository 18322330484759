import { Injectable } from '@angular/core';
import { dbFullService } from './dbFull.service'

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private dbFull: dbFullService
  ) { }

  formatDate(date:string = ''):string{
    let d = date === '' ? new Date() : new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year ].join('-');
  }

  async retrieveAction(route: string){
    console.log('RUTA')
    console.log(route)
    const module:any = route.match(/empresa|configuracion|promociones|Login|Logout/)

    if (route !== 'Login' && route !== 'Logout') {
      console.log('pasando por primera parte')
      const url: any = route.match(/franquicias|cat_empresas|cate_prod|consulta|usuarios|nueva|productos/)

    const qRoute = `${module[0]}/${url[0]}`
     
    const result = await this.dbFull.GetAllDataCampo('cb_Accion', 'Modulo', module[0])

    const action = result.find( element => element.Url === qRoute)

    return action.idAccion;

    }else{
     
    const result = await this.dbFull.GetAllDataCampo('cb_Accion', 'Modulo', module[0])

    const action = result.find( element => element.Modulo === module[0])

    console.log(action)
    console.log(module)

    return action.idAccion;
    }

  }
}
