import { Component, inject, Input } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { Modal } from 'src/app/core/interfaces/Modal.interface';
import { FormFieldConfig } from 'src/app/core/interfaces/FormFieldConfig.interface';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss'],
})
export class ZonesComponent {
  @Input() Modal!: Modal;

  form: FormGroup;
  editForm: FormGroup;

  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);

  modalTitle: string = '';
  modalText: string = '';
  buttonSave: string = '';
  buttonClose: string = '';
  id: any;
  loading = false;
  array: any;
  endPoint: string = 'locations';
  formSections = [
    {
      title: 'Zone name',
      controlName: 'name',
      type: 'text',
    },
  ];

  buttonsHide: boolean = false;
  formConfig: { [key: string]: FormFieldConfig } = {
    // Define tus campos aquí
  };

  constructor() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });

    this.editForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }

  ngOnInit() {
    this.getData();
  }

  async getData() {
    let estructura = {
      title: 'Zone',
      text: 'This is a simple test',
      saveButton: 'Save',
      closeButton: 'Close',
    };

    this.Modal = estructura;

    this.modalTitle = this.Modal.title;

    this.modalText = this.Modal.text;

    this.buttonSave = this.Modal.saveButton;

    this.buttonClose = this.Modal.closeButton;

    this.loading = true;

    const data = await this.smartOltService.getData('locations/listing');

    if (data.data.length > 0) {
      this.loading = false;
      this.array = data.data;
    } else {
      this.loading = false;
      this.array = [];
    }
  }

  receiveID(itemId: number) {
    this.id = itemId;
  }

  receiveEdit(editForm: FormGroup) {
    this.editForm = editForm;
  }

  receiveData(data: any) {
    this.array = data;
  }
}
