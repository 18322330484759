import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit, OnDestroy {

  userImg = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxHCtXF1JcaLde-N-k-d5tufHX0urGyqrHvEiyd3Cwgw&s';

  loading: boolean = false;
  form!: FormGroup;
  editForm!: FormGroup;
  id: any;
  item: any;
  formSections: any = [];

  usersList = [];
  roles = [];

  idleState: string = 'Not Started';

  private onIdleSubscriptions: Subscription = new Subscription;


  constructor(private smartOltService: SmartOltService, private userService: UserService, private idle: Idle, private rolesService: RolesService){
    idle.setIdle(60);
    idle.setTimeout(1200); // 20 minutos de inactividad
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.onIdleSubscriptions.add(
      this.idle.onIdleStart.subscribe(()=>{
        this.idleState = 'Idle'
        console.log('Innactive');
      })
    ); 

    this.onIdleSubscriptions.add(
      this.idle.onTimeout.subscribe(()=>{
        this.idleState = "Timed Out";
        console.log('Logged out');
  
        this.userService.logOut();
  
      })
    ); 

    this.onIdleSubscriptions.add(
     
      this.idle.onIdleEnd.subscribe(()=>{
        this.idleState = 'Started';

        console.log('Active again');
      })
    ); 
  }

  async ngOnInit() {

    this.getData();

    this.setState();

  }

  
  ngOnDestroy(): void {

    if(this.onIdleSubscriptions){

      this.idle.stop();
      this.onIdleSubscriptions.unsubscribe();

    }
    
  }

  setState(){
    this.idle.watch();
    this.idleState = 'Started';

  }


  initForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      // confirmPassword: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),

    });

    this.editForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl(null),
      // confirmPassword: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
    });

    this.formSections = [
      {
        title: 'Name',
        controlName: 'name',
        placeholder: 'name',
        type: 'text',
      },

      {
        title: 'Email',
        controlName: 'email',
        placeholder: 'Email',
        type: 'email',
      },

      {
        title: 'New Password',
        controlName: 'password',
        placeholder: 'Password',
        type: 'password',
      },

      // {
      //   title: 'Confirm new Password',
      //   controlName: 'confirmPassword',
      //   placeholder: 'Confirm Password',
      //   type: 'password',
      // },

      {
        title: 'Role',
        controlName: 'role',
        type: 'select',
        selectOptions: this.roles,
      },
      
    ];

  }


  async getData(){
    

    this.loading = true;

    let res = await this.smartOltService.getData('user/listing');


    if (res.length > 0) {
      // this.usersList = [...res, ...this.usersList];
      this.usersList = res;
    } else {
      this.usersList = [];
    }


    this.usersList.sort(function(a: any, b: any){

      // orden decreciente desde el mas reciente al mas viejo
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();

    });


    this.usersList.map((user: any) => {

      if(user.id == 1){
        user.role = 'admin';
      }

      user.img = this.userImg;


    });

    res = await this.smartOltService.getData('role/listing');

    this.roles = res.map((rol: any) => {
      let obj = {
        name: '',
        id: ''
      };

      obj.name = rol.name;
      obj.id = rol.name

      return obj;

    });

    console.log(this.roles);

    await this.initForm();

    this.loading = false;

  }

  receiveID(itemId: number) {
    this.id = itemId;

    this.item = this.usersList.find((user: any) => user.id == itemId);
  }

  receiveEdit(editForm: any) {
    this.editForm = editForm;
  }

}
