import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart, registerables } from 'node_modules/chart.js';
import { SmartOltService } from 'src/app/services/smart-olt.service';

Chart.register(...registerables);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  onus = {
    espera: [],
    online: [],
    offline: [],
    low: []
  }

  olts: any = [];

  infoEx: any = [
    {
      id: 'gpon-onu_0/12/14:6',
      time: '2023-08-01T16:09:41.332Z',
      accion: 'ONU enabled'
    },
    {
      id: 'ONU HWTCD21A7EA7 gpon-onu_0/12/14:6',
      time: '2023-08-01T16:22:26.861Z',
      accion: 'Authorized'
    },
    {
      id: 'gpon-onu_0/12/14:6',
      time: '2023-08-01T16:09:41.332Z',
      accion: 'ONU enabled'
    },
    {
      id: 'ONU HWTCD21A7EA7 gpon-onu_0/12/14:6',
      time: '2023-08-01T16:22:26.861Z',
      accion: 'Authorized'
    },
    {
      id: 'gpon-onu_0/12/14:6',
      time: '2023-08-01T16:09:41.332Z',
      accion: 'ONU enabled'
    },
    {
      id: 'ONU HWTCD21A7EA7 gpon-onu_0/12/14:6',
      time: '2023-08-01T16:22:26.861Z',
      accion: 'Authorized'
    },
    {
      id: 'gpon-onu_0/12/14:6',
      time: '2023-08-01T16:09:41.332Z',
      accion: 'ONU enabled'
    },
    {
      id: 'ONU HWTCD21A7EA7 gpon-onu_0/12/14:6',
      time: '2023-08-01T16:22:26.861Z',
      accion: 'Authorized'
    },
    {
      id: 'gpon-onu_0/12/14:6',
      time: '2023-08-01T16:09:41.332Z',
      accion: 'ONU enabled'
    },
    {
      id: 'ONU HWTCD21A7EA7 gpon-onu_0/12/14:6',
      time: '2023-08-01T16:22:26.861Z',
      accion: 'Authorized'
    },
    {
      id: 'gpon-onu_0/12/14:6',
      time: '2023-08-01T16:09:41.332Z',
      accion: 'ONU enabled'
    },
    {
      id: 'ONU HWTCD21A7EA7 gpon-onu_0/12/14:6',
      time: '2023-08-01T16:22:26.861Z',
      accion: 'Authorized'
    },
    {
      id: 'gpon-onu_0/12/14:6',
      time: '2023-08-01T16:09:41.332Z',
      accion: 'ONU enabled'
    },
    {
      id: 'ONU HWTCD21A7EA7 gpon-onu_0/12/14:6',
      time: '2023-08-01T16:22:26.861Z',
      accion: 'Authorized'
    },
    {
      id: 'gpon-onu_0/12/14:6',
      time: '2023-08-01T16:09:41.332Z',
      accion: 'ONU enabled'
    },
    {
      id: 'ONU HWTCD21A7EA7 gpon-onu_0/12/14:6',
      time: '2023-08-01T16:22:26.861Z',
      accion: 'Authorized'
    }
  ]

  loading = false;

  constructor(private smartOltService: SmartOltService, private router: Router) { }

  ngOnInit(): void {
    this.renderChart();

    this.getData();

  }

  async getFilteredData(filter: any) {

    if (filter == 'Todos') {

      this.getData();

    } else {

      
      try{

        this.loading = true;

        this.onus = {
          espera: [],
          online: [],
          offline: [],
          low: []
        }
  
        this.olts = [];
  
        let res = await this.smartOltService.getData('dashboard/showByOlt/' + filter);

      //  console.log(typeof(res));
        this.onus.espera = res.pending;
  
      
  
        this.onus.online = res.online;
  
        this.onus.offline = res.offline;
  
        this.onus.low = res.low_signal;
  
        res = await this.smartOltService.getData('olts/listing');
  
        this.olts = res.data;
  
        this.loading = false;

      } catch(e){

        console.error(e);

      }

    

    }

  }

  async getData() {

    try{

      this.smartOltService.reseting();

      this.loading = true;

      this.onus = {
        espera: [],
        online: [],
        offline: [],
        low: []
      }

      this.olts = [];


      let res = await this.smartOltService.getData('dashboard');

      this.onus.espera = res.pending;

      this.onus.online = res.online;

      this.onus.offline = res.offline;

      this.onus.low = res.low_signal;

      console.log(this.onus)


      res = await this.smartOltService.getData('olts/listing');

      // res.data.forEach((olt: any) => {

      //   let obj = {
      //     name: olt.name,
      //     info: "120 dias, 12:15, 28C"
      //   }

      //   this.olts.push(obj);

      // });

      this.olts = res.data;

      this.loading = false;



    } catch(e){

      console.error(e);

    }


  }

  redirect(route: any, card?: any) {

    this.router.navigate([`/dashboard/${route}`], { queryParams: { cardFilter: card, path: 'home' } });

  }

  renderChart() {
    const canvas = document.getElementById('chart1') as HTMLCanvasElement | null;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [{
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }
    }
  }


}
