<div class="row export">
  <div class="col-md-12 mb-3">
    <button class="btn btn-primary" routerLink="/dashboard/home" *ngIf="path == 'home'">
      <i class="fa fa-mail-reply"></i> Back to dashboard
    </button>
  </div>

  <div class="col-md-1 align-self-end mb-3">
    <label class="control-label">Actions</label>

    <a class="btn btn-primary text-white" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
  </div>
</div>

<div class="p-2">
  <div *ngIf="loading; else contentList" class="div-centrado">
    <app-loader></app-loader>
  </div>

  <ng-template #contentList>
    <app-lista-frontend [showFilter]="showFilter" [viewStatus]="viewStatus" [title]="title" [data]="data" [endPoint]="endPoint"> </app-lista-frontend>
  </ng-template>
</div>