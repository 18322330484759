<div>
    <div *ngIf="loading; else contentList" class="div-centrado">
        <app-loader></app-loader>
    </div>
    <ng-template #contentList>
        <app-lista-frontend [placeholder]="'Name'" [showFilter]="true" [statusFilter]="false" [actionsButtons]="true" [title]="modalTitle" [data]="array" [endPoint]="endPoint" [form]="form" [formSections]="formSections" [editForm]="editForm" (formEditEmitter)="receiveEdit($event)" (itemIdEmitter)="receiveID($event)" > </app-lista-frontend>
    </ng-template>
</div>

<app-modal [title]="modalTitle" [formSections]="formSections" [id]="id" [endPoint]="endPoint" [editForm]="editForm" [form]="form" (dataEmitter)="getData()"></app-modal>




