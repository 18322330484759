import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSidebarService } from 'src/@core/components/core-sidebar/core-sidebar.service';//@core/components/core-sidebar/core-sidebar.service
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';
import { dbFullService } from 'src/app/services/dbFull.service';
import { HelperService } from 'src/app/services/helper.service';
import { LogsService } from 'src/app/services/logs.service';

let Estructura = {
      TitleHead: "Franquicia",
      Table: "cb_Franquicia",
      Inputs: [],
      Value:[]//"idUserEmp_3jKgVz"
    }

interface IEstructuraCampo {
  Campo: string,
  ExpresionReg: null | "string",
  HadListName: string,
  MaxLen: number | null,
  OrdenInsert: number,
  OrdenList: number,
  PK: number,
  PlaceHolder: string,
  RelacionOtrasTablas: string,
  Requerido: number,
  ShowInsert: number,
  ShowList: number,
  TableName: string,
  TipoDato: string,
  idEstructura: string
}

interface IEstructura {
  TitleHead: string
  Table: string
  Estructura: IEstructuraCampo[],
  Inputs: TipoInput[],
  Value: any[],
  Order?:TipoInput[],
  Type?: String
}
@Component({
  selector: 'app-new-user-sidebar-stepper',
  templateUrl: './new-user-sidebar-stepper.component.html',
  styleUrls: ['./new-user-sidebar-stepper.component.scss']
})
export class NewUserSidebarStepperComponent {
  @Input() element:any;
  @Input() params: IEstructura[] = [];
  @Input() excelColumnsNames: string[] = [];
  @Input() optionToExecute: string = '';
  @Input() idEmpConvCreate: string = '';
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();

  public step: number = 0;
  public fullname:any;
  public username:any;
  public email:any;
  private regex = /_/
  public DataCampos:any ="";
  public DataKeys:any[] =[];
  // public CampoValor = new Map();
  public ConseguiData = true;
  public userID: string = "";
  public formDatas: IFormData[] = [];

  // param: IEstructura = {
  //   TitleHead: '',
  //   Table:'',
  //   Inputs:[],
  //   Value:[],
  //   Order:[],
  //   Type:''
  // };

  getFormDataActive() {
    if(!this.formDatas[this.step]) {
      this.formDatas[this.step] = {
        vars: { },
        error: { }
      };
    }
    return this.formDatas[this.step];
  }

  getParamActive() {
    return this.params[this.step];
  }

  sendMessage(event:any,data: any = '') {
    if( data !== '' && data.hasOwnProperty('Status') ) data.Status = 1;
    let Estructura ={
      evento:event,
      data: [data]
    }
    this.SalidaClickMenu.emit(Estructura)
  }

  constructor( 
    private _dbFullService : dbFullService, 
    private gsnackbar: GenericSnackbarService, 
    private logs: LogsService, 
    private helper: HelperService,
    private router: Router
    ) { 
    }

  ngOnInit() {
    this.setParams();
    if( this.optionToExecute === 'edit' ) this.getDataToEdit(this.element);
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */

  VerificadorTipeRequest(){
    const param = this.getParamActive();
    console.log(param)

    if(param.Type=="Create"){
      param.Order = param.Inputs.filter((i: any) => i.ShowInsert).sort((a: any, b: any) => (a.OrdenInsert - b.OrdenInsert));
    }else{
      //Busco por el campo llave
      let Position = param.Value[0].search(this.regex)
      let Campo = param.Value[0].slice(0,Position);
      let Valor = param.Value[0].slice(Position+1);

      this._dbFullService.GetAllDataCampo(param.Table,Campo,Valor)
      .then((resp:any)=>{

        console.log( resp )
        if(resp && resp.length>0) {
          this.userID = resp[0]?.idUserEmp || '';
          //Busco los keys de lo que me trajo la BD
          const formData = this.getFormDataActive();
          Object.entries(resp[0]).forEach(([keyOriginal, valueKey]) => formData.vars[keyOriginal] = valueKey);
          //Los Ordeno excepto los que no voy a mostrar
          param.Order = param.Inputs.filter((i: any) => i.ShowInsert).sort((a: any, b: any) => (a.OrdenInsert - b.OrdenInsert));
          //Agarro lo que no se muestran y los meto de ultimo en el arreglo
          param.Order.push(...param.Inputs.filter((i: any) => !i.ShowInsert));
          //this.param.Order.push({"idEstructura":"24","TableName":"cb_EmpresaUser","PK":1,"Campo":"idUserEmp","TipoDato":"DependencySelection","HeadListName":"ID","PlaceHolder":"ID","ShowList":0,"ShowInsert":0,"MaxLen":6,"Requerido":0,"ExpresionReg":null,"OrdenList":0,"OrdenInsert":0,"RelacionOtrasTablas":null} as any)
        }else{
          this.ConseguiData = false;
        }
      })
      .catch((error:any)=>{
        this.ConseguiData = false;
        console.log(error);
      })
    }
  }

  /**
   * Submit
   *
   * @param form
   */
  async submit(event:any) {
    let message = ''
    event.preventDefault();
    const formData = this.getFormDataActive();
    const param = this.getParamActive();

    const targetForm = event.target;
    // verify data
    const hasError = param.Order?.some((input: any) => {
      let value = targetForm[input.Campo]?.value || Boolean(targetForm[input.Campo]?.checked || formData.vars[input.Campo]);
      const invalidRegExp = Boolean(input.ExpresionReg && RegExp(input.ExpresionReg).test(String(value ?? '')) === false);
      const invalidMaxLength = Boolean(input.MaxLen !== null && value?.length > input.MaxLen);
      const invalidRequired = Boolean(input.Requerido && !value);

      // fix data
      if(invalidMaxLength) value = value.slice(0, input.MaxLen);
      if(invalidRegExp) {
        formData.error[input.Campo] = `El valor no cumple con la estructura deseada`;
      } else if(invalidRequired) {
        formData.error[input.Campo] = `Campo requerido*`
      } else {
        delete formData.error[input.Campo];
        return false;
      }
      return true;
    })

    if(!hasError) {
      param.Order?.forEach((input: any,index:number) => {
        if(!(input.Campo in formData.vars)) {
          let defaultValue;

          // Se usa un valor por defecto en caso de que no tenga asignado ningun valor
          // NOTA: Es muy probable que nunca pase esto pero no está demás asegurar .___.
          switch(input.TipoDato) {
            case "Switch":
              defaultValue = false;
              break;

            case "Avatar":
              defaultValue = "https://res.cloudinary.com/thomasv9/image/upload/v1681065837/ClubFibex/users_sjlanv.png";
              break;
          }

          formData.vars[input.Campo] = targetForm[input.Campo]?.value || defaultValue;
          let valueSetting = targetForm[input.Campo]?.value;

          if(valueSetting !== undefined && valueSetting !== null && valueSetting !== '') formData.vars[input.Campo] = valueSetting;
        }
      })

      if(this.step === this.params.length-1) {
        let primaryKey: {[key: string]: string} = {};
        
        for( let [i, param] of this.params.entries() ) {
          const formData = this.formDatas[i];
          const pk = param.Estructura.find(est => est.PK);

          try {
            if(pk) {
              if(param.Type==="Create") {
                if( this.idEmpConvCreate && this.idEmpConvCreate.length > 0 ) {
                  formData.vars['idEmpConv'] = this.idEmpConvCreate; 
                }
                message = 'Se ha creado un nuevo registro'

                const resp: any = await this.InsertData(param, {...formData.vars, ...primaryKey});
                this.sendMessage("create",resp);
                primaryKey = {[pk.Campo]: resp[pk.Campo]};
              } else {
                message = `Se ha actualizado un registro`
                const resp: any = await this.UpdateData(param, {...formData.vars, ...primaryKey});
                this.sendMessage("update",resp);
                primaryKey = {[pk.Campo]: resp[pk.Campo]};
              }
            }
            else throw new Error("No existe ningun campo 'primaryKey' en la tabla: " + param.Table);
          }
          catch(error) {
            this.sendMessage("error", error);
          }
        }
        this.createLogs(message)
        this.gsnackbar.runSnackBarNoCallback("Su registro ha sido completado de manera exitosa.", 1, true, 5000)
      }
      else {
        this.next();
      }
      targetForm.reset();
    }
  }

  EventoDinamic(event:any,CampoAgg:string){
    this.getFormDataActive().vars[CampoAgg] = event;
  }

  InsertData(param: any, value:any){
    
    return new Promise((resolve,reject)=>{
      let idKey:any= param.Inputs.filter((Campos:any)=>Campos.PK ==1) //Con esto se busca la llave primaria para que el backend la genere automáticamente
      if(idKey && idKey !=null){
        this._dbFullService.CreateData(param.Table,idKey[0]['Campo'],value)
        .then((resp:any)=>{

          resolve(resp);
        })
        .catch((error:any)=>{
          reject(error);
          console.log(error);
        })
      }
    });
  }

  UpdateData(param: any, value:any){
    const formData = this.getFormDataActive();

    return new Promise((resolve,reject)=>{
      let idKey:any= param.Inputs.filter((Campos:any)=>Campos.PK ==1) //Con esto se busca la llave primaria para que el backend la genere automáticamente
      if(idKey && idKey !=null){
        this._dbFullService.UpdateData(param.Table,idKey[0]['Campo'],value)
        .then((resp:any)=>{
          formData.vars['rowsUpdate']=resp.rowsUpdate
          resolve(formData.vars);
        })
        .catch((error:any)=>{
          reject(error);
          console.log(error);
        })
      }
    });
  }

  next(){
    if (this.step < this.params.length -1) {
      this.step++;
      this.setParams()
      if( this.optionToExecute === 'edit' ) this.getDataToEdit(this.element);
    }
  }

  back() {
    if(this.step > 0) {
      this.step--;
      this.setParams();
    }
  }

  reset() {
    this.step = 0;
    this.formDatas = [];
  }

  setParams(){
    const param = this.getParamActive();
    console.log(param)

    this._dbFullService.GetAllDataCampo("cb_Estructura","TableName",param.Table)
    .then((resp)=>{
      param.Inputs = (resp as any);
      //Si tiene valores es un edit
      param.Type = param.Value && param.Value.length > 0 ? "Update" : "Create";
      this.VerificadorTipeRequest();
    })
    .catch((error:any)=>{
      console.log(error);
    })
  }

  async getDataToEdit( value?: any ) {
    const { idSucursal } = value;

    const objTest = {
      ImgURL: '',
      Nombre: '',
      Status: false,
      Direccion: '',
      idSucursal: ''
    }

    const sucursalResp = await this._dbFullService.GetAllDataCampo('cb_EmpresaSucursal', 'idSucursal', idSucursal);
    const directionResp = await this._dbFullService.GetAllDataCampo('cb_SucursalDir', 'idSucursal', idSucursal);

    objTest.Direccion = directionResp[0].Direccion
    objTest.ImgURL = sucursalResp[0].ImgURL
    objTest.Nombre = sucursalResp[0].Nombre
    objTest.Status = sucursalResp[0].Status === 1 ? true : false
    objTest.idSucursal = sucursalResp[0].idSucursal
    
    this.getFormDataActive().vars = objTest
  }

  async createLogs(detail: string){
    const auth: any = sessionStorage.getItem('login')
    const { idUser, idUserEmp } = JSON.parse(auth)

    if(idUser){
      const action = await this.helper.retrieveAction(this.router.url)
  
      this.logs.createLog(idUser, action, detail).then(resp => {
  
      }).catch(error => console.log(error))

    }else if(idUserEmp){
      const action = await this.helper.retrieveAction(this.router.url)
  
      this.logs.createLog(idUserEmp, action, detail).then(resp => {
  
      }).catch(error => console.log(error))
    }

  }
}
