import { Component, inject, ViewChild, ElementRef, Input } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { Modal } from 'src/app/core/interfaces/Modal.interface';


@Component({
  selector: 'app-olts',
  templateUrl: './olts.component.html',
  styleUrls: ['./olts.component.scss']
})
export class OltsComponent {
  @Input() Modal!: Modal;



  form: FormGroup;
  editForm: FormGroup;

  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);


  modalTitle: string = '';
  modalText: string = '';
  buttonSave: string = '';
  buttonClose: string = '';
  loading = false;
  array: any;
  close: boolean = false;
  idOlt: any;
  options: any = [];
  selectedItemId: number | undefined;
  endPoint: string = 'olts';
  id: any;
  ElementSelect: any;
  viewbtn:boolean = true;
  viewEndpoint: string = 'settings/olts/view'
  formSections:any;
  itemsSoftware:any = [];
  itemsHardware:any = [];
  selectedPonType: any;
  itemsPonTypes: any = [
    { id: 1, name: 'GPON' },
    { id: 2, name: 'EPON' },
    { id: 3, name: 'GPON | EPON' },
  ];
  activateOlt: any = null;

 
  buttonsHide:boolean = false;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      ip: new FormControl('', [Validators.required, Validators.minLength(5)]),
      telnet_port: new FormControl('', [Validators.required, Validators.minLength(2)]),
      ipvt_module: new FormControl('', [Validators.required, Validators.minLength(2)]),
      olt_hardware_version_id: new FormControl('', [Validators.required, Validators.minLength(1)]),
      olt_software_version_id: new FormControl('', [Validators.required, Validators.minLength(1)]),
      pon_type_id: new FormControl('', [Validators.required, Validators.minLength(3)]),
      snmp_udp_port: new FormControl('', [Validators.required, Validators.minLength(3)]),
      snmp_read_only: new FormControl('', [Validators.required, Validators.minLength(3)]),
      snmp_read_write: new FormControl('', [Validators.required, Validators.minLength(3)]),
      act_olt_auto: new FormControl(false)

    });

    this.editForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)]),
      ip: new FormControl('', [Validators.required, Validators.minLength(5)]),
      telnet_port: new FormControl('', [Validators.required, Validators.minLength(2)]),
      ipvt_module: new FormControl('', [Validators.required, Validators.minLength(2)]),
      olt_hardware_version_id: new FormControl('', [Validators.required, Validators.minLength(1)]),
      olt_software_version_id: new FormControl('', [Validators.required, Validators.minLength(1)]),
      pon_type_id: new FormControl('', [Validators.required, Validators.minLength(3)]),
      snmp_udp_port: new FormControl('', [Validators.required, Validators.minLength(3)]),
      snmp_read_only: new FormControl('', [Validators.required, Validators.minLength(3)]),
      snmp_read_write: new FormControl('', [Validators.required, Validators.minLength(3)]),
      act_olt_auto: new FormControl(false)

    });
  }


  async ngOnInit() {

   this.getData();

  }

  init(){

    
    let estructura = {
      title: "OLTS",
      text: "Esto es una simple prueba",
      saveButton: "Save",
      closeButton: "Close"
    }

    this.Modal = estructura;

    this.modalTitle = this.Modal.title

    this.modalText = this.Modal.text

    this.buttonSave = this.Modal.saveButton

    this.buttonClose = this.Modal.closeButton

    this.formSections = [
      {
        title: 'OLT name',
        controlName: 'name',
        placeholder: 'OLT name',
        type: 'text',
      },
      {
        title: 'OLT IP',
        controlName: 'ip',
        placeholder: 'OLT IP',
        type: 'text',
      },
      {
        title: 'PON TYPE',
        controlName: 'pon_type_id',
        placeholder: 'Select PON TYPE',
        type: 'select',
        selectOptions: this.itemsPonTypes,
      },
      {
        title: 'Telnet/SSH TCP port',
        controlName: 'telnet_port',
        placeholder: 'Telnet/SSH TCP port',
        type: 'number',
      },
      {
        title: 'IPTV',
        controlName: 'ipvt_module',
        placeholder: 'IPTV',
        type: 'text',
      },
      {
        title: 'OLT hardware version',
        controlName: 'olt_hardware_version_id',
        placeholder: 'OLT hardware version',
        type: 'select',
        selectOptions: this.itemsHardware,
      },
      {
        title: 'OLT SW version',
        controlName: 'olt_software_version_id',
        placeholder: 'OLT SW version',
        type: 'select',
        selectOptions: this.itemsSoftware,
      },
      {
        title: 'SNMP PORT',
        controlName: 'snmp_udp_port',
        placeholder: 'SNMP PORT',
        type: 'text',
      },
      {
        title: 'SNMP Read',
        controlName: 'snmp_read_only',
        placeholder: 'SNMP Read',
        type: 'text',
      },
      {
        title: 'SNMP Read-Write',
        controlName: 'snmp_read_write',
        placeholder: 'SNMP Read-Write',
        type: 'text',
      },
      // {
      //   title: 'Activate OLT automatically',
      //   controlName: 'act_olt_auto',
      //   type: 'checkbox',
      // },
    ];

    this.loading = false;

  }

  async getData(){

    this.loading = true;

    let res = await this.smartOltService.getData('get/hardware');

    this.itemsHardware = res.data;

    res = await this.smartOltService.getData('get/software');

    this.itemsSoftware = res.data;

    console.log(this.itemsSoftware,this.itemsHardware);

    res = await this.smartOltService.getData('olts/listing');

    if (res.data.length > 0) {

      res.data = res.data.map((i: any)=>{

        delete i.hardware_version_id;

        if(i.olt_active != null || i.olt_active != undefined){

          if(i.olt_active == 0){

            i.olt_active = 'Inactive';

          } else if(i.olt_active == 2) {

            i.olt_active = 'Pending';

          } else {

            i.olt_active = 'Active';

          }

          return i
          
        }

      });
      

      this.array = res.data;
    } else {
      this.loading = false;
      this.array = [];
    }

    this.init();

    
  }

  exportToCSV(): void {
    const rows = this.array;
    var fileName = `${new Date().toISOString()}_fibex_olt.csv`;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'text/csv' });
    const fileUrl = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  receiveID(itemId: number) {
    this.id = itemId;
  }

  receiveEdit(editForm:any){
    this.editForm = editForm;
    console.log(this.editForm, 'Recibido')
  }



  recieveActivate(data: any){

    this.activateOlt = data;
  }

  refresh(){
    this.activateOlt = null;
    this.getData();
  }

}