import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import axios from "axios";

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor() { }

  async createLog(idUser: string, action: string, detail: string){
    //!TODO: obtener la direccion ip del usuario
    const IP = '127.0.0.1'


    const body = {
      idUserEmp:idUser,
      idAccion:action,
      Detail: detail,
      IP
    }

    console.log(body)

    return new Promise(async(resolve,reject)=>{
      
        let config = {
            method: 'post',
            url: `${environment.UrlFull}api/v1/create-info/${environment.bd}/${environment.logs.table}`,
            headers: { 
            'x-keys-to-add-id': `["${environment.logs.id}"]`, 
            'x-keys-of-arrays': '[]', 
            'x-relations': 'false', 
            'Content-Type': 'application/json'
            },
            data : body
        };
        axios.request(config)
        .then((response) =>{
          // console.log(response)
          resolve(response.data)
        })
        .catch((error) =>{
          // console.log(error)
          reject(error)
        });
    })
  }
}
