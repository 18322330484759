import { Component, inject, Input} from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { Modal } from 'src/app/core/interfaces/Modal.interface';



@Component({
  selector: 'app-capabilities',
  templateUrl: './capabilities.component.html',
  styleUrls: ['./capabilities.component.scss']
})
export class CapabilitiesComponent {

  @Input() Modal!: Modal;

  form: FormGroup;
  editForm: FormGroup;

  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);

  modalTitle: string = '';
  modalText: string = '';
  buttonSave: string = '';
  buttonClose: string = '';
  id: any;
  loading = false;
  array: any;
  close: boolean = false;
  idCapability: any;
  ElementSelect: any;
  endPoint: string = 'capabilities';
  formSections = [
    {
      title: 'Capability name',
      controlName: 'name',
      type:'text',
    },
  ];
  buttonsHide:boolean = false;


  constructor() {

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)])
    });

    this.editForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)])
    });
  }
  async ngOnInit() {

    this.getData();
 
  }

  async getData(){

    let estructura = {
      title: "Capability",
      text: "Esto es una simple prueba",
      saveButton: "Save",
      closeButton: "Close"
    }

    this.Modal = estructura;

    this.modalTitle = this.Modal.title

    this.modalText = this.Modal.text

    this.buttonSave = this.Modal.saveButton

    this.buttonClose = this.Modal.closeButton

    this.loading = true;

    const data = await this.smartOltService.getData('capabilities/listing');

    if (data.data.length > 0) {
      this.loading = false;
      this.array = data.data;
    } else {
      this.loading = false;
      this.array = [];
    }

  }


  async edit(id: bigint) {
    this.id = id;
    const response = await this.smartOltService.getById('capabilities', id);
    if (response) {
      this.form.reset();
      this.idCapability = response.data.id;
      console.log(response)
      this.editForm = new FormGroup({
        name: new FormControl(response.data.name, [Validators.required, Validators.minLength(5)])
      });
      //console.log(response)
    }
  }
  
  receiveID(itemId: number) {
    this.id = itemId;
  }

  receiveEdit(editForm:any){
    this.editForm = editForm;
    console.log(this.editForm, 'Recibido')
  }

}
