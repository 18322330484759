import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss']
})
export class ImportsComponent {

  file: any = null;
  loading: boolean = false;

  // filas del archivo que se va a subir para validarlo
  filas: any[] = [];

  invalid: any = {
    int: false,
    string: false
  };

  form = new FormGroup({
    file: new FormControl()
  });

  constructor(private smartOltService: SmartOltService){}

  csvImport($event: any) {

    const files = $event.target.files;

    if (files.length) {

      const file = files[0];
      
      // Se manda el archivo de una vez al api y el se encarga de hacer todo

      const reader = new FileReader();
      reader.onload = (event: any) => {

        const wb = XLSX.read(event.target.result);
        const sheets = wb.SheetNames;

        if(sheets.length) {
          const rows = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
          this.filas = rows;

          console.log(this.filas[0]);

          this.filas.forEach(async (fila: any) => {

            let aux = Object.entries(fila).forEach((entry: any) => {
              const [key, value] = entry;

              if (key.includes('id') && key.split('_').length <= 2) {

                if (typeof value != 'number') {

                  this.reset();
                  this.invalid.int = true;

                  let col;
                  if (key.includes('_')) {
                    col = key.split('_').join(' ');
                  } else {
                    col = key;
                  }

                  alert(`Error, el tipo de dato en la columna ${col.toUpperCase()} del registro ${fila.name.toUpperCase()} debe ser un numero!`);

                }

              } else {

                if (typeof value != 'string') {

                  this.reset();
                  this.invalid.string = true;

                  let col;
                  if (key.includes('_')) {
                    col = key.split('_').join(' ');
                  } else {
                    col = key;
                  }

                  alert(`Error, el tipo de dato en la columna ${col.toUpperCase()} del registro ${fila.name.toUpperCase()} debe ser texto!`);
                }

              }

            });
            
          });

          console.log('llego aqui')

          if(!this.invalid.int && !this.invalid.string){

            this.file = file;
    
            console.log('archivo valido', this.invalid)
    
          } else {

            this.reset();
          }
    

        }

      };
      
      reader.readAsArrayBuffer(file);

    
    }

  }

  reset(){

    this.form.reset();
    this.file = null;
    this.invalid.int = false;
    this.invalid.string = false;
  }

  async submitImport(){

    console.log(this.file)

    if(this.file){

      this.loading = true;

      let body = new FormData();

      body.append('import_file', this.file);

      try{

        let res = await this.smartOltService.create('onus_imports', body);
  
        console.log(res);
  
        if(res.status){
  
          this.form.reset();

          this.file = null;
          
          this.loading = false;
  
        }

      } catch(e){

        console.error(e);

      }
  
    } else {

      console.log("No ha adjuntado ningun archivo");

    }

  

  }
}
