import { Component, Renderer2, Output, EventEmitter  } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @Output() statusEmitter = new EventEmitter<string>();
  filters: string = '';
  filters2!: any;
  data: any = [];
  loading = true;
  selectedOlt: any = null;
  itemsOlts: any = [];
  path: any;
  mostrar: any = null;
  numDatosMostrados: any = 10;
  aux: any = [];

  constructor(
    private smartOltService: SmartOltService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    this.getData();

  }

  async getData() {

    let res = await this.smartOltService.getData('olts/listing');


    this.data = res.data;
    this.aux = this.data;

    if (this.data.length > 0) {

      this.itemsOlts = res.data;
      this.loading = false;
    } else {
      this.loading = false;
      this.data = [];
    }

    if (this.numDatosMostrados != 10 || this.mostrar != null) {
      this.numDatosMostrados = 10;
      this.mostrar = null;
    }
    if (this.route.snapshot.queryParams['id']) {
      let olt = this.route.snapshot.queryParams['id']

      this.selectedOlt = olt;
      this.selectOlt(olt);
    }
    this.route.queryParams.subscribe(async (data: any) => {
      if (data.path) {

        this.path = data.path;
      }
    });

  }

  selectOlt(olt: any) {

    this.selectedOlt = olt;
    if (this.selectedOlt === null) {
      this.filters2 = null;
      this.getData();
    } else {
      let dataOlt = this.aux.map((d: any) => {
        if (d.olt_name == this.selectedOlt) {
          return d
        }
      }).filter((d: any) => d != undefined);
      this.data = dataOlt;
      this.numDatosMostrados = this.data.length;
    }
  }

  onInputChange() {
    // Realiza la acción deseada cuando el input cambie
    if (this.filters.length > 4) {
      if (this.mostrar == null) {
        this.mostrar = this.numDatosMostrados;
      }
      this.numDatosMostrados = this.data.length;

    }
    else if (this.mostrar != null && this.filters.length < 3) {
      this.numDatosMostrados = this.mostrar;
      this.mostrar = null;

    }
  }


  refresh() {

    this.loading = true;
    this.selectedOlt = null;
    this.filters = '';
    this.filters2 = -1;
    this.getData();
  }

  selectStatus(status: any) {
    this.enviar();
    console.log('selectStatus called');
    if (status.toLowerCase() === this.filters2) {
      this.filters2 = null;
      if (this.selectedOlt === null) this.numDatosMostrados = 10;

    } else {
      if (this.numDatosMostrados < 100 || this.selectedOlt !== null) this.numDatosMostrados = 100;
      this.filters2 = status.toLowerCase();
    }
  }

  enviar() {
    const status = 'tu_estado';  // Asegúrate de obtener el estado de alguna manera
    console.log('Emitiendo evento con status:', status);
    this.statusEmitter.emit(status);
  }
  

}
