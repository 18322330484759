import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IControl } from '../json-form/interfaces/IControl.interface';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit {
  @Input() Field: any;
  @Input() TitleSow: any;
  @Output() ValueSalida = new EventEmitter<string>();

  public ArrayDataIn: any[]=[]

  ngOnInit(): void {
      console.log("P")
      console.log(this.Field)
      this.ArrayDataIn = this.Field
  }

}