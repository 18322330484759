import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { dbFullService } from 'src/app/services/dbFull.service';
import { ObservableComponentsService } from 'src/app/services/observable-components.service';

@Component({
  selector: 'app-master-crud',
  templateUrl: './master-crud.component.html',
  styleUrls: ['./master-crud.component.scss']
})
export class MasterCrudComponent implements  AfterViewInit, OnInit, OnDestroy {
    @Input() TableName: any; 
    @Input() Atributos: any =  { 'title': 'Control', 'Description': 'Listado...' };     
    @Output() ValueSalida = new EventEmitter<string>();
    @Input() showCreateBtn: boolean = false;
    
    public thead: any[]=[]
    public DataFormOpen: any;
    public openCreateElement: boolean = false; 
    public openCreateELementInit: boolean = true; 
    public updateData: any;
    
    public optionsMenu=[
      {id:1, name: 'Eliminar', icon:'icon ion-trash-a'},
      {id:2, name:'Editar', icon:'icon ion-edit'},
      {id:3, name:'Sucursales', icon:'fa fa-building'}
    ]

    public Cargando = true
    constructor( private obsServer: ObservableComponentsService ) {
      this.initSuscriptions();
      // Voy a 
      // this.thead = ["ID", "Nombre", "Rif", "Vendedor", "URL", "Status", "Prioridad"]
    }

    ngOnDestroy(): void {
      this.obsServer.paramData$.emit(false);
    }

    ngAfterViewInit(): void { 
      
    }

    ngOnInit(): void { 
      const FullDBObj = new dbFullService()
      // this.TableName = "cb_EmpresaProductos"
      
      FullDBObj.GetAllDataCampo("cb_Estructura", "TableName", this.TableName).then((Estructura: any) => { 
          console.log(Estructura)
          this.DataFormOpen = {
            TitleHead: this.Atributos?.title,
            Table: this.TableName,
            Estructura: Estructura,
            Inputs: [],
            Value:[]//"idUserEmp_3jKgVz"
          }
          this.Atributos.Estructura = Estructura
          const ArgTemp: any[] = []
          Estructura.forEach((element:any, index: number) => {
            if (element.ShowList) {
              ArgTemp.push(element.HeadListName)
            }
            // console.log(element)
            if (element.PK == 1)
            if (Estructura.length - 1 == index ) { 
              this.thead = ArgTemp
              this.Cargando = false
            }
          }); 
      })
    }

    RecibirClicMenurEvent(event:any){
      // console.log("RecibirClicMenurEvent");
      // console.log(event);
    }



    ngOnChanges(changes: SimpleChanges) { 
      // changes.prop contains the old and the new value...
      // Voy a buscar los campos de la tabla
      // console.log(changes['prop'])
      
     
    } 

    toOpenCreateElement() {
      this.openCreateElement = true;
      this.openCreateELementInit = false;
    }

    toCloseCreateElement( event?: any ) {
      if( event !== undefined && event.data.length > 0) {
        // console.log(event.data)
        this.updateData = event.data;
        // this.updateData = true;
      }
      this.openCreateElement = false;
      this.openCreateELementInit = false;
      setTimeout(() => {
        this.openCreateELementInit = true;
      }, 1000)
      
    }

    initSuscriptions() {
      this.obsServer.paramData$.subscribe(res => {
        if( res ) {
          this.toOpenCreateElement();
        } return;
      });
    }
}
