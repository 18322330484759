
<div class="row mb-3 export">
    <div class="col-12 mb-3">
      <button class="btn btn-primary" routerLink="/dashboard/home" *ngIf="path == 'home'">
        <i class="fa fa-mail-reply"></i> Back to dashboard
      </button>
      </div>
  
    <div class="col-md-1 align-self-end mb-4">
      <label class="control-label">Actions</label>
      <div class="d-flex">
        <a class="btn btn-primary text-white" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
        <a class="btn btn-success text-white ml-2" (click)="true" data-toggle="modal" data-target="#newModal"><i class="fa fa-plus" aria-hidden="true"></i> Add ONU for later authorization</a>
      </div>
    </div>
  </div>
  
  <div class="p-2">
    <div *ngIf="loading; else contentList" class="div-centrado">
      <app-loader></app-loader>
    </div>
  
    <ng-template #contentList>
  
      <app-lista-backend [itemsPerPage]="10" [showFilterPanel]="showFilterPanel" [onuFilters]="false" [viewEndpoint]="viewEndpoint" [title]="title" [data]="data" [endPoint]="endPoint" [authorizeButton]="autorizeButton" [queryParams]="queryParams" [formSections]="formSections" (sendQueryParams)="recieveQueryParams($event)" (itemIdEmitter)="receiveID($event)" [editForm]="editForm" (formEditEmitter)="receiveEdit($event)"> </app-lista-backend>
  
    </ng-template>
  </div>

  <app-modal *ngIf="!loading" [title]="'unconfigured ONU'" [formSections]="formSections" [id]="onuId" [endPoint]="endPoint" [editForm]="editForm" [form]="form" (dataEmitter)="getData()"></app-modal> 