
<div>
    <div *ngIf="loading; else contentList" class="div-centrado">
        <app-loader></app-loader>
    </div>


    <ng-template #contentList>
    
     <app-lista-backend [actionsButtons]="true" [itemsPerPage]="10" [showFilterPanel]="true" [oltFilter]="true" [title]="'ONU'" [data]="array" [endPoint]="endPoint" [formSections]="formSections" [editForm]="editForm" [queryParams]="queryParams" (formEditEmitter)="receiveEdit($event)"  (sendQueryParams)="recieveQueryParams($event)" (itemIdEmitter)="receiveID($event)"> </app-lista-backend>

    </ng-template>
</div>

<app-modal *ngIf="!loading" [title]="modalTitle" [formSections]="formSections" [id]="id" [endPoint]="endPoint" [editForm]="editForm" [form]="form" (dataEmitter)="getData()"></app-modal> 
