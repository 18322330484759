import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy{

  form: FormGroup;
  loading = false;
  
  idleState: string = 'Not Started';

  private onIdleSubscriptions: Subscription = new Subscription;

  constructor(formBuilder: FormBuilder, private router: Router, private userService: UserService, private utilsService: UtilsService, private smartOltService: SmartOltService, private idle: Idle, 
  ){
    this.form = formBuilder.group({
      oldPassword: ['', Validators.required, this.validOldPassword],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    }, {
      validator: this.passwordConfirmation
    });

    idle.setIdle(60);
    idle.setTimeout(1200); // 20 minutos de inactividad
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.onIdleSubscriptions.add(
      this.idle.onIdleStart.subscribe(()=>{
        this.idleState = 'Idle'
        console.log('Innactive');
      })
    ); 

    this.onIdleSubscriptions.add(
      this.idle.onTimeout.subscribe(()=>{
        this.idleState = "Timed Out";
        console.log('Logged out');
  
        this.userService.logOut();
  
      })
    ); 

    this.onIdleSubscriptions.add(
     
      this.idle.onIdleEnd.subscribe(()=>{
        this.idleState = 'Started';

        console.log('Active again');
      })
    ); 


  }

  get oldPassword(){
    return this.form.get('oldPassword');
  }
  get newPassword(){
    return this.form.get('newPassword');
  }
  get confirmPassword(){
    return this.form.get('confirmPassword');
  }

  ngOnInit(): void {
    this.setState();

  }

  
  ngOnDestroy(): void {

    if(this.onIdleSubscriptions){

      this.idle.stop();
      this.onIdleSubscriptions.unsubscribe();

    }
    
  }

  setState(){
    this.idle.watch();
    this.idleState = 'Started';

  }

  async onSubmit(){

    let userId = JSON.parse(sessionStorage.getItem('loginInfo')!).id;

    let user = {
      id: userId,
      current_password: this.form.value.oldPassword,
      new_password: this.form.value.confirmPassword

    }

    try {

      let res = await this.smartOltService.changePassword(user);

      console.log(res);

      if((!res.current_password || !res.new_password) && res[0] == "¡Contraseña actualizada exitosamente!"){

        this.form.reset();

        this.utilsService.showSuccess(res[0]);
        
        this.loading = false;

      } else {

        this.utilsService.showError("Ocurrio un error");
        
        this.loading = false;

      }

        
    } catch (error) {

      console.error(error);
      this.utilsService.showError("Error, la contraseña actual no coincide con la contraseña registrada");
      this.loading = false;
        
    }

  }

  exit(){
    this.router.navigate(['/'])

  }

  validOldPassword(control: any){
    return new Promise((resolve) => {

      // if(control.value !== '1234'){
      if(false){
        resolve( { invalidOldPassword: true });
      } else {
        resolve(null);
      }

    });
  }

  passwordConfirmation(control: any){
    let newPassword = control.get('newPassword');
    let confirmPassword = control.get('confirmPassword');

    if(newPassword.value != confirmPassword.value){
      return { doNotMatch: true};
    } else {
      return null;
    }

  }

}
