import { EventEmitter, Injectable } from '@angular/core';
import axios from 'axios';
import { error } from 'jquery';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SmartOltService {

  private baseUrl = environment.apiUrl;

  reset = new EventEmitter<any>();

  private _accessToken = '';

  private _refresh$ = new Subject<void>();

  constructor() {}

  reseting(){
    this.reset.emit(true);
  }

  get refresh$(){
    return this._refresh$;
  }

  get getToken(){

    return this._accessToken;
  }

  set setToken(value: string){

    this._accessToken = value;

  }

  login(user: any, password: any){

    let headers = {
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
        axios.post(`${this.baseUrl}/login?email=${user}&password=${password}`,  { headers: headers}).then((res: any) => {

          resolve(res.data);
        }, error =>{

          reject(error);

        });
    
    });

  }

  
  changePassword(user: any){

    let headers = {
      AK: environment.apiAuthKey
    } 

    let body = {
      "id": user.id,
      "current_password": user.current_password,
      "new_password": user.new_password
    }
    
    return new Promise<any>((resolve, reject) => {
      
        axios.post(`${this.baseUrl}/change/password`, body, { headers: headers}).then((res: any) => {

          resolve(res.data);
          
        }, error =>{

          reject(error)

        });
      
    });

  }

  signUp(user: any, name: any, password: any){

    let headers = {
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
        axios.post(`${this.baseUrl}/register?email=${user}&name=${name}&password=${password}`,  { headers: headers}).then((res: any) => {

          resolve(res.data);
        }, error =>{

          reject(error);

        });
      
    });

  }


  // Metodo para obtener datos
  async getData(url: string) {

    this.setToken = sessionStorage.getItem('login') || '';

    let headers = {
      Authorization: 'Bearer ' + this.getToken,
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
     
      axios.get(`${this.baseUrl}/${url}`,  { headers: headers}).then((res: any) => {
        resolve(res.data);

        
      },
      error => {
  
        reject(error);
    
      });

    });

  }

  // Metodo para insertar datos
  create(url: string, data: any) {

    this.setToken = sessionStorage.getItem('login') || '';

   let headers = {
      Authorization: 'Bearer ' + this.getToken,
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
        axios.post(`${this.baseUrl}/${url}`, data,  { headers: headers}).then((res: any) => {
          resolve(res.data);
        }, error =>{

          reject(error);

        });
    });

  }

  // Metodo par obtener mediante ID
  getById(url: string, id: bigint) {

    this.setToken = sessionStorage.getItem('login') || '';

   let headers = {
      Authorization: 'Bearer ' + this.getToken,
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
        axios.get(`${this.baseUrl}/${url}/${id}`,  { headers: headers}).then((res: any) => {
          resolve(res.data);
        }, error =>{

          reject(error);

        });
    });
  }

  

  // Metodo para actualizar un registro
  async update(url: string, id: BigInteger, data: any) {

    this.setToken = sessionStorage.getItem('login') || '';

   let headers = {
      Authorization: 'Bearer ' + this.getToken,
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
        axios.patch(`${this.baseUrl}/${url}/${id}`, data,  { headers: headers}).then((res: any) => {
          resolve(res.data);
        }, error =>{

          reject(error);

        });
    });
    
  }

  async authorize(url: string, id: BigInteger, data: any) {

    this.setToken = sessionStorage.getItem('login') || '';

   let headers = {
      Authorization: 'Bearer ' + this.getToken,
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
        axios.patch(`${this.baseUrl}/${url}/authorize/${id}`, data,  { headers: headers}).then((res: any) => {
          resolve(res.data);
        }, error =>{

          reject(error);

        });
    });
    
  }

  // Metodo para eliminar datos
  async delete(url: string, id: any) {

    this.setToken = sessionStorage.getItem('login') || '';

   let headers = {
      Authorization: 'Bearer ' + this.getToken,
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
        axios.delete(`${this.baseUrl}/${url}/${id}`,  { headers: headers}).then((res: any) => {
          resolve(res.data);
        }, error =>{

          reject(error);

        });
    });
    
  }

  // Metodo para obtener el numero de pagina o realizar paginado
  async paginate(url: string, page: number) {

    this.setToken = sessionStorage.getItem('login') || '';

   let headers = {
      Authorization: 'Bearer ' + this.getToken,
      AK: environment.apiAuthKey
    } 
    
    return new Promise<any>(async (resolve, reject) => {
        axios.get(`${this.baseUrl}/${url}?page=${page}`,  { headers: headers}).then((res: any) => {
          resolve(res.data);
        }, error =>{

          reject(error);

        });
    });
    
  }
}
