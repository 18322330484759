<div class="main">


  <form [formGroup]="form" (ngSubmit)="onSubmit()">

<!-- 
    <div class="close-btn">
      <i class="fa fa-times" aria-hidden="true" (click)="exit()"></i>
    </div> -->

    <div class="form-group">
      <label for=""><b>Contraseña actual</b></label>
      <input
        formControlName="oldPassword"
        type="password"
        class="form-control"
      />
      <div
        class="alert alert-danger"
        *ngIf="oldPassword!.touched && oldPassword!.invalid"
      >
        <div *ngIf="oldPassword!.errors!['required']">
          La contraseña actual es requerida
        </div>
        <div *ngIf="oldPassword!.errors!['invalidOldPassword']">
          Contraseña actual incorrecta
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for=""><b>Contraseña nueva</b></label>
      <input
        formControlName="newPassword"
        type="password"
        class="form-control"
      />
      <div
        class="alert alert-danger"
        *ngIf="newPassword!.touched && newPassword!.invalid"
      >
        <div *ngIf="newPassword!.errors!['required']">
          La contraseña nueva es requerida
        </div>

        <div *ngIf="newPassword!.errors!['minlength']">
          La contraseña nueva debe tener mínimo 8 caracteres
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for=""><b>Confirmar contraseña nueva</b></label>
      <input
        formControlName="confirmPassword"
        type="password"
        class="form-control"
      />
      <div
        class="alert alert-danger"
        *ngIf="confirmPassword!.touched && confirmPassword!.invalid"
      >
        <div *ngIf="confirmPassword!.errors!['required']">
          La contraseña nueva es requerida
        </div>

        <div *ngIf="confirmPassword!.errors!['minlength']">
          La contraseña nueva debe tener mínimo 8 caracteres
        </div>
      </div>

      <div
        class="alert alert-danger"
        *ngIf="confirmPassword!.touched && form.invalid && form.errors?.['doNotMatch']"
      >
        Las contraseñas no coinciden
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="form.invalid || loading"
    >
      Cambiar Contraseña
      <i *ngIf="loading" class="fa fa-spinner fa-spin margin-left"></i>
    </button>
  </form>
</div>
