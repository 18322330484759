import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { SmartOltService } from 'src/app/services/smart-olt.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent {

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(5)]),
    ip: new FormControl('', [Validators.required, Validators.minLength(5)]),
    telnet_ssh_tcp_port: new FormControl('', [Validators.required, Validators.minLength(2)]),
    ipvt: new FormControl('', [Validators.required, Validators.minLength(2)]),
    oltHardwareVersion: new FormControl('', [Validators.required, Validators.minLength(1)]),
    oltSwVersion: new FormControl('', [Validators.required, Validators.minLength(1)]),
    support_pon_type: new FormControl('', [Validators.required, Validators.minLength(3)]),
    snmp_port: new FormControl('', [Validators.required, Validators.minLength(3)])
  });

  constructor(private smartSvc: SmartOltService, private router: Router, private activeRoute: ActivatedRoute, private gsnackbar: GenericSnackbarService) { }

  updateBtn: boolean = false;
  idOlt: any;

  selectedPonType: any;
  itemsPonTypes: any = [
    { id: 'gpon', name: 'GPON' },
    { id: 'epon', name: 'EPON' },
    { id: 'gpon_epon', name: 'GPON | EPON' },
  ];

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.activeRoute.queryParams.subscribe(async (data: any) => {
      if (data.id) {
        this.smartSvc.getById('olts', await data.id).then((res: any) => {
          //console.log(res.data);
          const items = res.data;
          this.idOlt = items.id;
          let olt = {
            name: items[0].name,
            ip: items[0].ip,
            telnet_ssh_tcp_port: items[0].telnet_port,
            ipvt: items[0].ipvt || null,
            oltHardwareVersion: items[0].oltHardwareVersion,
            oltSwVersion: items[0].oltSwVersion,
            snmp_port: items[0].snmp_port,
            support_pon_type: items[0].support_pon_type || null,
          };
          this.updateBtn = true;
          this.form.setValue(olt);
          this.form.updateValueAndValidity();
        });
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.smartSvc.create('olts', this.form.value).then((res) => {
        console.log(res);
        if (res.data === true) {
          this.form.reset();
          this.router.navigateByUrl('/dashboard/settings/olts');
          this.gsnackbar.runSnackBarNoCallback("Registro guardado con exito.", 1, true, 3000);
        }
      });
    }
  }

  onUpdate() {
    if (this.form.valid) {
      this.smartSvc.update('olts', this.idOlt, this.form.value).then((res) => {
        if (res) {
          this.form.reset();
          this.router.navigateByUrl('/dashboard/settings/olts');
          this.gsnackbar.runSnackBarNoCallback("Registro Actualizado con exito.", 1, true, 3000);
        }
      })
    }
  }

}
