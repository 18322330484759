<div>
    <div *ngIf="loading; else contentList" class="div-centrado">
        <app-loader></app-loader>
    </div>

    <ng-template #contentList>

    
        <div class="col-md-12">
            <app-lista-frontend *ngIf="!loading" [showFilter]="true" [statusFilter]="false" [viewEndpoint]="viewEndpoint" [viewbtn]="viewbtn" [actionsButtons]="true" [formSections]="formSections" [title]="modalTitle" [data]="array" [endPoint]="endPoint" [form]="form" [editForm]="editForm" (refresh)="refresh()" (formEditEmitter)="receiveEdit($event)" (itemIdEmitter)="receiveID($event)" [activateOlt]="activateOlt" > </app-lista-frontend>
        </div>
    </ng-template>
</div>

<app-modal *ngIf="!loading" [title]="modalTitle" [formSections]="formSections" [id]="id" [endPoint]="endPoint" [editForm]="editForm" [form]="form" [selectOptions]="options" (dataEmitter)="getData()" (activateOlt)="recieveActivate($event)"></app-modal> 
