<div class="main">


    <div class="table">

        <div class="buttons">

            <button class="btn btn-primary" [routerLink]="'/dashboard/profiles'"><i class="fa fa-mail-reply"></i> Back</button>

            <button *appUserRole="['Administrar permisos']" class="btn btn-success" [routerLink]="'/dashboard/permissions'"><i class="fa fa-unlock" aria-hidden="true"></i> Administrar Permisos</button>
    
        
        </div>


       
        <div *ngIf="loading; else contentList" class="div-centrado">
            <app-loader></app-loader>
        </div>

        <ng-template #contentList>

            <app-lista-frontend [showFilter]="true" [placeholder]="'Name'" [statusFilter]="false" [endPoint]="'role'" [exportOpts]="false" [actionsButtons]="true" [title]="'Roles'"  [data]="rolesList" [form]="form" [editForm]="editForm"  [formSections]="formSections" (formEditEmitter)="receiveEdit($event)" (itemIdEmitter)="receiveID($event)"> </app-lista-frontend>

        </ng-template>


    </div>
    
</div>
  
<app-modal *ngIf="!loading" [title]="'Role'" [formSections]="formSections" [item]="item" [id]="id" [endPoint]="'role'" [editForm]="editForm" [form]="form" (dataEmitter)="getData()"></app-modal> 
