<div class="main-wrapper">
  <div class="d-flex flex-column aling-items-center" *ngIf="showFilter">
    <!-- <app-filter (statusEmitter)="aplicarFiltro($event)"></app-filter> -->
    <!--FILTRO PARA COMPONENE-->
    <div class="d-flex p-2 aling-items-center">
      <div class="col-md-3" *ngIf="oltFilter">
        <div class="form-group">
          <label class="control-label"> Select OLT</label>
          <ng-select
            [items]="itemsOlts"
            (change)="selectOlt()"
            [clearable]="true"
            notFoundText="No data found"
            placeholder="Select OTL"
            bindLabel="name"
            bindValue="name"
            [(ngModel)]="selectedOlt"
          >
          </ng-select>
          
        </div>
      </div>
  
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Filter</label>
          <input
            type="text"
            class="form-control"
            [placeholder]="placeholder"
            name="filters"
            #textFilter
            (keyup)="onInputChange(textFilter.value)"
          />
        </div>
      </div>
  
      <div class="col-md-4" *ngIf="statusFilter">
        <div class="form-group">
          <label for="status" class="control-label">Status</label>
  
          <ul class="pagination">
            <li
              class="status-filter mr-2"
              (click)="selectStatus(1)"
              title="Online"
            >
              <span
                ><i
                  class="fa fa-globe fa-sm text-green btn btn-light"
                  [ngClass]="statusSignalFilter == 1 ? 'active' : ''"
                ></i
              ></span>
            </li>
  
            <li
              class="status-filter mr-2"
              (click)="selectStatus(2)"
              title="Offline"
            >
              <span
                ><i
                  class="fa fa-globe fa-sm text-red btn btn-light"
                  [ngClass]="statusSignalFilter == 2 ? 'active' : ''"
                ></i
              ></span>
            </li>
  
            <li
              class="status-filter mr-2"
              (click)="selectStatus(3)"
              title="Unknown"
            >
              <span
                ><i
                  class="fa fa-globe fa-sm text-warning btn btn-light"
                  [ngClass]="statusSignalFilter == 3 ? 'active' : ''"
                ></i
              ></span>
            </li>
            <!-- <li
              class="status-filter mr-2"
              #powerFail
              value="powerfail"
              title="Power Fail"
              (click)="selectStatus('power fail')"
            >
              <span
                ><i
                  class="fa fa-plug fa-sm text-grey btn btn-light"
                  [ngClass]="statusSignalFilter == 'power fail' ? 'active' : ''"
                ></i
              ></span>
            </li>
            <li
              class="status-filter mr-2"
              #los
              value="los"
              title="Los Of Signal"
              (click)="selectStatus('LOS')"
            >
              <span
                ><i
                  class="fa fa-chain-broken fa-sm text-red btn btn-light"
                  [ngClass]="statusSignalFilter == 'LOS' ? 'active' : ''"
                ></i
              ></span>
            </li>
            <li
              class="status-filter mr-2"
              #offline
              value="offline"
              title="Offline"
              (click)="selectStatus('offline')"
            >
              <span
                ><i
                  class="fa fa-globe fa-sm text-red btn btn-light"
                  [ngClass]="statusSignalFilter == 'offline' ? 'active' : ''"
                ></i
              ></span>
            </li>
            <li
              class="status-filter mr-2"
              #disabled
              value="disabled"
              title="Disabled"
              (click)="selectStatus('Disabled')"
            >
              <span
                ><i
                  class="fa fa-ban fa-sm text-grey btn btn-light"
                  [ngClass]="statusSignalFilter == 'Disabled' ? 'active' : ''"
                ></i
              ></span>
            </li>
            <li
              class="status-filter mr-2"
              value="very good"
              (click)="selectStatus('Very good')"
              title="Very good"
            >
              <span
                ><i
                  class="fa fa-signal fa-md text-success btn btn-light"
                  [ngClass]="statusSignalFilter == 'very good' ? 'active' : ''"
                ></i
              ></span>
            </li>
            <li
              class="status-filter mr-2"
              value="warning"
              title="Warning"
              (click)="selectStatus('Warning')"
            >
              <span
                ><i
                  class="fa fa-signal fa-md text-warning btn btn-light"
                  [ngClass]="statusSignalFilter == 'warning' ? 'active' : ''"
                ></i
              ></span>
            </li>
            <li
              class="status-filter mr-2"
              value="critical"
              title="Critical"
              (click)="selectStatus('Critical')"
            >
              <span
                ><i
                  class="fa fa-signal fa-md text-danger btn btn-light"
                  [ngClass]="statusSignalFilter == 'critical' ? 'active' : ''"
                ></i
              ></span>
            </li> -->
          </ul>
        </div>
      </div>
      <!--Para exportar-->
      <div class="col-md-2 aling-items-center" *ngIf="exportOpts">
        <label class="control-label">Export</label>
        <app-export
          [info]="
            data
              | listFilters
                : this.searchText[0]
                : headers
                : currentPage
                : itemsPerPage
                : searchText
                : statusSignalFilter
                : selectedOlt
          "
          [columns]="headers"
        ></app-export>
      </div>
    </div>
  </div>
  
  <div class="col-md-12">
    <div class="card bd-primary">
      <div class="card-header bg-dark text-white">
        <span>{{ title }} list</span>
  
        <ng-container *ngIf="actionsButtons">
          <button
            *appUserRole="['Crear registros']"
            class="btn btn-success float-right"
            data-toggle="modal"
            data-target="#newModal"
          >
          <i class="fa fa-plus" aria-hidden="true"></i>
            Add {{ title }}
          </button>
        </ng-container>
      </div>
      <div class="card-body pd-sm-30">
        <div class="table-responsive">
          <table
            class="table-striped table border"
            *ngIf="data && data.length > 0; else arrayEmpty"
          >
            <thead>
              <tr>
                <ng-container *ngIf="viewbtn">
                  <th>View</th>
                </ng-container>
  
                <ng-container *ngIf="autorizeBtn">
                  <th *appUserRole="['Autorizar onus']">Authorize</th>
                </ng-container>
  
                <ng-container *ngIf="tab == 'profiles'">
                  <th>Image</th>
                </ng-container>
  
                <ng-container scope="col" *ngFor="let header of headers">
                  <ng-container [ngSwitch]="header">
                    <th *ngSwitchCase="'pon_type_id'">Type</th>
                    <th *ngSwitchDefault>
                      {{
                        header.includes("_")
                          ? header.split("_").join(" ")
                          : header == "pon_type_id"
                          ? "Type"
                          : header
                      }}
                    </th>
                  </ng-container>
                </ng-container>
  
                <ng-container *ngIf="actionsButtons">
                  <th *ngIf="title == 'OLTS'">Activate OLT</th>
  
                  <th *appUserRole="['Editar registros']">Actions</th>
                </ng-container>
              </tr>
            </thead>
            <tbody #body>
              <tr
                *ngFor="
                  let item of data
                    | listFilters
                      : this.searchText[0]
                      : headers
                      : currentPage
                      : itemsPerPage
                      : searchText
                      : statusSignalFilter
                      : selectedOlt
  
                "
              >
                <td *ngIf="viewbtn" class="text-center">
                  <a
                    [routerLink]="['/dashboard/' + viewEndpoint]"
                    [queryParams]="{ id: item.id }"
                    class="btn btn-success"
                    >View</a
                  >
                </td>
  
                <ng-container *ngIf="autorizeBtn">
                  <td *appUserRole="['Autorizar onus']" class="text-center">
                    <a
                      data-toggle="modal"
                      data-target="#authorizeModal"
                      class="btn btn-success text-white"
                      >Authorize</a
                    >
                  </td>
                </ng-container>
  
                <ng-container *ngIf="tab == 'profiles'">
                  <td class="text-center" id="">
                    <img [src]="item.img" alt="" />
                  </td>
                </ng-container>
  
                <ng-container *ngFor="let key of headers">
                  <ng-container [ngSwitch]="key">
                    <td *ngSwitchCase="'status'" class="text-center">
                      <i
                        [title]="
                          item[key] == 1
                            ? 'Online'
                            : item[key] == 2
                            ? 'Offline'
                            : 'Unknown'
                        "
                        [class]="switchStatusIcon(item[key])"
                      >
                      </i>
                    </td>
  
                    <td *ngSwitchCase="'tunnel_status'" class="text-center">
                      <i
                        [title]="
                          item[key] == 1
                            ? 'Online'
                            : item[key] == 2
                            ? 'Offline'
                            : 'Unknown'
                        "
                        [class]="switchStatusIcon(item[key])"
                      >
                      </i>
                    </td>
  
                    <!-- PON PORTS ------------------->
  
                    <td *ngSwitchCase="'average_signal'" class="text-center">
                      <i
                        [title]="item[key]"
                        [class]="switchStatusIcon(item[key])"
                      ></i>
                    </td>
  
                    <td *ngSwitchCase="'signal'" class="text-center">
                      <i
                        [title]="item[key]"
                        [class]="switchStatusIcon(item[key])"
                      ></i>
                    </td>
  
                    <td *ngSwitchCase="'pon_type_id'" class="text-center" id="">
                      {{
                        item[key] == 1
                          ? "GPON"
                          : item[key] == 2
                          ? "EPON"
                          : "GPON / EPON"
                      }}
                    </td>
  
                    <td *ngSwitchCase="'operational_status'">
                      <i
                        [title]="
                          item[key] == 1
                            ? 'Online'
                            : item[key] == 2
                            ? 'Offline'
                            : 'Unknown'
                        "
                        [class]="switchStatusIcon(item[key])"
                      >
                      </i>
                    </td>
  
                    <td *ngSwitchCase="'admin_status'" class="text-center" id="">
                      {{
                        item[key] == 1
                          ? "ENABLED"
                          : item[key] == 2
                          ? "DISABLED"
                          : "UNKNOWN"
                      }}
                    </td>
  
                    <!-- UPLINKS ------------ -->
  
                    <ng-container *ngIf="tab == 'uplinks'">
                      <td *ngSwitchCase="'type'" class="text-center" id="">
                        {{
                          item[key] == "6" ? "Ethernet CSMA/CD" : "Fast Ethernet"
                        }}
                      </td>
                    </ng-container>
  
                    <td *ngSwitchCase="'admin_state'" class="text-center" id="">
                      {{
                        item[key] == 1
                          ? "ENABLED"
                          : item[key] == 2
                          ? "DISABLED"
                          : "UNKNOWN"
                      }}
                    </td>
  
                    <td *ngSwitchCase="'updated_at'" class="text-center" id="">
                      {{ item[key] | date : "MMMM d y, h:mm:ss a" }}
                    </td>
  
                    <td *ngSwitchCase="'created_at'" class="text-center" id="">
                      {{ item[key] | date : "MMMM d y, h:mm:ss a" }}
                    </td>
  
                    <!-- VLANS---------------- -->
  
                    <td
                      *ngSwitchCase="'multicast_vlan'"
                      class="text-center"
                      id=""
                    >
                      <i
                        [class]="
                          item[key]
                            ? 'fa fa-check-square text-success'
                            : 'fa fa-window-close text-danger'
                        "
                      ></i>
                    </td>
  
                    <td
                      *ngSwitchCase="'management_voip'"
                      class="text-center"
                      id=""
                    >
                      <i
                        [class]="
                          item[key]
                            ? 'fa fa-check-square text-success'
                            : 'fa fa-window-close text-danger'
                        "
                      ></i>
                    </td>
  
                    <td *ngSwitchCase="'dhcp_snooping'" class="text-center" id="">
                      <i
                        [class]="
                          item[key]
                            ? 'fa fa-check-square text-success'
                            : 'fa fa-window-close text-danger'
                        "
                      ></i>
                    </td>
  
                    <td *ngSwitchCase="'lan_to_lan'" class="text-center" id="">
                      <i
                        [class]="
                          item[key]
                            ? 'fa fa-check-square text-success'
                            : 'fa fa-window-close text-danger'
                        "
                      ></i>
                    </td>
  
                    <td *ngSwitchDefault>
                      {{ checkIfArray(item[key]) }}
                    </td>
                  </ng-container>
                </ng-container>
  
                <td *ngIf="tab == 'oltCards'" class="text-center">
                  <button class="btn btn-primary">Reboot card</button>
                </td>
  
                <td *ngIf="tab == 'ponPorts'">
                  <button
                    data-toggle="modal"
                    data-target="#configurePonModal"
                    class="btn btn-primary"
                  >
                    Configure
                  </button>
                </td>
  
                <td *ngIf="tab == 'uplinks'">
                  <button
                    data-toggle="modal"
                    data-target="#configureuplink"
                    class="btn btn-primary"
                  >
                    Configure
                  </button>
                </td>
  
                <td *ngIf="tab == 'vlans'">
                  <button class="btn btn-danger">Delete</button>
                </td>
  
                <ng-container *ngIf="actionsButtons">
                  <td *ngIf="title == 'OLTS'">
                    <button
                      title="Activate OLT"
                      (click)="syncOlt(item.id)"
                      [class]="
                        item.olt_active == 'Active'
                          ? 'btn btn-success btn-sm mx-2'
                          : item.olt_active == 'Pending'
                          ? 'btn btn-warning btn-sm mx-2'
                          : 'btn btn-danger btn-sm mx-2'
                      "
                      [disabled]="item.olt_active != 'Inactive' || searching"
                    >
                      <i
                        *ngIf="!searching && item.olt_active != 'Pending'"
                        class="fa fa-power-off text-white"
                      ></i>
                      <i
                        *ngIf="!searching && item.olt_active == 'Pending'"
                        class="fa fa-clock-o text-white"
                      ></i>
                      <i
                        *ngIf="searching"
                        class="fa fa-spinner fa-spin margin-left"
                      ></i>
                    </button>
                  </td>
  
                  <td >
                    <button *appUserRole="['Editar registros']"
                      (click)="edit(item.id)"
                      data-toggle="modal"
                      data-target="#editModal"
                      class="btn btn-secondary btn-sm m-2"
                      title="Edit"
                      [disabled]="checkAdmiRole(item)"
                    >
                      <i class="fa fa-pencil-square-o text-white"></i>
                    </button>
  
                    <button  *appUserRole="['Eliminar registros']"
                      class="btn btn-danger btn-sm m-2"
                      data-toggle="modal"
                      (click)="toDelete(item.id)"
                      data-target="#deleteModal"
                      title="Delete"
                      [disabled]="
                        checkUserSession(item.id) || checkAdmiRole(item)
                      "
                    >
                      <i class="fa fa-trash text-white"></i>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
          <ng-template #arrayEmpty>
            <h5 class="text-center">No results</h5>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="btn-pagination align-items-center">
      <button
        class="btn btn-list"
        (click)="previousPage()"
        [disabled]="currentPage === 1"
      >
        Previous
      </button>
      <span class="number">{{ currentPage }} of {{ getTotalPages() }}</span>
      <button
        class="btn btn-list"
        (click)="nextPage()"
        [disabled]="currentPage === getTotalPages()"
      >
        Next
      </button>
    </div>
  </div>
  
</div>