<div class="dropdown dropdown-profile">
  <a href="" data-toggle="dropdown" class="dropdown-link">
    <!-- Imagen a Cambiar -->

    <img src="assets/img/avatar.jpg" class="wd-60 rounded-circle" alt="" />
  </a>
  <div class="dropdown-menu dropdown-menu-right">
    <div class="media align-items-center">
      <!-- Imagen a Cambiar -->
      <img
        src="assets/img/avatar.jpg"
        class="wd-60 ht-60 rounded-circle bd pd-5"
        alt=""
      />
      <div class="media-body">
        <!-- Nombre a Cambiar -->
        <h6 class="tx-inverse tx-15 mg-b-5">{{ login.name }}</h6>
        <!-- Email a Cambiar -->
        <p class="mg-b-0 tx-12">{{ login.email }}</p>
      </div>
      <!-- media-body -->
    </div>
    <!-- media -->
    <hr />
    <ul class="dropdown-profile-nav">
      <li role="button">
        <!-- <ng-container *ngFor="let probtn of profileBtns">
          <a [routerLink]="probtn.route ? probtn.route : null">
            <i [class]="probtn.styleRules?.icon"></i>{{ probtn.name }}
          </a>
        </ng-container>
        <hr /> -->
        <a *appUserRole="['Administrar Usuarios']" (click)="profiles()" style="cursor: pointer"
          ><i class="fa fa-users" aria-hidden="true"></i> Administrar
          Usuarios</a
        >
        <a (click)="changePassword()" style="cursor: pointer"
          ><i class="fa fa-key" aria-hidden="true"></i> Cambiar Contraseña</a
        >
        <a (click)="logout()" style="cursor: pointer"
          ><i class="icon ion-power"></i> Cerrar Sesión</a
        >
      </li>
    </ul>
  </div>
  <!-- dropdown-menu -->
</div>
