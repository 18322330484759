<div class="row mb-3 export">
  <div class="col-12 mb-3">
    <button class="btn btn-primary" routerLink="/dashboard/home" *ngIf="path == 'home'">
      <i class="fa fa-mail-reply"></i> Back to dashboard
    </button>
    </div>

  <div class="col-md-1 align-self-end mb-4">
    <label class="control-label">Actions</label>
    <a class="btn btn-primary text-white" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
  </div>
</div>

<div class="p-2">
  <div *ngIf="loading; else contentList" class="div-centrado">
    <app-loader></app-loader>
  </div>

  <ng-template #contentList>

    <app-lista-backend [itemsPerPage]="10" [showFilterPanel]="showFilterPanel" [oltFilter]="true" [viewEndpoint]="viewEndpoint" [viewButton]="viewbtn" [title]="title" [data]="info" [endPoint]="endPoint" [queryParams]="queryParams" (sendQueryParams)="recieveQueryParams($event)"> </app-lista-backend>

  </ng-template>
</div>
