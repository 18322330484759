import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserRolesGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const isLogged = this.userService.getLoginToken();

    if(!isLogged){

      this.router.navigateByUrl('/signin');
      return false

    } else {

      let roles = route.data['roles'];

      if(!roles.length){

        return true;

      } else {

        
        const user = this.userService.getUserData();

        let hasPermission = false;

        console.log(roles);
        console.log(user.role);

      
        // roles.forEach((routeRole: any) => {

        //   if(!hasPermission){

        //     hasPermission = user.roles.find((role: any) => role == routeRole);

        //   }
          
        // });


        hasPermission = roles.find((role: any) => role == user.role);

        
        if(hasPermission){
  
          return true;
  
        } else {
  
          this.router.navigateByUrl('/dashboard');
          return false
  
        }

      }

    }


  }


}
