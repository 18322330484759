import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CoreSidebarService } from 'src/@core/components/core-sidebar/core-sidebar.service';//@core/components/core-sidebar/core-sidebar.service
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';
import { dbFullService } from 'src/app/services/dbFull.service';
import { HelperService } from 'src/app/services/helper.service';
import { LogsService } from 'src/app/services/logs.service';
import { Router } from '@angular/router';

let Estructura = {
      TitleHead: "Franquicia",
      Table: "cb_Franquicia",
      Inputs: [],
      Value:[]//"idUserEmp_3jKgVz"
    }

interface IEstructura {
  TitleHead: string
  Table: string
  Inputs: TipoInput[],
  Value: any[],
  Order?:TipoInput[],
  Type?: String
}

@Component({
  selector: 'app-new-user-sidebar',
  templateUrl: './new-user-sidebar.component.html',
  styleUrls: ['./new-user-sidebar.component.scss']
})
export class NewUserSidebarComponent {
  @Input() element:any;
  @Input() param!: IEstructura;
  @Input() excelColumnsNames: string[] = [];
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();

  public fullname:any;
  public username:any;
  public email:any;
  private regex = /_/
  public DataCampos:any ="";
  public DataKeys:any[] =[];
  // public CampoValor = new Map();
  public ConseguiData = true;
  public userID: string = "";
  public formData: IFormData = {
    vars: { },
    error: { }
  }

  sendMessage( event:any, data: any = '' ) {
    if( data !== '' && data.hasOwnProperty('Status') ) data.Status = 1;
    let Estructura ={
      evento:event,
      data: [data]
    }
    this.SalidaClickMenu.emit(Estructura)
  }

  constructor( 
    private _dbFullService : dbFullService, 
    private gsnackbar: GenericSnackbarService,
    private helper: HelperService,
    private logs: LogsService,
    private router: Router
    ) { if(!this.param)this.param=Estructura }

  ngOnInit() {
    this._dbFullService.GetAllDataCampo("cb_Estructura","TableName",this.param.Table)
    .then((resp)=>{
      console.log(resp)
      this.param.Inputs = (resp as any);
      //Si tiene valores es un edit
      this.param.Type = this.param.Value && this.param.Value.length> 0 ? "Update" : "Create";
      this.VerificadorTipeRequest()
    })
    .catch((error:any)=>{
      console.log(error);
    })

  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */

  VerificadorTipeRequest(){
    if(this.param.Type=="Create"){
      this.param.Order = this.param.Inputs.filter(i => i.ShowInsert).sort((a, b) => (a.OrdenInsert - b.OrdenInsert));
    }else{
      //Busco por el campo llave
      let Position = this.param.Value[0].search(this.regex)
      let Campo = this.param.Value[0].slice(0,Position);
      let Valor = this.param.Value[0].slice(Position+1);

      this._dbFullService.GetAllDataCampo(this.param.Table,Campo,Valor)
      .then((resp:any)=>{
        if(resp && resp.length>0){
          this.userID = resp[0]?.idUserEmp || '';
          //Busco los keys de lo que me trajo la BD
          Object.entries(resp[0]).forEach(([keyOriginal, valueKey]) =>this.formData.vars[keyOriginal] = valueKey);
          //Los Ordeno excepto los que no voy a mostrar
          this.param.Order = this.param.Inputs.filter(i => i.ShowInsert).sort((a, b) => (a.OrdenInsert - b.OrdenInsert));
          //Agarro lo que no se muestran y los meto de ultimo en el arreglo
          this.param.Order.push(...this.param.Inputs.filter(i => !i.ShowInsert));
          //this.param.Order.push({"idEstructura":"24","TableName":"cb_EmpresaUser","PK":1,"Campo":"idUserEmp","TipoDato":"DependencySelection","HeadListName":"ID","PlaceHolder":"ID","ShowList":0,"ShowInsert":0,"MaxLen":6,"Requerido":0,"ExpresionReg":null,"OrdenList":0,"OrdenInsert":0,"RelacionOtrasTablas":null} as any)
        }else{
          this.ConseguiData = false;
        }
      })
      .catch((error:any)=>{
        this.ConseguiData = false;
        console.log(error);
      })
    }
  }

  /**
   * Submit
   *
   * @param form
   */
  submit(event:any) {
    let message = ''
    const targetForm = event.target;
    // verify data
    const hasError = this.param.Order?.some(input => {

      let value = targetForm[input.Campo]?.value || Boolean(targetForm[input.Campo]?.checked || this.formData.vars[input.Campo]);

      const invalidRegExp = Boolean(input.ExpresionReg && RegExp(input.ExpresionReg).test(String(value ?? '')) === false);
      const invalidMaxLength = Boolean(input.MaxLen !== null && value?.length > input.MaxLen);
      const invalidRequired = Boolean(input.Requerido && !value);

      //console.log(RegExp(input.ExpresionReg || ""))

      // fix data
      if(invalidMaxLength) {
        // console.log("invalidMaxLength")
        value = value.slice(0, input.MaxLen);
      }

      if(invalidRegExp) {
        // console.log("Error de patron")
        this.formData.error[input.Campo] = `El valor no cumple con la estructura deseada`;
      }
      else if(invalidRequired) {
        // console.log("invalidRequired")
        // console.log(input.Campo)
        this.formData.error[input.Campo] = `Campo requerido*`
      }
      else {
        // console.log("delete");
        delete this.formData.error[input.Campo];

        return false;
      }
      return true;
    })

    if(!hasError) {
      this.param.Order?.forEach((input,index:number) => {
        if(!(input.Campo in this.formData.vars)) {
          let defaultValue;

          // Se usa un valor por defecto en caso de que no tenga asignado ningun valor
          // NOTA: Es muy probable que nunca pase esto pero no está demás asegurar .___.
          switch(input.TipoDato) {
            case "Switch":
              defaultValue = false;
              break;

            case "Avatar":
              defaultValue = "https://res.cloudinary.com/thomasv9/image/upload/v1681065837/ClubFibex/users_sjlanv.png";
              break;
          }

          this.formData.vars[input.Campo] = targetForm[input.Campo]?.value || defaultValue;
          let valueSetting = targetForm[input.Campo]?.value;

          if(valueSetting !== undefined && valueSetting !== null && valueSetting !== '') this.formData.vars[input.Campo] = valueSetting;
        }

        if(index+1 == this.param.Order?.length){
          if(this.param.Type=="Create"){
            message = 'Se ha creado un nuevo registro'
            this.InsertData(this.formData.vars,targetForm)
            .then((resp:any)=>this.sendMessage("create",resp))
            .catch((error:any)=>this.sendMessage("error",error))
          }else{
            message = `Se ha actualizado un registro`
            this.UpdateData(this.formData.vars,targetForm)
            .then((resp:any)=>this.sendMessage("update",resp))
            .catch((error:any)=>this.sendMessage("error",error))
          }
        }
      })
      this.createLogs(message)
      this.gsnackbar.runSnackBarNoCallback("Su registro ha sido completado de manera exitosa.", 1, true, 5000)
      // console.log("VALUE: ", this.formData.vars);
    }
  }

  EventoDinamic(event:any,CampoAgg:string){
    this.formData.vars[CampoAgg] = event;
    // this.CampoValor.set(CampoAgg, event);
    // console.log("Event");
    // console.log(event);
    // console.log("CampoAgg");
    // console.log(CampoAgg);
    // console.log("Form");
    // console.log(Form);
  }

  InsertData(value:any,form:any){
    return new Promise((resolve,reject)=>{
      let idKey:any= this.param.Inputs.filter((Campos:any)=>Campos.PK ==1) //Con esto se busca la llave primaria para que el backend la genere automáticamente
      if(idKey && idKey !=null){
        this._dbFullService.CreateData(this.param.Table,idKey[0]['Campo'],value)
        .then((resp:any)=>{

          resolve(resp);
          form.reset();
        })
        .catch((error:any)=>{
          reject(error);
          // console.log("error");
          console.log(error);
        })
      }else{
        // console.log("Falta llave primaria..");
      }
    })
  }

  UpdateData(value:any,form:any){
    return new Promise((resolve,reject)=>{
      let idKey:any= this.param.Inputs.filter((Campos:any)=>Campos.PK ==1) //Con esto se busca la llave primaria para que el backend la genere automáticamente
      if(idKey && idKey !=null){
        this._dbFullService.UpdateData(this.param.Table,idKey[0]['Campo'],value)
        .then((resp:any)=>{
          this.formData.vars['rowsUpdate']=resp.rowsUpdate
          resolve(this.formData.vars);
          form.reset();
        })
        .catch((error:any)=>{
          reject(error);
          // console.log("error");
          console.log(error);
        })
      }else{
        // console.log("Falta llave primaria..");
      }
    })
  }

  async createLogs(detail: string){
    const auth: any = sessionStorage.getItem('login')
    const { idUser, idUserEmp } = JSON.parse(auth)

    if(idUser){
      const action = await this.helper.retrieveAction(this.router.url)
  
      this.logs.createLog(idUser, action, detail).then(resp => {
  
      }).catch(error => console.log(error))

    }else if(idUserEmp){
      const action = await this.helper.retrieveAction(this.router.url)
  
      this.logs.createLog(idUserEmp, action, detail).then(resp => {
  
      }).catch(error => console.log(error))
    }
  }
}
