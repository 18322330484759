import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { forEach } from 'lodash';
import { dbFullService } from 'src/app/services/dbFull.service';
import $ from 'jquery';
import 'src/assets/lib/datatables/jquery.dataTables.js'; // using the one from npm presents issues
import { ObservableComponentsService } from 'src/app/services/observable-components.service';
import { Router } from '@angular/router';


//! testing dahr 
interface ICoreEstructure {
  TitleHead: any,
  Table: any,
  Estructura: any[],
  Inputs: any[],
  Value:any[]//"idUserEmp_3jKgVz"
}

const steps = [
  'cb_EmpresaConvenio', 
  'cb_EmpresaSucursal',
  'cb_SucursalDir'
];

//! finish dahr

@Component({
  selector: 'app-list-table-crud',
  templateUrl: './list-table-crud.component.html',
  styleUrls: ['./list-table-crud.component.scss']
})
export class ListTableCrudComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() TableName: any = "cb_EmpresaConvenio";
    @Input() HeadShow: any;
    @Input() options:any;
    @Input() style:any;
    public element:any;
    public Atributos:any = { 'title': 'Usuarios - Empresas', 'Description': 'Listado control de los usuarios' }
    public table:any

    public param: any;
    public tableId: string = '';
    public tableClasses: string[] = [];
    public ArrayDataIn: any[]=[]
    public selectedProduct:any="";
    public thead: any[]=[]
    public FieldAndHead: any
    public showMenu: boolean = false;
    public loading: boolean = true;
    public openCreateElement: boolean = false;
    public openCreateELementInit: boolean = true;
    public openModalCreate: boolean = false;
    public openModalCreateInit: boolean = true;
    public WithCalculeSize: number = 0// Tempotal;
    public showClientData: boolean = false;
    public idToCompare: string = "";
    public validFields: any = [];
    public ArrayDataAll: any[]=[] 

    public DataFormOpen = {
      TitleHead: this.Atributos?.title,
      Table: this.TableName,
      Estructura: [],
      Inputs: [],
      Value:[]//"idUserEmp_3jKgVz"
    }

    //!testing dahr
    public DataFormOpenArray: ICoreEstructure[] = [];
    
    coreEstructure = {
      TitleHead: this.Atributos?.title,
      Table: this.TableName,
      Estructura:[],
      Inputs: [],
      Value:[]//"idUserEmp_3jKgVz"
    }

    //!finish dahr

    public Cargando = true


    public rowData = {
      TitleHead: "Editar Empresa",
      Table: "cb_EmpresaConvenio",
      Inputs: [],
      Value: ['']//"idUserEmp_p1YdX_"
    }

    ngOnChanges(changes: SimpleChanges) {
      console.log('changes')
      // changes.prop contains the old and the new value...
    }

    toCloseMenu = () => {
      document.body.removeEventListener("click", this.toCloseMenu)
      this.showMenu = false;
    }

    toOpenMenu() {
      setTimeout(() => {
        document.body.addEventListener("click", this.toCloseMenu);
      }, 200)
      this.showMenu = true;
    }

    async Cargar() {
      console.log('cargar 1')
      const FullDBObj = new dbFullService();
      const DatosSend = { title: "Control " + this.TableName, head: this.HeadShow, data: [] };
      this.FieldAndHead = DatosSend;

      const DatosDic: any = await FullDBObj.GetAllT();

        // let ArgValues: any
        for(let element of DatosDic.model) {
          if (element.tableName == this.TableName) {
            const argHead = element.attr
            let ArgValues: any[]=[]
            // Voy ahora a buscar los datos
            const DatosTable: any = await FullDBObj.GetAllDataCampo(this.TableName, "Status", "1");
            argHead.pop();  // Elimino los dos ultimos registros que son los de las fechas
            argHead.pop();  // Esto se debe mejorar
            DatosTable.forEach((elementTable: any, index: number) => {
              let ValurReg:any[]=[]
              argHead.forEach((ValueAttr:any) => {
                ValurReg.push(elementTable[ValueAttr])
              });
              ArgValues.push(ValurReg)

            });
            this.FieldAndHead.data = ArgValues
          }
        }

        this.loading = false; // resuelto!!!
    }

    Cargar2() {
      return new Promise(async(resolve,reject)=>{ 
        const FullDBObj = new dbFullService();
        const DatosSend = { title: "Control " + this.TableName,  data: [] };
        this.FieldAndHead = DatosSend;
        let ArgValues: any[] = [];

        FullDBObj.GetAllDataCampo("cb_Estructura", "TableName", this.TableName).then(async (Estructura: any) => { 

          this.DataFormOpen.Estructura = Estructura
          this.Atributos.Estructura = Estructura



          const ArgHead: any[] = []
          Estructura.forEach(async (element:any , index:number) => {
            if(element.ShowList) {
              this.validFields.push(element.Campo)
              ArgHead.push(element.HeadListName)                  
            }
          });

          this.FieldAndHead.head = ArgHead;
          this.WithCalculeSize =  100 / this.FieldAndHead.length

          this.ArrayDataAll =  await FullDBObj.GetAllDataCampo(this.TableName, "Status", "1");
          this.ArrayDataAll.forEach((elementTable: any, index: number) => {
            let ValurReg:any[]=[]
          
            ArgValues.push(ValurReg)                    
            if (this.ArrayDataAll.length - 1 == index ) resolve(ArgValues);
          });            
        })
      })
    }

    async createFormSteps(){
      const FullDBObj = new dbFullService();

      for (const table of steps) {
        const estructure = await FullDBObj.GetAllDataCampo("cb_Estructura", "TableName", table)

        const core = {
          TitleHead: this.Atributos?.title,
          Table: table,
          Estructura: estructure,
          Inputs: [],
          Value:[]//"idUserEmp_3jKgVz"
        }

        this.DataFormOpenArray.push(core)
      }
    }

    constructor( 
      private cd: ChangeDetectorRef, 
      private obsServer: ObservableComponentsService,
      private router: Router ) {
        this.initSuscriptions();
    }
      
    ngOnInit(): void {

    }

    ngOnDestroy(): void {
      this.obsServer.paramData$.emit(false);
    }

    toopenModalCreate() {
      this.openModalCreate = true;
      this.openModalCreateInit = false;
    }

    toOpenCreateElement(element:any) {
      this.element = element;
      const { Campo } = this.getPK();

      this.rowData.Value[0] = `${Campo}_${this.element[Campo]}`;
      this.idToCompare = this.element[Campo];

      this.openCreateElement = true;
      this.openCreateELementInit = false;
      this.showClientData = true;
    }

    toCloseCreateElement( element?: any ) {
      if( element.data[0] instanceof Object ) {
        this.openCreateElement = false;
        this.openCreateELementInit = false;
        this.reloadCurrentRoute();
      } else {
        this.openModalCreate = false;
        this.openModalCreateInit = false;
      }

      this.showClientData = false;
      setTimeout(() => {
        this.openModalCreateInit = true;
      }, 1000);
    }


    toogleDetails(product:any){
      if ( this.selectedProduct && this.selectedProduct[3] === product[3] )
        {
            // Close the details
            this.closeDetails();
            return;
        }
        else{
          this.selectedProduct=product
        }
    }
    closeDetails(){
      this.selectedProduct=""
    }

  ngAfterViewInit(): void {
    this.setData();
    this.createFormSteps();
  }


  initSuscriptions() {
    this.obsServer.paramData$.subscribe(res => {
      if( res ) {
        console.log(res)
        this.toopenModalCreate();
      } return;
    });
  };

  setData() {
    this.Cargar2().then((Data: any) => {
      this.FieldAndHead.data = Data;
      this.loading = false; // resuelto!!!
      setTimeout(() => {

        //@ts-ignore
        //////////////////////////////////////////////// verificar necesidad del codigo comentado 184-->212
        $('#datatable-crud').DataTable({
          language: {
            searchPlaceholder: 'Buscar...',
            sSearch: '',
            lengthMenu: 'MOSTRAR _MENU_ REGISTROS',
          }
        });
      },500)
    })
    .catch((error) => {
      console.error(error);
      // console.log("No se logró cargar los datos")
    })
  }


  /*
    @Return this.getPK() helper to get the PK whith the value of 1
  */ 
  getPK() {
    const pk = this.DataFormOpen.Estructura.filter((element: any) => element.PK === 1);
    return pk[0];
  }

  updateField( field: any ) {
    this.FieldAndHead?.data.filter(( res: any ) => console.log(res))
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}