import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private router: Router,
    ) { }

  getLoginToken(){

    let data = sessionStorage.getItem('login');

    return data;

  }

  getUserData(){

    let data = JSON.parse(sessionStorage.getItem('loginInfo')!);

    return data;
   
  }

  setUserData(data: any){

    sessionStorage.setItem('loginInfo', JSON.stringify(data));

    return true;

  }

  setLoginToken(token: string){

    sessionStorage.setItem('login', token);

    return true;

  }

  logOut(){

    sessionStorage.removeItem('login');
    sessionStorage.removeItem('loginInfo');
    this.router.navigate(['/signin']);
    document.title = 'Fibex OLT';



  }
}
