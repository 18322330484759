import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  filteredDataLength: EventEmitter<any> = new EventEmitter<any>();


  constructor(private toastr: ToastrService) { }

  sendDataLength(length: any){
    this.filteredDataLength.emit(length);
  }

  showSuccess(mensaje: string, titulo?: string) {
    this.toastr.success(mensaje, titulo, {
      closeButton: true,
      positionClass: "toast-bottom-center"
    });
  }

  showError(mensaje: string, titulo?: string) {
    this.toastr.error(mensaje, titulo, {
      closeButton: true,
      positionClass: "toast-bottom-center"
    });
  }

  showWarning(mensaje: string, titulo?: string) {
    this.toastr.warning(mensaje, titulo, {
      closeButton: true,
      positionClass: "toast-bottom-center"
    });
  }

  showInfo(mensaje: string, titulo?: string) {
    this.toastr.info(mensaje, titulo, {
      closeButton: true,
      positionClass: "toast-bottom-center"
    });
  }
}
