import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { filter } from 'lodash';

@Pipe({
  name: 'listFilters'
})
export class ListFiltersPipe implements PipeTransform {

  constructor(private utilsService: UtilsService){}

  transform(data: any, typing: string, headers: any, currentPage: number = 0, itemsPerPage: number, searchTexts: any, status: any = 0, selectedOlt: any = null): any {

    let startIndex = (currentPage - 1) * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;
    let filteredData: any = [];
    let secondFilterData: any = [];

    let allValuesAreEmpty = !searchTexts.some((item: any)=> item != '' && item != null);

    if(allValuesAreEmpty){

      if(status == 0){

        console.log('no hay filtros');
  
        filteredData = data;
  
      } else {

        console.log('hay filtro status pero no textos');
  
        data.forEach((item: any) => {
  
          headers.forEach((key: any) => {
    
            if(key.toLowerCase().includes('status') && item[key] == status){
              filteredData.push(item);
            } 
          
          });
      
        });

      }

    } else {

      let someEmptyValues = false;

      searchTexts.forEach((value: string) => {

        if(value == '' || value == null){

          someEmptyValues = true;

        }
        
      });

      if(someEmptyValues){

        if(status == 0){

          console.log('hay filtro texto pero no status');
    
          data.forEach((item: any) => {
    
            headers.forEach((key: any) => {
              
              if(typeof item[key] == 'string'){
    
                for(let i = 0; i < searchTexts.length; i++){
    
                  if((searchTexts[i] != '' && searchTexts[i] != null) && item[key].toLowerCase().includes(searchTexts[i].toLowerCase().trim())){

                    filteredData.push(item);
                  }
                }
              } 
            });  
          });
    
        } else {
    
          console.log('hay filtros');
    
    
          data.forEach((item: any) => {
    
            headers.forEach((key: any) => {
      
              if(key.toLowerCase().includes('status') && item[key] == status){
                secondFilterData.push(item);
              } 
            
            });
        
          });
    
          secondFilterData.forEach((item: any) => {
    
            headers.forEach((key: any) => {
              
              if(typeof item[key] == 'string'){
    
                for(let i = 0; i < searchTexts.length; i++){
    
                  if((searchTexts[i] != '' && searchTexts[i] != null) && item[key].toLowerCase().includes(searchTexts[i].toLowerCase().trim())){

                    filteredData.push(item);
                  }
                }
              } 
            });  
        
          });
    
        } 

      } else {
          
        if(status == 0){

          console.log('hay filtro texto pero no status');
    
          data.forEach((item: any) => {
    
            headers.forEach((key: any) => {
              
              if(typeof item[key] == 'string'){
    
                for(let i = 0; i < searchTexts.length; i++){
    
                  if(item[key].toLowerCase().includes(searchTexts[i].toLowerCase().trim())){
    
                    if(searchTexts[i-1] != undefined && Object.values(item).some((item: any)=> this.checkObjectValue(item, searchTexts[i-1]))){
                      filteredData.push(item);
                    }
                  }                 
                }       
              }             
            });        
          });
    
        } else {
    
          console.log('hay filtros');
    
    
          data.forEach((item: any) => {
    
            headers.forEach((key: any) => {
      
              if(key.toLowerCase().includes('status') && item[key] == status){
                secondFilterData.push(item);
              } 
            
            });
        
          });
    
          secondFilterData.forEach((item: any) => {
    
      
            headers.forEach((key: any) => {
              
              if(typeof item[key] == 'string'){
    
                for(let i = 0; i < searchTexts.length; i++){
    
                  if(item[key].toLowerCase().includes(searchTexts[i].toLowerCase().trim())){
    
                    if(searchTexts[i-1] != undefined && Object.values(item).some((item: any)=> this.checkObjectValue(item, searchTexts[i-1]))){
                      filteredData.push(item);
                    }
                  }                 
                }       
              }             
            });
        
          });
    
        } 
        
      }
 
    }

  
    // this.utilsService.sendDataLength(filteredData.length);

    // eliminar datos repetidos
    let array = filteredData.slice(startIndex, endIndex);

    console.log(array);
  
    return array.filter((data: any, index: any) => array.indexOf(data) == index);

  }

  checkObjectValue(item: any, value: string){

    if(typeof item == 'string'){

      if(item.toLowerCase().includes(value.toLowerCase())){
        return true;
      }

    }

    return false;
      
  }

}


