import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isArray } from 'jquery';
import { AuthService } from 'src/app/core/services/auth.service';
import { dbFullService } from 'src/app/services/dbFull.service';
import { ilogin } from 'src/app/core/interfaces/Ilogin.interface';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HelperService } from 'src/app/services/helper.service';
import { LogsService } from 'src/app/services/logs.service';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { UserService } from 'src/app/services/user.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
form!:FormGroup;

  private _auth!: ilogin;

  public msgErr: string = "";

  public error: boolean = false;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dbFullService: dbFullService,
    private router: Router,
    private confServ: ConfigurationService,
    private helper: HelperService,
    private logs: LogsService,
    private smartOltService: SmartOltService,
    private userService: UserService,
    private rolesService: RolesService

  ) { }
  get auth(){
    return {...this._auth}
  }

  ngOnInit(): void {
      this.initForm()
  }

  initForm() {
    this.form = this.fb.group({
      login: ['',Validators.required],
      password: ['', Validators.required]
    })
  }


  login( form: FormGroup ) {

    this.error = false;
    this.loading = true;

    try {
      const { login, password } = form.value;

      console.log(login, password);

      this.smartOltService.login(login, password).then(async (res: any) => {

        console.log(res);

        if(res.access_token){

          this.loading = false;
          this.error = false;

          let token = res.access_token;

          this.userService.setLoginToken(token);
          this.userService.setUserData(res.data);

          await this.rolesService.setUserPermissions();

          this.router.navigate(['/dashboard/home']);


        } else {

          this.error = true;
          this.loading = false;


        }

      });

    } catch (error) {
      console.error(error);
    }

   
  }


  async createLog(loginParams:any){
    const { idUser, idUserEmp } = loginParams

    if (idUser) {
      const action = await this.helper.retrieveAction('Login')

      this.logs.createLog(idUser, action, 'Inicio de sesión').then(resp => {

      }).catch(error => console.log(error))

    }else if(idUserEmp){
      const action = await this.helper.retrieveAction('Login')

      this.logs.createLog(idUserEmp, action, 'Inicio de sesión').then(resp => {

      }).catch(error => console.log(error))
    }
  }


}
