import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  form!: FormGroup;
  editForm!: FormGroup;
  id: any;
  item: any;
  formSections: any = [];

  rolesList = [];

  idleState: string = 'Not Started';

  private onIdleSubscriptions: Subscription = new Subscription;


  constructor(private smartOltService: SmartOltService,    private userService: UserService, private idle: Idle ){
    idle.setIdle(60);
    idle.setTimeout(1200); // 20 minutos de inactividad
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.onIdleSubscriptions.add(
      this.idle.onIdleStart.subscribe(()=>{
        this.idleState = 'Idle'
        console.log('Innactive');
      })
    ); 

    this.onIdleSubscriptions.add(
      this.idle.onTimeout.subscribe(()=>{
        this.idleState = "Timed Out";
        console.log('Logged out');
  
        this.userService.logOut();
  
      })
    ); 

    this.onIdleSubscriptions.add(
     
      this.idle.onIdleEnd.subscribe(()=>{
        this.idleState = 'Started';

        console.log('Active again');
      })
    ); 
  }

  async ngOnInit() {

    this.getData();

    this.setState();

  }

  
  ngOnDestroy(): void {

    if(this.onIdleSubscriptions){

      this.idle.stop();
      this.onIdleSubscriptions.unsubscribe();

    }
    
  }

  setState(){
    this.idle.watch();
    this.idleState = 'Started';

  }


  async initForm() {

    let res = await this.smartOltService.getData('permission/listing');

    let permissions: any = [];

    res.forEach((data: any) => {

      let obj = {name: null, id: null, selected: false};

      obj.name = data.name;
      obj.id = data.id;

      permissions.push(obj);
      
    });

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      permissions: new FormArray([], [Validators.required])

    });

    this.editForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      permissions: new FormArray([], [Validators.required])

    });

    this.formSections = [
      {
        title: 'Name',
        controlName: 'name',
        placeholder: 'name',
        type: 'text',
      },

      {
        title: 'Permissions',
        controlName: 'permissions',
        type: 'checkbox',
        options: permissions
      }
    
    ];

  }

  async getData(){
    
    this.loading = true;

    let res = await this.smartOltService.getData('role/listing');
    
    res.forEach((role: any) => {

      let permissions = role.permissions;
      role.permissions = [];

      permissions.forEach((permission: any) => {

        role.permissions.push(permission.name);
        
      });
      
    });

    if (res.length > 0) {
      this.rolesList = res;
    } else {
      this.rolesList = [];
    }

    this.rolesList.sort(function(a: any, b: any){

      // orden decreciente desde el mas reciente al mas viejo
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();

    });

    await this.initForm();

    this.loading = false;

    console.log(this.rolesList);

  }

  async createRole(){

    this.loading = true;

    // try {
    //   const response = await this.smartOltService.create(
    //     'role',
    //     this.form.value

    //   );

    //   console.log(response)

  
      
    // } catch (error) {

    //   console.log(error)
      
    // }

    this.loading = false;
    this.getData();

  }

  receiveID(itemId: number) {
    this.id = itemId;

    this.item = this.rolesList.find((user: any) => user.id == itemId);
  }


  receiveEdit(editForm: any) {
    this.editForm = editForm;
  }

}
