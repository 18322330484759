import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HelperService } from 'src/app/services/helper.service';
import { LogsService } from 'src/app/services/logs.service';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  form!:FormGroup;
  error: boolean = false;
  loading: boolean = false;



  constructor(
    private formGroup: FormBuilder,
    private router: Router,
    private confServ: ConfigurationService,
    private helper: HelperService,
    private userService: UserService,
    private smartOltService: SmartOltService
  ) { }


  ngOnInit(): void {
    this.initForm()
}

  initForm() {
    this.form = this.formGroup.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      firstName: ['', Validators.required],
      lastName: ['']
    }, {
      validator: this.passwordConfirmation
    });
  }

  get email(){
    return this.form.get('email');
  }
  get password(){
    return this.form.get('password');
  }
  get confirmPassword(){
    return this.form.get('confirmPassword');
  }
  get firstName(){
    return this.form.get('firstName');
  }
  get lastName(){
    return this.form.get('lastName');
  }

  passwordConfirmation(control: any){
    let password = control.get('password');
    let confirmPassword = control.get('confirmPassword');

    if(password.value != confirmPassword.value){
      return { doNotMatch: true};
    } else {
      return null;
    }

  }

  async signUp() {

    this.error = false;
    this.loading = true;


    // NUEVO LOGIN
    try {

      const password = this.form.value.password;

      const body = {
        email: this.form.value.email,
        name: `${this.form.value.firstName} ${this.form.value.lastName}`,
        password: this.form.value.password
      }

      let res = await this.smartOltService.create(
        'user',
        body
      );
     

      console.log(res);

      if (res) {

        this.smartOltService.login(res.email, password).then(async (res: any) => {

          console.log(res);
  
          if(res.access_token){
  
            this.loading = false;
            this.error = false;
  
            let token = res.access_token;
  
            this.userService.setLoginToken(token);
            this.userService.setUserData(res.data);
          
  
            this.router.navigate(['/dashboard/home']);
  
  
          } else {
  
            this.error = true;
            this.loading = false;
  
  
          }
  
        });
        
      }

    } catch (error) {
      console.error(error);
    }
    
   
  }


}
