import {Component} from '@angular/core';
import { RolesService } from './services/roles.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{

  constructor() {}


}
