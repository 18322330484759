<div>
    <div class="col-md-12 mb-3">
    <button class="btn btn-primary" [routerLink]=" path == 'list' ? '/dashboard/settings/olts' : '/dashboard/home' "><i class="fa fa-mail-reply"></i> {{path == 'list' ? 'Back to list' : 'Back to dashboard'}}</button>
</div>


    <div *ngIf="loading; else contentList" class="div-centrado">
        <app-loader></app-loader>
    </div>

    <ng-template #contentList>
        <div class="col-md-12">
            <div class="card">
                <ul class="nav nav-tabs bg-light" id="oltTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#oltDetails" role="tab"
                            aria-controls="oltDetails" aria-selected="true">OLT Details</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#oltCards" role="tab"
                            aria-controls="oltCards" aria-selected="false"  >OLT cards</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#ponPorts" role="tab"
                            aria-controls="ponPorts" aria-selected="false"   >PON ports</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#uplink" role="tab"
                          aria-controls="uplink" aria-selected="false" >Uplink</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#vlans" role="tab"
                            aria-controls="vlans" aria-selected="false"  >Vlans</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#onuMIP" role="tab"
                            aria-controls="onuMIP" aria-selected="false">ONU Mgmt IPs</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#remoteAcls" role="tab"
                            aria-controls="remoteAcls" aria-selected="false">Remote ACLs</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#voipProfiles" role="tab"
                            aria-controls="voipProfiles" aria-selected="false">VoIP profiles</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#advanced" role="tab"
                            aria-controls="advanced" aria-selected="false">Advanced</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="oltDetails" role="tabpanel" aria-labelledby="home-tab">
                        <div class="row p-2">
                            <div class="col-md-6 mt-2">
                                <button class="btn btn-primary mx-1" data-toggle="modal" data-target="#modalHistory" >See History</button>
                                <button class="btn btn-success mx-1" >>_Cli</button>
                                <button class="btn btn-primary mx-1" >Config Backups</button>
                                <button class="btn btn-success" [disabled]="loadingOnus" (click)="importOnus()">Import Onus <i *ngIf="loadingOnus" class="fa fa-spinner fa-spin margin-left"></i></button>
                                <table class="table p-1 mt-2">
                                    <thead>
                                        <tr>
                                            <th>OLT settings</th>
                                            <th>Values</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{{ data.name || 'none' }}</td>
                                        </tr>
                                        <tr>
                                            <td>OLT IP</td>
                                            <td>{{ data.ip || 'none' }}</td>
                                        </tr>
                                        <tr>
                                            <td>Reachable via VPN Tunnel</td>
                                            <td>no/yes</td>
                                        </tr>
                                        <tr>
                                            <td>Telnet TCP port</td>
                                            <td>{{ data.telnet_port || 'none' }}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>OLT telnet username</td>
                                            <td><a href="javascript:;">show</a></td>
                                        </tr>
                                        <tr>
                                            <td>OLT telnet password</td>
                                            <td><a href="javascript:;">show</a></td>
                                        </tr>
                                        <tr>
                                            <td>SNMP read-only community</td>
                                            <td><a href="javascript:;">show</a></td>
                                        </tr>
                                        <tr>
                                            <td>SNMP read-write community</td>
                                            <td><a href="javascript:;">show</a></td>
                                        </tr> -->
                                        <tr>
                                            <td>SNMP UDP port</td>
                                            <td>{{ data.snmp_udp_port || 'none' }}</td>
                                        </tr>
                                        <tr>
                                            <td>IPTV module</td>
                                            <td>{{ data.ipvt_module || 'none' }}</td>
                                        </tr>
                                        <tr>
                                            <td>OLT hardware version</td>
                                            <td>{{ data.hardware_version || 'none' }}</td>
                                        </tr>
                                        <tr>
                                            <td>OLT Software version</td>
                                            <td>{{ data.oltSwVersion || 'none' }}</td>
                                        </tr>
                                        <tr>
                                            <td>Supported PON types</td>
                                            <td>{{ data.support_pon_type || 'none' }}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>TR069 Profile</td>
                                            <td>Select dinamico (pendiente)</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6 text-center">
                                <img src="/assets/img/olt.jpeg" alt="OLT">
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="oltCards" role="tabpanel" aria-labelledby="profile-tab">

                        <div class="row p-2">
                            <div class="col-md-12 mt-2">
                                <button class="btn btn-primary mx-1" (click)="refresh('get/snmp/oltcard/')" [disabled]="refreshing">Refresh OLT cards info <i *ngIf="refreshing" class="fa fa-spinner fa-spin margin-left"></i></button>
                                <button class="btn btn-primary mx-1">Detect new cards</button>

                                <div *ngIf="loading2" class="div-centrado w-100 d-flex justify-content-center">
                                    <app-loader></app-loader>
                                </div>

                                <br><br>
                                
                                <ng-container *ngIf="!loading2" class="mt-2">
                                    <app-lista-frontend [placeholder]="'Type, Ports, Software version'"  [itemsPerPage]="5" viewEndpoint="" tab="oltCards" title="OLT Cards" [data]="data.olt_cards" actionBtn="true" endPoint=""> </app-lista-frontend>
                                </ng-container>

                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="ponPorts" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="row p-2">
                            <div class="col-md-12 mt-2">
                                <button class="btn btn-primary mx-1" (click)="refresh('get/snmp/portData/')" [disabled]="refreshing">Refresh PON ports info <i *ngIf="refreshing" class="fa fa-spinner fa-spin margin-left"></i></button>
                                <button class="btn btn-primary mx-1">Enable all PON ports</button>
                                <button class="btn btn-primary mx-1">Enable Autofind</button>

                                <!-- <h5 class="mt-2 p-1">OLT slot 1, board type: H805GPFD, Offline</h5> -->

                                
                                <br><br>
                                
                                <ng-container *ngIf="!loading2" class="mt-2">
                                    <app-lista-frontend [placeholder]="'Type, Ports, Description'"  [itemsPerPage]="5" viewEndpoint="" tab="ponPorts" title="PON Ports" [data]="data.olt_ports" actionBtn="true" endPoint=""> </app-lista-frontend>
                                </ng-container>

                              
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="uplink" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="row p-2">
                            <div class="col-md-12 mt-2">
                                <button class="btn btn-primary mx-1" (click)="refresh('get/snmp/uplink/')" [disabled]="refreshing">Refresh uplink ports info <i *ngIf="refreshing" class="fa fa-spinner fa-spin margin-left"></i></button>

                                <div *ngIf="loading2" class="div-centrado w-100 d-flex justify-content-center">
                                    <app-loader></app-loader>
                                </div>

                                <br><br>
                                
                                <ng-container *ngIf="!loading2" class="mt-2">
                                    <app-lista-frontend [placeholder]="'Name, Type, Negotiation, Mtu, Wavel, Description'"  [itemsPerPage]="5" viewEndpoint="" tab="uplinks" title="Uplinks" [data]="data.olt_uplinks" actionBtn="true" endPoint=""> </app-lista-frontend>
                                </ng-container>

                              

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="vlans" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="row p-2">
                            <div class="col-md-12 mt-2">
                                <button class="btn btn-primary mx-1" (click)="refresh('get/snmp/vlan/')" [disabled]="refreshing">Refresh VLAN info <i *ngIf="refreshing" class="fa fa-spinner fa-spin margin-left"></i></button>
                                <button class="btn btn-primary mx-1" data-toggle="modal" data-target="#addVLanModal"><i
                                        class="fa fa-plus"></i> Add VLAN</button>
                                <button class="btn btn-primary mx-1"><i class="fa fa-plus"></i> Add Multiple
                                    VLANs</button>
                                <button class="btn btn-primary mx-1"><i class="fa fa-minus"></i> Delete Multiple
                                    VLANs</button>

                                <br><br>
                            
                                <ng-container *ngIf="!loading2" class="mt-2">
                                    <app-lista-frontend [placeholder]="'Description'" [itemsPerPage]="5" viewEndpoint="" tab="vlans" title="Vlans" [data]="data.olt_vlan" actionBtn="true" endPoint=""> </app-lista-frontend>
                                </ng-container>
    
                                
                             
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="onuMIP" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="row p-2">
                            <div class="col-md-12 mt-2">
                                <button class="btn btn-primary mx-1" data-toggle="modal" data-target="#addIps"><i
                                        class="fa fa-plus"></i> Add more management IPs</button>
                            </div>
                            <div class="col-md-12 mt-2">
                                <span>No ONU managament IPs</span>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="remoteAcls" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="row p-2">
                            <div class="col-md-12 mt-2">
                                <button class="btn btn-primary" data-toggle="modal" data-target="#editAccessList">Edit
                                    Access Lists</button>
                            </div>
                            <div class="col-md-12 mt-2">
                                <h4>No Access Lists Found</h4>
                                <span>The IPs in the Access Lists will have the permissions to connect remotely to the
                                    ONUs
                                    by activating the option "Allow remote access" on the ONU options.</span>
                                <hr>
                                <div class="d-flex justify-content-center align-items-center">
                                    <img src="/assets/img/acces-lists.jpeg" alt="IMG">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="voipProfiles" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="row p-2">
                            <div class="col-md-12 mt-2">
                                <button class="btn btn-primary mx-1">Add VoIP profile</button>
                            </div>
                            <div class="col-md-12 mt-2">
                                <h4>No VoIP profiles found</h4>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="advanced" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="row p-2">
                            <div class="col-md-12 mt-2">
                                <button class="btn btn-primary mx-1" (click)="toggle()">Edit Advanced</button>
                                <table class="table p-1 mt-2">
                                    <thead>
                                        <tr>
                                            <th>OLT settings</th>
                                            <th>Values</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Show admin-disabled ONUs in Unconfigured</td>
                                            <td *ngIf="!show">yes</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" checked>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use DHCP Option82</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>DHCP Option82 field</td>
                                            <td *ngIf="!show">none</td>
                                            <td *ngIf="show">
                                                <input type="text" class="form-control" value="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use PPPoE-plus</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ONU IP source-guard</td>
                                            <td *ngIf="!show">disabled</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use max-mac-learn</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Max MACs allowed on internet vport per ONU</td>
                                            <td *ngIf="!show">3</td>
                                            <td *ngIf="show">
                                                <input type="text" class="form-control" value="3">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use MAC allow list</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use MAC drop list</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use Ports filtering ACL</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Ports filtering ACL</td>
                                            <td *ngIf="!show">none</td>
                                            <td *ngIf="show">
                                                <input type="text" class="form-control" value="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ONUs signal level "warning" (dBm)</td>
                                            <td *ngIf="!show">-26</td>
                                            <td *ngIf="show">
                                                <input type="text" class="form-control" value="-26">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ONUs signal level "critical" (dBm)</td>
                                            <td *ngIf="!show">-30</td>
                                            <td *ngIf="show">
                                                <input type="text" class="form-control" value="-30">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Seperate IP-hosts for VoIP/Mgmt</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>OLT temperature level "warning" (°C)</td>
                                            <td *ngIf="!show">45</td>
                                            <td *ngIf="show">
                                                <input type="text" class="form-control" value="45">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>OLT temperature level "critical" (°C)</td>
                                            <td *ngIf="!show">55</td>
                                            <td *ngIf="show">
                                                <input type="text" class="form-control" value="55">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use CVLAN-ID option</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use SVLAN-ID option</td>
                                            <td *ngIf="!show">yes</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" checked>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Default tag-transform mode</td>
                                            <td *ngIf="!show">translate</td>
                                            <td *ngIf="show">
                                                <select class="form-control">
                                                    <option value="translate" selected>translate</option>
                                                    <option value="option2" selected>option2</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Use "other-all" TLS VLAN</td>
                                            <td *ngIf="!show">no</td>
                                            <td *ngIf="show">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                         
                                <p
                                style="text-align: center; font-weight: bold; font-size: 16px"
                                *ngIf="vlans.length < 1 && !loading2"
                                >
                                No results
                                </p>

                                <div *ngIf="show" class="float-right">
                                    <button class="btn btn-success mx-1">Save</button>
                                    <button class="btn btn-light" (click)="toggle()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>


<div class="modal fade" id="configurePonModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Configure PON port 0</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Admin State</label>
                    <div class="col-sm-10">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                value="option1" checked>
                            <label class="form-check-label" for="exampleRadios1">
                                Enabled
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                                value="option2">
                            <label class="form-check-label" for="exampleRadios2">
                                Disabled
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Description</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" placeholder="Description">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Min ranger</label>
                    <div class="col-sm-10">
                        <input type="number" class="form-control" placeholder="Example mts">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Max ranger</label>
                    <div class="col-sm-10">
                        <input type="number" class="form-control" placeholder="Example mts">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="configureuplink" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Configure uplink port ethernet0/7/0</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <span>Tagged VLANs</span>
                <hr>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Description</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control" placeholder="Description">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Add VLANs</label>
                    <div class="col-sm-9">
                        <input type="number" class="form-control" placeholder="Example mts">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Remove VLANs</label>
                    <div class="col-sm-9">
                        <input type="number" class="form-control" placeholder="Example mts">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addVLanModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Add VLAN</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <form>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label for="">VLAN ID</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label for="">Description</label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                    <label class="form-check-label" for="defaultCheck1">
                                        Multicast VLAN, used for IPTV
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                    <label class="form-check-label" for="defaultCheck1">
                                        Managament/VoIP VLAN
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                    <label class="form-check-label" for="defaultCheck1">
                                        DHCP Snooping on VLAN
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                    <label class="form-check-label" for="defaultCheck1">
                                        Enable direct communication between ONUs on this VLAN (aka, LAN-to-LAN)
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label for="">Default PON ports</label>
                                <select class="form-control">
                                    <option value="option1" selected>Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addIps" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Add more IPs</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-row">
                        <div class="col">
                            <label for="">start IPv4 range</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col">
                            <label for="">end IPv4 range</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <label for="">Subnet mask</label>
                            <select class="form-control">
                                <option value="option1" selected>/24 - 255.255.255.0</option>
                            </select>
                        </div>
                        <div class="col">
                            <label for="">Default gateway</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <label for="">DNS 1</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col">
                            <label for="">DNS 2</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editAccessList" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Edit Access List</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <label for="">Access List 1</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-md-12">
                            <label for="">Access List 2</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label for="">Access List 3</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-md-12">
                            <label for="">Access List 4</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success">Save</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalHistory" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">History</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>Action</th>
                            <th>User</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let log of logs">
                        <tr>
                            <td scope="row">{{ log.action }}</td>
                            <td>{{ log.user }}</td>
                            <td>{{ log.date }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success">Save</button>
            </div>
        </div>
    </div>
</div>