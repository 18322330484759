import { Component } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss'],
})

export class GraphsComponent {

  labels: any = [];

  tempArray: any = [];

  data: any = [];
  olts: any = [];
  dataChart: any = [];
  loading = false;
  filters = '';

  // Select para olts
  selectedOlt: any;
  itemsOlts: any = [];

  selectedFormat: any;

  show: boolean = false;
  title: string = '';
  getCharts!: number;
  cantCharts = [1, 1, 1, 1, 1];
  temperature!: number;

  constructor(private smartSvc: SmartOltService) {}

  ngOnInit() {
    this.getData();
    this.getTemperature();
  }

  async getData() {
    this.loading = true;

    let res = await this.smartSvc.getData('olts/listing');
    this.olts = res.data;
    this.itemsOlts = res.data;


    // res = await this.smartSvc.getData('get_olts_uptime_and_env_temperature');

    // console.log(res);

    // this.data = res;


    res = [

      {
        created_at: "2024-01-22 20:23:06",
        env_temp: 31,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },
      {
        created_at: "2024-01-23 20:23:06",
        env_temp: 29,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },
      {
        created_at: "2024-01-20 20:23:06",
        env_temp: 28,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },
      {
        created_at: "2024-01-19 20:23:06",
        env_temp: 32,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },
      {
        created_at: "2024-01-24 20:23:06",
        env_temp: 26,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },
      {
        created_at: "2024-01-21 12:23:06",
        env_temp: 30,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },

      {
        created_at: "2022-01-18 12:23:06",
        env_temp: 30,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },

      {
        created_at: "2024-01-09 12:23:06",
        env_temp: 30,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },

      {
        created_at: "2024-01-08 12:23:06",
        env_temp: 30,
        olt_id: 18,
        olt_name:"OLT UNICENTER",
        uptime:"30 días, 02:03:36"
      },
      

    ];

    this.olts = this.olts.map((olt: any) => {

      olt.temperatureArray = res.filter((item: any) => item.olt_id == olt.id).sort(function(a: any, b: any){

        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  
      });

      olt.temperatureArray = this.sortByDate(olt.temperatureArray);

      return olt;

    });

    this.itemsOlts = this.olts;

    this.loading = false;

    console.log(this.itemsOlts);

  }

  getGraphData(array: any){

    let labels: string[] = [];
    let temps: string[] = [];

    let data = {
      labels: labels,
      temps: temps
    }

    array.forEach((item: any) => {

      let date = new Date(item.created_at)

      let label = date.toDateString() == new Date().toDateString() ? this.dayOfWeek(date.getDay()) + " (today)" : this.dayOfWeek(date.getDay());

      data.labels.push(label);
      data.temps.push(item.env_temp);

      
    });
    

    return data;
    
  }

  sortByDate(arr: any) {

    let filter: any = null;

    switch (this.selectedFormat) {
      case 1:

        filter = new Date(new Date().setDate(new Date().getDate() - 7));
        
        break;

      case 2:

        filter = new Date(new Date().setDate(new Date().getMonth() - 12));
      
        break;

      case 3:

        filter = new Date(new Date().setDate(new Date().getDate() - 7));
      
        break;
    
      default:

        filter = new Date(new Date().setDate(new Date().getDate() - 7));

        break;
    }

    filter.setHours(0o0);

    return arr.filter((obj: any) => new Date(obj.created_at.split(" ")[0]).getTime() > new Date(filter).getTime() && new Date(obj.created_at.split(" ")[0]).getTime() <= new Date().getTime()).sort((a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  }
  

  dayOfWeek(day: any){

    switch(day){
      case 0:
        return 'Sunday'
      case 1:
        return 'Monday'
      case 2:
        return 'Tuesday'
      case 3:
        return 'Wednesday'
      case 4:
        return 'Thursday'
      case 5:
        return 'Friday'
      case 6:
        return 'Saturday'
      default:
        return 'Invalid day'
    }
    
  }

  idRand(){
    return Math.floor(Math.random() * 101);
  }

  getTemperature() {
    this.temperature = Math.floor(Math.random() * 51);
    return this.temperature;
  }

  mostrar(op: boolean, title: string, id: number) {
      this.show = op;
      this.title = title;
      this.getCharts = id + 6;
      console.log(this.getCharts);
  }

  clearModal() {
    this.show = !this.show;
    this.title = '';
  }

  selectFormat(){
    console.log(this.selectedFormat);

    this.loading = true;

    setTimeout(() => {
      this.loading = false;
      console.log(this.itemsOlts);
    }, 200);

  }

  selectOlt() {
    this.loading = true;

    if (this.selectedOlt === null) {
      setTimeout(() => {
        this.loading = false;
        this.itemsOlts = this.olts;
      }, 200);
    } else {
      let olt = this.olts.find(
        (element: any) => element.id === this.selectedOlt
      );

      setTimeout(() => {
        this.loading = false;
        this.itemsOlts = [olt];

        console.log(this.itemsOlts);
      }, 200);
    }
  }

  refresh() {
    this.getData();
  }
}
