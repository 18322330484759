import { IUser } from "src/app/core/interfaces/IUser.interface";
import { IDummyDataResponse } from "src/shared-interfaces/IDummyDataResponse.interface";

export function getMockUserPublicInfo(): Promise<IDummyDataResponse<IUser>> {
  let data: IUser = {
    id: 1,
    username: "user",
    email: "user@gmail.com",
    roles: [],
    modules: [
      {
        id: 2,
        name: 'Dashboard',
        order: 1,
        route: 'home',
        styleRules: { icon: 'fa fa-dashboard' },
        childrenModules: []
      },
      /*
            {
              id: 11,
              name: 'Configuración',
              order: 4,
              styleRules: { icon: 'fa fa-cog' },
              childrenModules: [
                {
                  id: 12,
                  name: 'Categoria Empresa',
                  order: 1,
                  styleRules: { icon: '	fa fa-institution' },
                  route: 'configuracion/cat_empresas',
                  contentRoute: '/modules/5/content-blocks',
                  childrenModules: []
                },
                {
                  id: 13,
                  name: 'Categoría Producto',
                  order: 2,
                  styleRules: { icon: 'fa fa-archive' },
                  route: 'configuracion/cate_prod',
                  contentRoute: '/modules/5/content-blocks',
                  childrenModules: []
                },
                {
                  id: 14,
                  name: 'Franquicias',
                  order: 3,
                  styleRules: { icon: 'fa fa-industry' },
                  route: 'configuracion/franquicias',
                  contentRoute: '/modules/5/content-blocks',
                  childrenModules: []
                },
                // {
                //   id: 15,
                //   name: 'Usuario',
                //   order: 4,
                //   styleRules: { icon: 'fa fa-user' },
                //   route: 'configuracion/Usuario',
                //   contentRoute: '/modules/5/content-blocks',
                //   childrenModules: []
                // }
              ]
            },
            */
      {
        id: 3,
        name: 'Unconfigured',
        order: 2,
        route: 'unconfigured',
        styleRules: { icon: 'fa fa-tasks' },
        childrenModules: []
      },
      {
        id: 4,
        name: 'Configured',
        order: 3,
        route: 'configured',
        styleRules: { icon: 'fa fa-sliders' },
        childrenModules: [
          {
            id: 21,
            name: 'Views',
            order: 3,
            styleRules: { icon: 'fa fa-eye' },
            route: 'configured/views',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          }
        ]
      },
      {
        id: 5,
        name: 'Graphs',
        order: 4,
        route: 'graphs',
        styleRules: { icon: 'fa fa-pie-chart' },
        childrenModules: []
      },
      {
        id: 6,
        name: 'Diagnostics',
        order: 5,
        route: 'diagnostics',
        styleRules: { icon: 'fa fa-signal' },
        childrenModules: []
      },
      {
        id: 7,
        name: 'Reports',
        order: 6,
        styleRules: { icon: 'fa fa-file-pdf-o' },
        roles: ['Acceder a reportes'],
        childrenModules: [
          {
            id: 8,
            name: 'Authorization',
            order: 1,
            styleRules: { icon: 'fa fa-unlock-alt' },
            route: 'reports/authorization',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 9,
            name: 'Exports',
            order: 2,
            styleRules: { icon: 'fa fa-file-pdf-o' },
            route: 'reports/exports',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 10,
            name: 'Imports',
            order: 3,
            styleRules: { icon: 'fa fa-file-archive-o' },
            route: 'reports/imports',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
        ]
      },
      {
        id: 11,
        name: 'Save Config',
        order: 7,
        route: 'saveConfig',
        styleRules: { icon: 'fa fa-check-square-o' },
        childrenModules: [],
        roles: ['Configurar sistema']
      },
      {
        id: 12,
        name: 'Settings',
        order: 8,
        styleRules: { icon: 'fa fa-cog' },
        roles: ['Configurar sistema'],
        childrenModules: [
          {
            id: 13,
            name: 'Zones',
            order: 1,
            styleRules: { icon: 'fa fa-map-marker' },
            route: 'settings/zones',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 14,
            name: 'Capabilities',
            order: 2,
            styleRules: { icon: 'fa fa-rss' },
            route: 'settings/capability',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 15,
            name: 'ODBS',
            order: 3,
            styleRules: { icon: 'fa fa-microchip' },
            route: 'settings/odbs',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 16,
            name: 'ONUS',
            order: 4,
            styleRules: { icon: 'fa fa-database' },
            route: 'settings/onus',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: [
              {
              id: 23,
              name: 'Add New ONU',
              order: 1,
              styleRules: { icon: 'fa fa-server' },
              route: 'settings/onus/addNewOnu',
              contentRoute: '/modules/5/content-blocks',
              childrenModules: []
            }
          ]
          },
          {
            id: 17,
            name: 'Speed Profiles',
            order: 5,
            styleRules: { icon: 'fa fa-laptop' },
            route: 'settings/speed_profiles',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 18,
            name: 'OLTS',
            order: 6,
            styleRules: { icon: 'fa fa-server' },
            route: 'settings/olts',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: [
              {
                id: 22,
                name: 'Create OLT',
                order: 1,
                styleRules: { icon: 'fa fa-server' },
                route: 'settings/olts/create',
                contentRoute: '/modules/5/content-blocks',
                childrenModules: []
              },
              {
                id: 24,
                name: 'View OLT',
                order: 2,
                styleRules: { icon: 'fa fa-server' },
                route: 'settings/olts/view',
                contentRoute: '/modules/5/content-blocks',
                childrenModules: []
              }
            ]
          },
          {
            id: 19,
            name: 'ONU Types',
            order: 7,
            styleRules: { icon: 'fa fa-sitemap' },
            route: 'settings/onuTypes',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          },
          {
            id: 20,
            name: 'VPN Tunnels',
            order: 8,
            styleRules: { icon: 'fa fa-shield' },
            route: 'settings/vpn-tunnels',
            contentRoute: '/modules/5/content-blocks',
            childrenModules: []
          }
        ]
      },

      {
        id: 25,
        name: 'Profiles',
        order: 9,
        route: 'profiles',
        styleRules: { icon: 'fa fa-users' },
        childrenModules: [],
        noDisplay: true

      },

      {
        id: 26,
        name: 'Change Password',
        order: 10,
        route: 'changePassword',
        styleRules: { icon: 'fa fa-key' },
        childrenModules: [],
        noDisplay: true

      },

      {
        id: 27,
        name: 'Roles',
        order: 11,
        route: 'roles',
        styleRules: { icon: 'fa fa-id-card' },
        childrenModules: [],
        noDisplay: true

      },

      {
        id: 28,
        name: 'Permissions',
        order: 12,
        route: 'permissions',
        styleRules: { icon: 'fa fa-unlock' },
        childrenModules: [],
        noDisplay: true

      },
    ]
  }

  const res = { data }
  return new Promise((resolve, reject) => {
    resolve(res)
    // setTimeout(() => {
    // }, 100);
  });
}
