<div class="bg-gray-900">
  <div class="signpanel-wrapper">
    <div class="signbox signup">
      <div class="signbox-header">
        <h2>Fibex OLT</h2>
        <p class="mg-b-0"> Admin </p>
      </div><!-- signbox-header -->
      <div class="signbox-body">
        <form  [formGroup]="form" (ngSubmit)="signUp()">

          <div class="form-group">
            <label class="form-control-label">Email:</label>
            <input type="email" name="email" class="form-control" placeholder="Type email address" formControlName="email">
            <div
            class="alert alert-danger"
            *ngIf="email!.touched && email!.invalid"
            >
              <div *ngIf="email!.errors!['required']">
                El email es requerido
              </div>

              <div *ngIf="email!.errors!['email']">
                El email es invalido
              </div>

            
            </div>
          </div><!-- form-group -->
  
          <div class="row row-xs">
            <div class="col-sm">
              <div class="form-group">
                <label class="form-control-label">Firstname:</label>
                <input type="text" name="firstName" class="form-control" placeholder="Type firstname" formControlName="firstName">

                <div
                class="alert alert-danger"
                *ngIf="firstName!.touched && firstName!.invalid"
                >
                  <div *ngIf="firstName!.errors!['required']">
                    El nombre es requerido
                  </div>
                
                </div>

              </div><!-- form-group -->
            </div><!-- col -->
            <div class="col-sm">
              <div class="form-group">
                <label class="form-control-label">Lastname:</label>
                <input type="text" name="lastname" class="form-control" placeholder="Type lastname" formControlName="lastName">
                
              </div><!-- form-group -->
            </div><!-- col -->
          </div><!-- row -->
  
          <div class="row row-xs">
            <div class="col-sm">
              <div class="form-group">
                <label class="form-control-label">Password:</label>
                <input type="password" name="password" class="form-control" placeholder="Type password" formControlName="password">

                <div
                class="alert alert-danger"
                *ngIf="password!.touched && password!.invalid"
                >
                  <div *ngIf="password!.errors!['required']">
                    La contraseña es requerida
                  </div>
          
                  <div *ngIf="password!.errors!['minlength']">
                    La contraseña debe tener mínimo 8 caracteres
                  </div>
                </div>
              </div><!-- form-group -->
            </div><!-- col -->
            <div class="col-sm">
              <div class="form-group">
                <label class="form-control-label">Confirm Password:</label>
                <input type="password" name="confirmPassword" class="form-control" placeholder="Retype password" formControlName="confirmPassword">

                
                <div
                class="alert alert-danger"
                *ngIf="confirmPassword!.touched && confirmPassword!.invalid"
                >
                  <div *ngIf="confirmPassword!.errors!['required']">
                    La contraseña es requerida
                  </div>
          
                  <div *ngIf="confirmPassword!.errors!['minlength']">
                    La contraseña debe tener mínimo 8 caracteres
                  </div>
                </div>
              </div><!-- form-group -->
            </div><!-- col -->
          </div><!-- row -->

          <div
          class="alert alert-danger"
          *ngIf="confirmPassword!.touched && form.invalid && form.errors?.['doNotMatch']"
          >
            Las contraseñas no coinciden
          </div>

          <div class="form-group mg-b-20 tx-12">By clicking Sign Up button below you agree to our <a href="">Terms of Use</a> and our <a href="">Privacy Policy</a></div>
  
          <button type="submit" class="btn btn-success btn-block" [disabled]="!form.valid || loading" >Sign Up <i *ngIf="loading" class="fa fa-spinner fa-spin margin-left"></i></button>
          <div class="tx-center bd pd-10 mg-t-40">Ya tienes cuenta? <a routerLink="/signin">Sign In</a></div>

        </form>
       
      </div><!-- signbox-body -->
    </div><!-- signbox -->
  </div><!-- signpanel-wrapper -->
</div>
