import { Component, Input, inject, Output, EventEmitter, Renderer2, } from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { initial } from 'lodash';



interface TipoItem {
  // Define las propiedades de tus objetos aquí
  id: number;
  status: string;
  // Otras propiedades
}

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent {

  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);

  @Output() itemIdEmitter = new EventEmitter<number>();
  @Output() formEditEmitter = new EventEmitter<FormGroup>();
  @Output() refresh = new EventEmitter<boolean>();
  @Input() array: any = [];
  @Input() showFilter: boolean = false;
  @Input() buttonsHide: boolean = true;
  @Input() viewEndpoint: string = '';
  @Input() title: string = '';
  @Input() endPoint: string = '';
  @Input() filter: string = '';
  @Input() filter2: any;
  @Input() formSections: any = '';
  @Input() viewbtn: boolean = false;
  @Input() form: FormGroup;
  @Input() headers: string[] = [];
  @Input() editForm: any;
  @Input() viewStatus: boolean = false;
  @Input() autorizeBtn: boolean = false;
  @Input() activateOlt: any;
  @Input() statusFilter: boolean = true;
  @Input() oltFilter: boolean = true;
  @Input() placeholder: string = "Name, SN, MAC, IP";




  ElementSelect: any;
  id: any;
  loading = false;
  currentPage = 1;
  itemsPerPage = 10;
  showContextMenu: boolean = false;
  originalArray: any = [];
  filteredItems: any = [];
  searching = false;
  sync = false;

  //FILTROS
  filters: string = '';
  filters2!: any;
  data: any = [];
  selectedOlt: any = null;
  itemsOlts: any = [];
  path: any;
  mostrar: any = null;
  numDatosMostrados: any = 10;
  aux: any = [];

  constructor(private filterPipe: FilterPipe,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router) {

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)])
    });

    this.editForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(5)])
    });
  }

  async ngOnInit() {

    this.initialize();


  }

  
  ngOnChanges(changes: any): void {
    
    if(changes.activateOlt) {

      console.log(this.activateOlt)

      if(this.activateOlt != null){

        this.syncOlt(this.activateOlt);

      }

    }
 }

 initialize(){

    this.array.forEach((item: any) => {

      this.getObjectKeysWithoutId(item);
      
    });

    console.log(this.headers);

    if(this.showFilter){

      this.getOltFilters();

    }

    this.loading = true;
    // const data = await this.smartOltService.getData(this.endPoint);
    // this.array = data.data;
    console.log(this.array);
    if (this.array.length > 0) {
      this.loading = false;
      // this.array = data.data;
      this.originalArray = this.array;
    } else {
      this.loading = false;
      this.array = [];
    }

 }


  //FILTROS
  async getOltFilters() {

    let res = await this.smartOltService.getData('olts/listing');


    this.data = res.data;
    this.aux = this.data;

    if (this.data.length > 0) {

      this.itemsOlts = res.data;
      this.loading = false;
    } else {
      this.loading = false;
      this.data = [];
    }


    if (this.numDatosMostrados != 10 || this.mostrar != null) {
      this.numDatosMostrados = 10;
      this.mostrar = null;
    }
    if (this.route.snapshot.queryParams['id']) {
      let olt = this.route.snapshot.queryParams['id']

      this.selectedOlt = olt;
      this.selectOlt(olt);
    }
    this.route.queryParams.subscribe(async (data: any) => {
      if (data.path) {

        this.path = data.path;
      }
    });

  }


  //Get keys to headers list from father component
  getObjectKeysWithoutId(item: any): string[] {
    this.headers = Object.keys(item).filter(key => key !== 'id' && key.split('_').pop() != 'id' && typeof item[key] !== 'object');
    return this.headers;

  }

  //Send selected id to father component
  sendId() {
    const id = this.id;
    this.itemIdEmitter.emit(id);
  }
  //Send editForm to father component
  sendEditForm() {
    const Form = this.editForm;
    this.formEditEmitter.emit(Form);
  }

  async syncOlt(id: any){

    this.searching = true;
    const route = "get/snmp/activeolt/" + id;
    // const route = "get/change/" + id;

    // setTimeout(() => {

    //   this.searching = false;

    //   console.log("peticion automatica mandada");
      
    // }, 3000);


    try {
      
      let res = await this.smartOltService.getData(route);

      if(res){
        console.log(res);

        this.searching = false;
        this.sync = true;

        this.refresh.emit(true);

      }
      
    } catch (error) {

      console.error(error);
      
    }


  }

  async edit(id: bigint) {
    this.id = id;
    this.sendId();
    const response = await this.smartOltService.getById(this.endPoint, id);
    if (response) {
      console.log(response)

      this.form.reset();
      //automatically fills the inputs by making a data fetch.
      let formControls: { [key: string]: FormControl } = {};
      if (response.data[0]) {

        const data0 = response.data[0];
        for (const key in data0) {
          if (data0.hasOwnProperty(key)) {
            formControls[key] = new FormControl(data0[key], [Validators.required, Validators.minLength(3)]);
          }
        }
      } else {

        const data0 = response.data;
        // for (let key in data0) {
        //   if (data0.hasOwnProperty(key)) {
        //     if(typeof data0[key] == 'number' || typeof data0[key] == 'string'){
        //       if(typeof data0[key] == 'number'){
        //         formControls[key] = new FormControl(data0[key], [Validators.required, Validators.minLength(1)]);
        //       } else {
        //         formControls[key] = new FormControl(data0[key], [Validators.required, Validators.minLength(3)]);
        //       }
        //     }
        //   }
        // }

        for (const key in data0) {
          if (data0.hasOwnProperty(key)) {
            formControls[key] = new FormControl(data0[key], [Validators.required, Validators.minLength(3)]);
          }
        }

      }

      this.editForm = new FormGroup(formControls);

      console.log(formControls)
      this.sendEditForm();
    }
  }


  toDelete(id: any) {
    this.id = id;
    this.sendId()
    this.ElementSelect = id;
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    const totalPages = this.getTotalPages();
    if (this.currentPage < totalPages) {
      this.currentPage++;
    }
  }

  getTotalPages(): number {
    // Calcular las páginas en función de la longitud del conjunto de datos filtrados
    return Math.ceil(this.getFilteredItems().length / this.itemsPerPage);
  }



  getVisibleItems(): any[] {
    // Obtener el conjunto de datos completo
    let filteredItems = this.array;

    console.log(filteredItems)

    // Aplicar el filtro solo si hay valores en los filtros
    if (this.filters || this.filters2) {
      // Aplicar el filtro por palabra clave (this.filters)
      if (this.filters && this.filters.length > 0) {
        const keyword = this.filters.toLowerCase();
        filteredItems = this.filterPipe.transform(
          filteredItems,
          this.filters,
          this.filters2
        );
      }

      // Aplicar el filtro adicional (this.filters2)
      if (this.filters2) {
        filteredItems = this.filterPipe.transform(
          filteredItems,
          this.filters,
          this.filters2
        );
      }
    }

    // Calcular los índices de inicio y fin para la paginación
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    // Obtener solo los elementos necesarios para la página actual
    return filteredItems.slice(startIndex, endIndex);
  }


  private getFilteredItems(): any[] {
    let filteredItems = this.array;

    // Aplicar el filtro por palabra clave (this.filters)
    if (this.filters && this.filters.length > 0) {
      const keyword = this.filters.toLowerCase();
      filteredItems = filteredItems.filter((item: any) => {
        return Object.keys(item).some(key =>
          item[key] != null && item[key].toString().toLowerCase().includes(keyword)
        );
      });
    }
    return filteredItems;
  }

  switchSignal(data: string) {
    switch (data) {
      case 'Very good':
        return 'fa fa-signal fa-md text-success'
      case 'Warning':
        return 'fa fa-signal fa-md text-warning'
      default:
        return 'fa fa-signal fa-md text-danger'
    }
  }

  switchStatus(data: string) {
    switch (data) {
      case 'Online':
        return 'fa fa-globe fa-md text-green'
      case 'Offline':
        return 'fa fa-globe fa-md text-red'
      case 'Power fail':
        return 'fa fa-plug fa-md text-grey'
      case 'LOS':
        return 'fa fa-chain-broken fa-sm text-red'
      default:
        return
    }
  }

  selectOlt(olt: any) {
    this.selectedOlt = olt;

    if (this.selectedOlt === null) {
      this.filters2 = null;
      this.getOltFilters();
    } else {
      this.filters = this.selectedOlt;
      this.numDatosMostrados = 10;
    }
  }


  onInputChange() {
    if (this.filters.length > 4) {
      if (this.mostrar == null) {
        this.mostrar = this.numDatosMostrados;
      }
      this.numDatosMostrados = this.data.length;
    } else if (this.mostrar != null && this.filters.length < 3) {
      this.numDatosMostrados = this.mostrar;
      this.mostrar = null;
    }
  }

  selectStatus(status: any) {
    if (status.toLowerCase() === this.filters2) {
      this.filters2 = null;
      if (this.selectedOlt === null) {
        this.numDatosMostrados = 10;
      }
    } else {
      if (this.numDatosMostrados < 100 || this.selectedOlt !== null) {
        this.numDatosMostrados = 100;
      }
      this.filters2 = status.toLowerCase();
    }
  }

}