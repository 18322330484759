import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TipoInput } from 'src/app/models/tipo.input';
import { IFormData } from 'src/app/models/tipo.input';

@Component({
  selector: 'app-extensible-select',
  templateUrl: './extensible-select.component.html',
  styleUrls: ['./extensible-select.component.scss']
})
export class ExtensibleSelectComponent {
  @Input() Field: {datoShow: string, value: string}[] = [];
  @Input() TitleShow: string = "";
  @Input() formData: IFormData = {vars:{}, error: {}};
  @Input() value: string | string[] = "";
  @Input() TableName: string = "";
  @Input() multiple: boolean = false;
  @Output() ActualizarDatos = new EventEmitter();
  @Output() ValueSalida = new EventEmitter<string | string[]>()
  // @Input() data: TipoInput = {} as any;
  // @Input() options: string[] = [];
  // @Input() value: any = "";

  opened: boolean = false;
  recupered: boolean = true;
  avariable: boolean = false;
  timer: NodeJS.Timer | null = null;

  modal: boolean = false;
  valueModal: string = "";
  errorModal: string = "";

  // HanderOnChange(event: any) {
  //   this.ValueSalida.emit()
  //   delete this.formData.error[this.data.Campo];
  //   this.formData.vars[this.data.Campo] = event.target.value;
  // }

  getValue() {
    if(this.multiple) {
      const values = this.value instanceof Array ? this.value : [];

      return (this.Field.filter((f: any) => values.some(v => v === f.value))).map((f: any) => f.datoShow).join(", ");
    }
    return this.Field.find((f: any) => f.value === this.value)?.datoShow ?? "";
  }

  Focus(ev: any) {
    this.opened = true;
    this.recupered = true;

    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.avariable = true;
    }, 500)
  }
  
  OnBlur() {
    this.recupered = false;

    setTimeout(() => {
      if(!this.recupered) {
        this.opened = false;
    
        if(this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.avariable = false;
        }, 500)
      }
    })
  }

  Click(ev: any) {
    if(this.avariable) {
      this.opened = !this.opened;
    }
  }

  SelectItem(value: string) {
    this.opened = false;
    if(this.multiple) {
      let valueArray = this.value;

      if(!(valueArray instanceof Array)) {
        valueArray = [];
      }

      const index = valueArray.findIndex(v => v === value);

      if(index !== -1) {
        valueArray.splice(index, 1);
      }
      else {
        valueArray.push(value);
      }

      this.ValueSalida.emit(valueArray);
    }
    else {
      this.ValueSalida.emit(value);
    }
    // this.formData.vars[this.data.Campo] = value;
  }

  OpenModal() {
    setTimeout(() => {
      this.OnBlur();
      this.modal = true;
      this.valueModal = "";
    })
  }

  CloseModal() {
    this.modal = false;
  }

  onChange(ev: any) {
    this.valueModal = ev.target.value;
    console.log("VALUE MODAL:", this.valueModal);
  }

  ClickContainer(ev: any) {
    ev.stopPropagation();
  }

  AddValueForm(ev: any) {
    ev.preventDefault();

    if(this.valueModal) {
      // this.options.push(this.valueModal);
      this.CloseModal();
    }
    else this.errorModal = "Requerido*";
  }

  Salida(ev: any) {
    switch(ev.evento) {
      case "cerrar":
      case "guardar":
        this.CloseModal();
        break;
    }
  }

  ngOnInit() {
    console.log(this.Field);
  }

  isSelected(value: string) {
    if(this.multiple) {
      if(this.value instanceof Array) {
        return this.value.includes(value);
      }
    }
    else {
      return this.value === value;
    }

    return false;
  }
}
