import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IModule } from 'src/app/core/interfaces/IModule.interface';
import { HelperService } from 'src/app/services/helper.service';
import { LogsService } from 'src/app/services/logs.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-profile-options',
  templateUrl: './profile-options.component.html',
  styleUrls: ['./profile-options.component.scss']
})
export class ProfileOptionsComponent {

  @Input() profileBtns!: IModule | any;

  login:any;

  constructor(
    private router: Router,
    private logs: LogsService,
    private helper: HelperService,
    private userService: UserService
    ) {

      this.login = sessionStorage.getItem('loginInfo');
      this.login = JSON.parse(this.login)

  }

  changePassword() {

    this.router.navigate(['/dashboard/changePassword'])
    
  }

  profiles() {
 
    this.router.navigate(['/dashboard/profiles']);

    
  }

  logout() {


    if (this.userService.getLoginToken()) {
      this.userService.logOut();
    }
  }

  async createLog(loginParams:any){
    const { idUser, idUserEmp } = loginParams

    if (idUser) {
      const action = await this.helper.retrieveAction('Logout')

      this.logs.createLog(idUser, action, 'Sesión finalizada').then(resp => {

      }).catch(error => console.log(error))

    }else if(idUserEmp){
      const action = await this.helper.retrieveAction('Logout')

      this.logs.createLog(idUserEmp, action, 'Sesión finalizada').then(resp => {

      }).catch(error => console.log(error))
    }
  }
}
