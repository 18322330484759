import {
  Component,
  inject,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal-component.component.html',
  styleUrls: ['./modal-component.component.scss'],
})
export class ModalComponent {
  @ViewChild('newModal') newModal!: ElementRef;
  @Input() title: string = '';
  @Output() dataEmitter = new EventEmitter<any>();
  @Output() activateOlt = new EventEmitter<any>();
  @Input() text: string = '';
  @Input() form!: FormGroup;
  @Input() editForm!: FormGroup;
  @Input() item: any;
  @Input() formSections: any;
  @Input() endPoint: string = '';
  @Input() selectOptions: any;
  @Input() validationRules: any = [];
  @Input() id: any;
  options: any;
  selectedItemId: number | undefined;
  isModalOpen: boolean = false;

  loading = false;
  array: any;
  close: boolean = false;
  idZone: any;
  ElementSelect: any;
  checkboxes: any[] = [];
  checkboxesEdit: any[] = [];

  constructor(private smartOltService: SmartOltService, private gSnackbarService: GenericSnackbarService, private utilsService: UtilsService) {
    this.selectedItemId = undefined;
  }

  ngOnInit() {
    console.log(this.formSections);
  }

  async onSubmit() {

    const activateOlt = this.form.value.act_olt_auto
    ? this.form.value.act_olt_auto
    : null;

    delete this.form.value.act_olt_auto;

    console.log(this.form.value);

    try {
      const response = await this.smartOltService.create(
        this.endPoint,
        this.form.value

      );

      console.log(response)

      if (response.data || response) {

        if (activateOlt) {
          this.activateOlt.emit(response.data.id);
        }

        this.utilsService.showSuccess("Datos guardados exitosamente");
        this.form.reset();
        this.sendData();
      }
      
    } catch (error) {

      console.log(error)
      this.utilsService.showError("Ha ocurrido un error...");
      
    }
    
  }

  onCheckboxChange(e: any, form: any) {

    const checkArray: FormArray = form.get('permissions') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    console.log(form)

  }

  get name() {
    return this.form.get('name');
  }

  get zone_id() {
    return this.form.get('zone');
  }

  get numPorts() {
    return this.form.get('nr_of_ports');
  }

  get speed() {
    return this.form.get('speed');
  }

  get type_conexion() {
    return this.form.get('type_conexion');
  }

  get type_speed() {
    return this.form.get('type_speed');
  }

  async authorize() {

    console.log(this.editForm.value);

    try {
      const response = await this.smartOltService.authorize(
        this.endPoint,
        this.id,
        this.editForm.value
      );

      console.log(response)
      if (response) {
        this.utilsService.showSuccess("Autorizacion exitosa");
        this.editForm.reset();
        this.sendData();
      }
    } catch (error) {
      
      console.log(error)
      this.utilsService.showError("Ha ocurrido un error...");

    }
  }


  async update() {

    console.log(this.editForm.value);

    try {
      const response = await this.smartOltService.update(
        this.endPoint,
        this.id,
        this.editForm.value
      );

      console.log(response)
      if (response) {
        this.utilsService.showSuccess("Datos actualizados exitosamente");
        this.editForm.reset();
        this.sendData();
      }
    } catch (error) {
      
      console.log(error)
      this.utilsService.showError("Ha ocurrido un error...");

    }
  }

  async confirmDelete() {
    try {
      const response = await this.smartOltService.delete(
        this.endPoint,
        this.id
      );
      if (response) {
        this.utilsService.showSuccess("Datos eliminados exitosamente");
        console.log(response);
        this.sendData();
    
      }
    } catch (error) {
    
      console.log(error)
      this.utilsService.showError("Ha ocurrido un error...");
    }
  }

  sendData() {
    console.log('Enviando',);
    this.dataEmitter.emit(true);
  }
}
