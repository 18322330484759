import { Component, Input, OnChanges } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ChartJsService } from 'src/app/services/chart-js.service';

Chart.register(...registerables)

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnChanges{
  @Input() dataToChart: any;
  @Input() idChart: any[] = [];
  @Input() chartType: string = '';


  constructor(private chartService: ChartJsService) { }

  ngOnChanges(): void {
    if( this.dataToChart instanceof Object && this.idChart.length > 0 ) {
      this.renderTotalChart(this.dataToChart);
    }
  }

  renderTotalChart(dataTotal: any[]) {
    try {
      const data = {
        labels: dataTotal.map(row => row.mes),
        datasets: [
          {
            label: this.idChart[0].label,
            data: dataTotal.map(row => row.cantidad),
            tension: .5,
            borderColor: this.chartService.getDataColors()[0],
            backgroundColor: this.chartService.getDataColors(70),
            fill: true,
            pointRadius: 10,
            pointHoverRadius: 15
          }
        ]
      }
  
      const options = {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
  
      new Chart(this.idChart[0].id, {type: this.idChart[0].typeChart, data, options})
    } catch (error) {
      console.log(error)
    }
    
  }
}
