import {
  Component,
  Input,
  inject,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { GenericSnackbarService } from 'src/@core/services/generic-snackbar.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { initial } from 'lodash';
import { ListFiltersPipe } from 'src/app/pipes/list-filters.pipe';
import { UtilsService } from 'src/app/services/utils.service';
import { UserService } from 'src/app/services/user.service';

interface TipoItem {
  // Define las propiedades de tus objetos aquí
  id: number;
  status: string;
  // Otras propiedades
}

@Component({
  selector: 'app-lista-frontend',
  templateUrl: './lista-frontend.component.html',
  styleUrls: ['./lista-frontend.component.scss'],
})
export class ListaFrontendComponent {
  smartOltService = inject(SmartOltService);
  gsnackbar = inject(GenericSnackbarService);

  @Output() itemIdEmitter = new EventEmitter<number>();
  @Output() formEditEmitter = new EventEmitter<FormGroup>();
  @Output() refresh = new EventEmitter<boolean>();
  @Input() data: any = [];
  @Input() title: string = '';
  @Input() headers: string[] = [];
  @Input() autorizeBtn: boolean = false;
  @Input() activateOlt: any;
  @Input() tab: any = '';
  @Input() itemsPerPage?: any = 5;
  @Input() showFilter: boolean = true;
  @Input() oltFilter: boolean = false;
  @Input() placeholder: string = 'SN, MAC, IP, name, address';
  @Input() statusFilter: boolean = true;
  @Input() viewbtn: boolean = false;
  @Input() viewEndpoint: string = '';
  @Input() actionsButtons: boolean = false;
  @Input() endPoint: string = '';
  @Input() editForm!: any;
  @Input() form!: FormGroup;
  @Input() formSections: any = '';
  @Input() viewStatus: boolean = false;
  @Input() exportOpts: boolean = true;


  ElementSelect: any;
  id: any;
  loading = true;
  currentPage = 1;

  showContextMenu: boolean = false;
  searching = false;
  sync = false;
  item: any;

  //FILTROS
  searchText: string[] = ['', ''];
  statusSignalFilter: any = 0;
  selectedOlt: any = null;
  itemsOlts: any = [];
  path: any;
  mostrar: any = null;
  numDatosMostrados: any = 10;
  aux: any = [];

  dataLength: any = null;

  constructor(private userService: UserService) {}

  async ngOnInit() {
    
    this.initialize();

    this.mapData();

    // this.utilsService.filteredDataLength.subscribe((length: any)=>{
    //   this.dataLength = length;
    // });

  }

  ngOnChanges(changes: any): void {
    if (changes.activateOlt) {
      console.log(this.activateOlt);
    }
  }

  async initialize() {

    if(this.data?.length){

        
      this.getObjectKeysWithoutId(this.data[0]);

      console.log(this.data);

    }

    if(this.oltFilter){

      this.getOltFilters();

    }

  }

  
  checkAdmiRole(item: any){

    let disabled = false;

    if(this.title == 'Roles'){

      if(item.name && item.name.toLowerCase() == "admin"){

        disabled  = true;

      }

    }

    return disabled;

  }


  checkUserSession(id: any){

    let disabled = false;

    if(this.title == 'Usuarios'){

      let user = this.userService.getUserData();

      if(user.id == id){
        disabled  = true;
      } 
      
    }

    return disabled;

  }

  selectOlt() {

    this.searchText[1] = this.selectedOlt;

  }

  mapData() {
    if (Array.isArray(this.data)) {
      this.data.map((item: any) => {
        let keys = Object.keys(item);

        keys.map((key: any) => {
          if (key.includes('status') && typeof item[key] == 'string') {
            if (
              item[key].toLowerCase().includes('1') ||
              item[key].toLowerCase().includes('online') ||
              item[key].toLowerCase().includes('normal') ||
              item[key].toLowerCase().includes('up') ||
              item[key].toLowerCase().includes('enabled') ||
              item[key].toLowerCase() == 'connected'
            ) {
              item[key] = 1;
            } else if (
              item[key].toLowerCase().includes('2') ||
              item[key].toLowerCase().includes('offline') ||
              item[key].toLowerCase().includes('down') ||
              item[key].toLowerCase().includes('disabled')||
              item[key].toLowerCase().includes('disconnected')
            ) {
              item[key] = 2;
            } else {
              item[key] = 3;
            }
          }
        });
      });
    }
  }

  //Get keys to headers list from father component
  getObjectKeysWithoutId(item: any): string[] {
    if (this.tab == 'ponPorts') {
      this.headers = Object.keys(item).filter(
        (key) =>
          key !== 'id' &&
          key !== 'img' &&
          key != 'min_range' &&
          key != 'max_range' &&
          key != 'olt_id' &&
          key.split('_').pop() != 'id'
      );
    } else {
      this.headers = Object.keys(item).filter(
        (key) =>
          key !== 'id' && key !== 'img' && key.split('_').pop() != 'id'
      );
    }
    return this.headers;
  }


  //Send selected id to father component
  sendId() {
    const id = this.id;
    this.itemIdEmitter.emit(id);
  }

  //Send editForm to father component
  sendEditForm() {
    const Form = this.editForm;
    this.formEditEmitter.emit(Form);
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }


  nextPage() {
    const totalPages = this.getTotalPages();
    if (this.currentPage < totalPages) {
      this.currentPage++;
    }
  }

  async getOltFilters() {

    let res = await this.smartOltService.getData('olts/listing');
    
    if (res.data.length > 0) {

      this.itemsOlts = res.data;
    } 

  }

  getTotalPages(): number {
    // Calcular las páginas en función de la longitud del conjunto de datos filtrados

    let length;

    if(this.dataLength){

      length = this.dataLength;

    } else {
      length = this.data?.length || 0;
    }

    return Math.ceil(length / this.itemsPerPage);
  }

  onInputChange(text: string) {
    this.currentPage = 1;
    this.searchText[0] = text;

  }

  selectStatus(status: any) {
    this.currentPage = 1;

    if (this.statusSignalFilter == status) {
      this.statusSignalFilter = 0;
    } else {
      this.statusSignalFilter = status;
    }
  }

  switchSignalIcon(data: string) {

    if(data == null){
      return
    }

    switch (data.toLowerCase()) {
      case 'very good':
        return 'fa fa-signal fa-md text-success';
      case 'warning':
        return 'fa fa-signal fa-md text-warning';
      case 'critical':
        return 'fa fa-signal fa-md text-danger';
      default:
        return 'fa fa-signal fa-md text-grey';
    }
  }

  async edit(id: bigint) {

    this.editForm.reset();

    this.id = id;
    
    this.sendId();

    console.log(id)
    
    const response = await this.smartOltService.getById(this.endPoint, id);
    if (response) {
      console.log(response);
   
      //automatically fills the inputs by making a data fetch.
      let formControls: { [key: string]: FormControl } = {};
      let data: any;

      if (response.data){

        if (response.data[0]) {

          data = response.data[0];
       
        } else{

          data = response.data;

        }

      } else {
        data = response;
      }

      this.formSections.forEach((section: any) => {

        console.log(section.controlName, ' = ', data[section.controlName])

        formControls[section.controlName] = new FormControl(data[section.controlName] || null, [
          Validators.required,
          Validators.minLength(3),
        ]);
        
      });

      // for (const key in data) {
      //   if (data.hasOwnProperty(key)) {
      //     formControls[key] = new FormControl(data[key], [
      //       Validators.required,
      //       Validators.minLength(3),
      //     ]);
      //   }
      // }

      this.editForm = new FormGroup(formControls);

      this.sendEditForm();
    }
  }

  toDelete(id: any) {
    this.id = id;
    this.sendId();
    this.ElementSelect = id;
  }

  checkIfArray(data: any){

    if(Array.isArray(data)){

      return data.join(', ');

    } else {
      return data;
    }

  }

  async syncOlt(id: any) {
    this.searching = true;
    const route = 'get/snmp/activeolt/' + id;
    // const route = "get/change/" + id;

    // setTimeout(() => {

    //   this.searching = false;

    //   console.log("peticion automatica mandada");

    // }, 3000);

    try {
      let res = await this.smartOltService.getData(route);

      if (res) {
        console.log(res);

        this.searching = false;
        this.sync = true;

        this.refresh.emit(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  switchStatus(data: string) {
    switch (data) {
      case '1':
        return 'text-green';
      case 'Online':
        return 'text-green';
      case 'Normal':
        return 'text-green';
      case 'UP':
        return 'text-green';
      case 'Offline':
        return 'text-red';
      case 'DOWN':
        return 'text-red';
      case 'UNKNOWN':
        return 'text-warning';
      case '6':
        return 'text-warning';
      default:
        return '';
    }
  }

  switchStatusIcon(data: any) {
    switch (data) {
      case 1:
        return 'fa fa-globe fa-md text-green';
      case 2:
        return 'fa fa-globe fa-md text-red';
      default:
        return 'fa fa-globe fa-md text-grey';
    }
  }

}
