<div class="main-wrapper">
  <div class="signpanel-wrapper">
    <div class="signbox">
      <div class="signbox-header">
        <h2>Fibex OLT</h2>
        <p class="mg-b-0">El dashboard admin de Fibex Telecom</p>
      </div><!-- signbox-header -->
      <div class="signbox-body">
      <form [formGroup]="form" (ngSubmit)="login( form )">
        <div class="form-group" >
          <label class="form-control-label">Usuario:</label>
          <input type="text" placeholder="Ingresa tu usuario" class="form-control" style="border-radius: 5px;" formControlName="login">
          <!-- <div class="errorvalidation" *ngIf="form.controls['login'].errors && form.controls['login'].touched"> <label>
            Usuario incorrecto
          </label></div> -->
        </div><!-- form-group -->
        <div class="form-group">
          <label class="form-control-label">Contraseña:</label>
          <input type="password" placeholder="Ingresa tu contraseña" class="form-control" style="border-radius: 5px;" formControlName="password">
          <div class="errorvalidation" *ngIf="error"> <label>
            Usuario o contraseña invalidos
          </label></div>
        </div><!-- form-group -->
        <!-- <div class="form-group" style="display:flex; justify-content:center; ">
          <a href="">¿Se te olvido tu contraseña?</a>
        </div> -->
        <button class="btn btn-success btn-block" style="border: radius 5px;"  type="submit" [disabled]="!form.valid || loading"  >Ingresar <i *ngIf="loading" class="fa fa-spinner fa-spin margin-left"></i>
        </button>
        <ng-container *ngIf="msgErr">
          <p class="errorvalidation errtxt" >{{msgErr}}</p>
        </ng-container>
        <!-- <div class="tx-center bg-white bd pd-10 mg-t-20">¿No tienes una cuenta? <br> <a routerLink='/signup'>Crea una nueva cuenta</a></div> -->
      </form>
      </div><!-- signbox-body -->

    </div><!-- signbox -->
  </div><!-- signpanel-wrapper -->
</div>
