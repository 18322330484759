import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFormData, TipoInput } from 'src/app/models/tipo.input';

let Estructura = {
      TitleHead: "Franquicia",
      Table: "cb_Franquicia",
      Inputs: [],
      Value:[]//"idUserEmp_3jKgVz"
    }

interface IEstructura {
  TitleHead: string
  Table: string
  Inputs: TipoInput[],
  Value: any[],
  Order?:TipoInput[],
  Type?: String
}

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() param!: IEstructura;
  @Input() steps: string[] = ['first', 'second', 'third', 'fourth']
  @Output() SalidaClickMenu: EventEmitter<any> = new EventEmitter();

  stepCounter: number = 0;

  constructor(){

  }

  ngOnInit(): void {
    console.log(this.param)
  }

  emitter( event?: any ){
    if( event !== undefined && event.data.length > 0) {
      
      this.SalidaClickMenu.emit(event)
    }
  }

  next(event: any){
    // this.stepCounter++
    console.log()
  }
}
