import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SmartOltService } from 'src/app/services/smart-olt.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-view-olt',
  templateUrl: './view-olt.component.html',
  styleUrls: ['./view-olt.component.scss']
})
export class ViewComponent {

  constructor(private router: ActivatedRoute, private smartOltService: SmartOltService, private utilsService: UtilsService) { }

  data: any = [];
  loading: boolean = false;
  show: boolean = false;
  path: any = '';
  loading2: boolean = false;
  refreshing: boolean = false;
  info: any = [];
  loadingOnus: boolean = false;
  
  // Info estatico

  ponPorts = [
    {
      port: '0',
      type: 'GPON',
      admin_state: 'Enabled',
      status: 'Up/Autofind',
      onus: 'online: 1 total: 4',
      signal: 'online',
      description: null,
      range: '0-20000m',
      tx_power: '7.01 dBm'
    },
    {
      port: '2',
      type: 'EPON',
      admin_state: 'Disabled',
      status: 'Down/Autofind',
      onus: 'online: 1 total: 4',
      signal: 'offline',
      description: null,
      range: '0-20000m',
      tx_power: '7.01 dBm'
    },
    {
      port: '3',
      type: 'GPON',
      admin_state: 'Enabled',
      status: 'Up/Autofind',
      onus: 'online: 1 total: 4',
      signal: 'online',
      description: null,
      range: '0-20000m',
      tx_power: '7.01 dBm'
    },
    {
      port: '4',
      type: 'EPON',
      admin_state: 'Disabled',
      status: 'Down/Autofind',
      onus: 'online: 1 total: 4',
      signal: 'offline',
      description: null,
      range: '0-20000m',
      tx_power: '7.01 dBm'
    }
  ];
  vlans = [
    {
      vlan_id: '100',
      default_for: null,
      description: 'FTH RESIDENCIAL',
      used_for_IPTV: true,
      used_for_mgmt_voip: true,
      dhcp_Snooping: true,
      lan_to_lan: true,
    },
    {
      vlan_id: '200',
      default_for: null,
      description: 'FTH RESIDENCIAL',
      used_for_IPTV: false,
      used_for_mgmt_voip: false,
      dhcp_Snooping: false,
      lan_to_lan: false,
    },
    {
      vlan_id: '300',
      default_for: null,
      description: 'FTH PYMES',
      used_for_IPTV: true,
      used_for_mgmt_voip: true,
      dhcp_Snooping: true,
      lan_to_lan: true,
    },
    {
      vlan_id: '400',
      default_for: null,
      description: 'FTH RESIDENCIAL',
      used_for_IPTV: false,
      used_for_mgmt_voip: false,
      dhcp_Snooping: false,
      lan_to_lan: false,
    }
  ];
  logs = [
    {
      action: 'Reboot',
      user: 'API',
      date: new Date()
    },
    {
      action: 'Upload Speed profile 300 MB added',
      user: 'vdelgado@fibextelecom.net',
      date: new Date()
    },
    {
      action: 'CLI session open on OLT',
      user: 'thomas@fibextelecom.net',
      date: new Date()
    }
  ];

  ngOnInit() {
    this.getData();
  }



  async importOnus(){

    this.loadingOnus = true;

    try {

      this.utilsService.showInfo("Importando onus...");

      let res = await this.smartOltService.getData('get/snmp/onu/' + this.data.id);

      if(res){
  
        console.log(res);  

        this.utilsService.showSuccess("Onus importadas con éxito");

  
      }
      
    } catch (error: any) {

      console.log(error);

      this.utilsService.showError(error.message, "Ocurrió un error");

      
    } 
    
    this.loadingOnus = false;

  }

  async refresh(endpoint: string){

    this.refreshing = true;

    let res = await this.smartOltService.getData(endpoint + this.data.id);

    if(res){

      console.log(res);
      this.refreshing = false;

      this.getData(true);


    }

  }

  async getData(table?: any) {

    let olt: any;

    this.router.queryParams.subscribe(async (data: any) => {

      olt = data.id;

      if (data.path) {

        this.path = data.path;

      } else {

        this.path = 'list';

      }

    });

    if(table){

      this.loading2 = true;

      let res = await this.smartOltService.getData('olts/' + olt);

      this.data = res.data;
      this.data.olt_cards = this.data.olt_cards.map((card: any) => {
  
        card.updated_at = new Date(card.updated_at);
  
        return card;
      });
  
      if (this.data) {
        this.loading2 = false;
      }


    } else {

        
      this.loading = true;

      let res = await this.smartOltService.getData('olts/' + olt);

      this.data = res.data;
      this.data.olt_cards = this.data.olt_cards.map((card: any) => {

        card.updated_at = new Date(card.updated_at);

        return card;
      });


      if (this.data) {
        this.loading = false;
      }

    }

    console.log(this.data)
 
  }

  toggle() {
    this.show = !this.show;
  }

}
